import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { GLOBAL } from './global';

@Injectable()
export class TorneosService{
    public url:string;

    constructor(
        public _http: HttpClient

    ){
        this.url = GLOBAL.url;

    }
    
    getTorneoConfiguraciones(token: string): Observable<any>{
        let headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
                                        .set('Authorization', token);
        return this._http.post(this.url+'torneos/getconfiguracion','', {headers: headers});
    }
    getDeportesEmpresa(data, token: string): Observable<any>{
        let json = JSON.stringify(data);
        let params = 'json='+json;
        let headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
                                        .set('Authorization', token);
        return this._http.post(this.url+'empresa/getdeporte',params, {headers: headers});
    }
    getCategoriasDeporte(data, token: string): Observable<any>{
        let json = JSON.stringify(data);
        let params = 'json='+json;
        let headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
                                        .set('Authorization', token);
        return this._http.post(this.url+'deporte/categorias',params, {headers: headers});
    }
    getItemsJuegoDeporte(data, token: string): Observable<any>{
        let json = JSON.stringify(data);
        let params = 'json='+json;
        let headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
                                        .set('Authorization', token);
        return this._http.post(this.url+'deporte/getitemjuegos',params, {headers: headers});
    }
    torneoInsUpd(data, token: string): Observable<any>{
        let json = JSON.stringify(data);
        let params = 'json='+json;
        let headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
                                        .set('Authorization', token);
        return this._http.post(this.url+'torneos/settorneo',params, {headers: headers});
    }
    getItemsJuegoText(data): Observable<any>{
        let json = JSON.stringify(data);
        let params = 'json='+json;
        let headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');                               
        return this._http.post(this.url+'torneos/itemtext',params, {headers: headers});
    }
    torneoAddEquipo(data, token: string): Observable<any>{
        let json = JSON.stringify(data);
        let params = 'json='+json;
        let headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
                                        .set('Authorization', token);
        return this._http.post(this.url+'torneos/Anotarse',params, {headers: headers});
    }
    torneoGetAnotados(data, token: string): Observable<any>{
        // cod_torneo
        let json = JSON.stringify(data);
        let params = 'json='+json;
        let headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
                                        .set('Authorization', token);
        return this._http.post(this.url+'torneos/listAnotados',params, {headers: headers});
    }
    torneoSetHablilitarAnotados(data, token: string): Observable<any>{
        // cod_torneo, cod_usuario
        let json = JSON.stringify(data);
        let params = 'json='+json;
        let headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
                                        .set('Authorization', token);
        return this._http.post(this.url+'torneos/habilitarEquipoAnotado',params, {headers: headers});
    }
    torneoRepartirEquiposZonas(data, token: string): Observable<any>{
        // cod_torneo
        let json = JSON.stringify(data);
        let params = 'json='+json;
        let headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
                                        .set('Authorization', token);
        return this._http.post(this.url+'torneos/repartirEquiposAnotados',params, {headers: headers});
    }
    torneoGetZonas(data, token: string): Observable<any>{
        // cod_torneo
        let json = JSON.stringify(data);
        let params = 'json='+json;
        let headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
                                        .set('Authorization', token);
        return this._http.post(this.url+'torneos/getZonas',params, {headers: headers});
    }
    torneoArmarPartidosZonas(data, token: string): Observable<any>{
        // cod_torneo
        let json = JSON.stringify(data);
        let params = 'json='+json;
        let headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
                                        .set('Authorization', token);
        return this._http.post(this.url+'torneos/setArmarPartidos',params, {headers: headers});
    }
    torneoTablaPuntaje(data, token: string): Observable<any>{
        // cod_torneo, zona
        let json = JSON.stringify(data);
        let params = 'json='+json;
        let headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
                                        .set('Authorization', token);
        return this._http.post(this.url+'torneos/getTablaGeneral',params, {headers: headers});
    }
    torneoPartidos(data, token: string): Observable<any>{
        // cod_torneo, cod_partido, cod_equipo, zona, ronda, llave
        let json = JSON.stringify(data);
        let params = 'json='+json;
        let headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
                                        .set('Authorization', token);
        return this._http.post(this.url+'torneos/getPartidos',params, {headers: headers});
    }
    torneoPuntosPartido(data, token: string): Observable<any>{
        // cod_torneo, cod_partido
        let json = JSON.stringify(data);
        let params = 'json='+json;
        let headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
                                        .set('Authorization', token);
        return this._http.post(this.url+'torneos/getPuntosPartido',params, {headers: headers});
    }
    torneoFechaPartido(data, token: string): Observable<any>{
        // cod_torneo, cod_partido, fecha_inicio
        let json = JSON.stringify(data);
        let params = 'json='+json;
        let headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
                                        .set('Authorization', token);
        return this._http.post(this.url+'torneos/setFechaPartido',params, {headers: headers});
    }
    torneoCambiarEquipoZona(data, token: string): Observable<any>{
        // cod_torneo, equipo, zonaActual, zonaNueva
        let json = JSON.stringify(data);
        let params = 'json='+json;
        let headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
                                        .set('Authorization', token);
        return this._http.post(this.url+'torneos/setCambiarEquipoZona',params, {headers: headers});
    }
    torneoArmarPartidosLlaves(data, token: string): Observable<any>{
        // cod_torneo, llave_inicial
        let json = JSON.stringify(data);
        let params = 'json='+json;
        let headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
                                        .set('Authorization', token);
        return this._http.post(this.url+'torneos/setArmarPartidosLlaves',params, {headers: headers});
    }
    torneoGetConfigPuntaje(data, token: string): Observable<any>{
        // cod_torneo
        let json = JSON.stringify(data);
        let params = 'json='+json;
        let headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
                                        .set('Authorization', token);
        return this._http.post(this.url+'torneos/getConfigPuntaje',params, {headers: headers});
    }
    torneoIniciarPartido(data, token: string): Observable<any>{
        // cod_torneo, cod_partido
        let json = JSON.stringify(data);
        let params = 'json='+json;
        let headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
                                        .set('Authorization', token);
        return this._http.post(this.url+'torneos/iniciarPartido',params, {headers: headers});
    }
    torneoGuardarPartido(data, token: string): Observable<any>{
        // cod_torneo, cod_partido
        let json = JSON.stringify(data);
        let params = 'json='+json;
        let headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
                                        .set('Authorization', token);
        return this._http.post(this.url+'torneos/guardarPartido',params, {headers: headers});
    }
    
}