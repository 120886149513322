import { MailService } from './../../services/mail.service';
import {Component, OnInit, Input} from '@angular/core';
import {Router, ActivatedRoute, Params} from '@angular/router';
import { user } from '../../clases/user';
import { UserService } from '../../services/user.service';
import { formatDate } from '@angular/common';

@Component({
    selector: 'app-register',
    templateUrl: './register.component.html',
    providers:[UserService, MailService],
    styleUrls:['./register.css']
})
export class RegisterComponent implements OnInit{
    @Input() varempresa: number;

    public title: string;
    public user: user;
    public status: string;
    public message:string;
    public registrado: boolean = false;
    view2factor: boolean = false;
    view2factorMethod: string = 'mail';
    
    public app: string;

    fechaLimite = Date.now();

    constructor(
        private _route: ActivatedRoute,
        private _router: Router,
        private _userService: UserService,
        private _mailService: MailService,
        
    ){
        this.title = 'Registro';
        this.user = new user('','','','',null,'','','usuario');
        
    }

    /*tratarDate(campo:string){
    
        if (campo == 'limite'){
          let fechaString = formatDate(this.fechaLimite,'yyyy/MM/dd HH:mm','en-US');
          this.user.fechaNacimiento = fechaString;
        }
    
        //console.log('entro y sali con valores '+campo+ ' '+this.mytorneo.fecha_limite_inscripcion);
      } */
    
    ngOnInit(){
        
        this._route.params.subscribe(
            params => {
                this.app = params['app'];
            }    
        );
        if(this.app){
           // console.log(this.app);
        }
        
    }
    
    

    onSubmit(registerForm){
        this.clearMessage();
        if(registerForm.valid)
        { 
           // console.log(this.user);
            this._userService.registerUser(this.user).subscribe(
                response => {
                    console.log(response);
                    if(response.status != 'error'){
                        console.log('registrado');
                        this.registrado= true;
                        
                    }else{
                        this.status = response.status;
                        this.message = response.message;
                    }        
                },
                error => {
                    console.log(<any> error);
                }
            )
        }
        
    }

    clearMessage() {
        this.status = '';
        this.message = '';
    }
    onForget(form) {
        this.clearMessage();
        form.reset();
    }

    
}