import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';


@Injectable({
    providedIn: 'root'
})
export class DataService{
    itemNoticiasNav = new BehaviorSubject<string>('inicial');

    constructor(){}

    getNoticiasNav(): Observable<any>{
        return this.itemNoticiasNav;
    }
    setNoticiasNav(item:string){
        this.itemNoticiasNav.next(item);
    }
    
    
}