
<div class="col-12 content" style="width: 100%; text-align: center;" *ngIf="!senalnoticia" >
    <div class="news-card " > 
        <div class="text-center">
            <img  style="max-height: 200px; ;" src="assets/imagen/empresas/{{varnoticia.cod_empresa}}/noticias/{{varnoticia.cod_noticia}}/{{varnoticia.imagen1}}"  alt="{{varnoticia.titulo}}">
        </div>
        <div class="card-body">
            <h2>{{varnoticia.titulo}}</h2>
            <p>{{varnoticia.texto_corto}} </p>
            <p *ngIf="varnoticia.activa==0" > {{varnoticia.noticia}} </p>
            <div class="text-center" *ngIf="varnoticia.activa==0" >
                <button class="btn btn-primary" (click)="senalnoticia = true" >Cerrar Noticia</button>
            <!--    <button class="btn btn-primary" onclick="history.go()" >Cerrar Noticia</button>-->
            </div>
        </div>
        <div class="card-footer">
            <p class="card-text"> <small class="text-muted"> Última actualización {{varnoticia.fecha}}</small> </p>
        </div>            
    </div>
</div>



<div class=" col-12 " style=" background-color: {{colorSite}};" *ngIf="senalnoticia" >
    
    <app-noticias   [varempresa]="varempresa">  </app-noticias>
</div>
 

<!-- 
<div class="col-12" style="width: 100%; text-align: center; border-width: 5px; border-style: double;">
    <div class="news-card" >
        <div class="text-center">
            <img  style="max-height: 200px; ;" src="assets/imagen/empresas/{{varnoticia.cod_empresa}}/noticias/{{varnoticia.cod_noticia}}/{{varnoticia.imagen1}}"  alt="{{varnoticia.titulo}}">
        </div>
        <div class="card-body">
            <h2>{{varnoticia.titulo}}</h2>
            <p>{{varnoticia.texto_corto}} </p>
            <p *ngIf="varnoticia.activa==0" > {{varnoticia.noticia}} </p>
            <!-
            <div class="text-center" *ngIf="noticia.activa==0" >
                <button class="btn btn-primary" (click)="noticiaSola(noticia)" >Agrandar Noticia</button>
            </div>
            ->
        </div>
        <div class="card-footer">
            <p class="card-text"> <small class="text-muted"> Última actualización {{varnoticia.fecha}}</small> </p>
        </div>            
    </div>
</div>

-->
