
<div class="alert alert-danger" *ngIf="status=='error'">
        {{message}}
        <h2> La sesion a expirado, vuelva a loguearse. </h2>
        <button class="button button" (click)="this._siteServices.logout(this.varempresa);" *ngIf="message=='no Autentificado'">
            Cerrar
        </button>
</div>

<div class="content" style="justify-content: space-between; background-color: {{colorSite}};" *ngIf="TorneoConfiguracion">

    <h2 style="text-align: center; " *ngIf="!codTorneoUpdate" >Crear Nuevo Torneo</h2>
    <h2 style="text-align: center; " *ngIf="codTorneoUpdate" >Modificar Torneo</h2>
    
    <div style="background-color: {{colorSite}};" *ngIf="message!='no Autentificado'">
        <div class="content">
            <form style="width: 100%;" #itemForm="ngForm" (ngSubmit)="onSubmit(itemForm);">
                <div class="row ">
                    <div class="edit-cards col1 " style="margin-right: 2%;">
                   
                        <div class="input-info">
                            <label style="margin: 5px ;"> Titulo </label>
                            <input class="form-control" type="text" style="width: 100%; flex-grow: 1; " required maxlength="50" name="torneo" #torneo="ngModel" [(ngModel)]="mytorneo.torneo" >
                        </div>
                        
                        <div class="input-info">
                            <label style="margin: 5px ;"> Torneo Habilitado </label>
                            <input class="form-control" style="width: max-content;" type="checkbox" name="activo" #activo="ngModel" [(ngModel)]="mytorneo.activo" />
                        </div>
                        
                        <div class="input-info">
                            <label style="margin: 5px ;"> Es Liga </label>
                            <input class="form-control" style="width: max-content;" type="checkbox" name="is_liga" #is_liga="ngModel" [(ngModel)]="mytorneo.is_liga" />
                        </div>

                        <div class="input-info">
                            <label style="margin: 5px ;"> Resumen </label>
                            <input class="form-control" type="text" style="width: 100%; flex-grow: 1;" required maxlength="200" name="descripcion" #descripcion="ngModel" [(ngModel)]="mytorneo.descripcion" >
                        </div>

                        <div class="input-info">
                            <label style="margin: 5px ;"> Fecha Inscripcion </label> 
                            <input class="form-control" style="width: max-content;"  type="date"  required name="Limite" #Limite="ngModel" [(ngModel)]="fechaLimite"  (ngModelChange)="tratarDate('limite')">
                        </div>

                        <div class="input-info">
                            <label style="margin: 5px ;"> Fecha Inicio </label>
                            <input class="form-control" style="width: max-content;" type="date" required name="inicio" #inicio="ngModel" [(ngModel)]="fechaInicio" (ngModelChange)="tratarDate('inicio')">
                        </div>

                        <div class="input-info">
                            <label style="margin: 5px ;"> Cantidad de Equipos </label>
<!--                         <input class="form-control" style="width: max-content;" type="number" min="1" step="1" max="99" required name="plaza_equipos" #plaza_equipos="ngModel" [(ngModel)]="mytorneo.plaza_equipos" >
-->   
                            <input class="form-control" style="width: max-content;" type="number" min="{{Equipos}}" step="1" max="99" required name="plaza_equipos" #plaza_equipos="ngModel" [(ngModel)]="mytorneo.plaza_equipos" >

                        </div>

                    </div>
              

                    <div class="edit-cards col1 " >
                    
                        <div class="input-info">
                            <label style="margin: 5px ;"> Tipo Torneo </label>


                            <select class="form-control" style="width: 100%;" required  name="cod_torneo_config" 
                                #cod_torneo_config="ngModel" [(ngModel)]="mytorneo.cod_torneo_config" (ngModelChange)="selectConfiguracion()">
                                <option [ngValue]="" hidden selected>Seleccione una Configuracion para el torneo </option>
                                <option value="{{item.cod_torneo_config}}" *ngFor="let item of TorneoConfiguracion" >
                                    {{item.detalle}}
                                    <!--assets/imagen/empresas/1/noticias/1/padel.png-->
                                </option>
                                
                            </select>
                        </div>
                    
                        <div class="  " style=" width: 80%; margin-left: 10%; margin-bottom: 5px; padding-left: 15px; border-radius: 5px; background-color: {{colorSite}};" *ngIf="TorneoConfiguracionSelect&&TorneoConfiguracionSelect!=null" >
                            <p>Grupos: {{TorneoConfiguracionSelect.zonas_grupos}}</p>
                            <p>Rondas zona de grupos: {{TorneoConfiguracionSelect.cantidad_rondas}}</p>
                            <p>Llave donde clasificarán: {{TorneoConfiguracionSelect.llave_inicial}} ª</p>
                            <p *ngIf="TorneoConfiguracionSelect.llave_ida_vuelta==0">Llaves ida y vuelta: No</p>
                            <p *ngIf="TorneoConfiguracionSelect.llave_ida_vuelta==1">Llaves ida y vuelta: Si</p>
                        </div>

                        <div class="input-info">
                            <label style="margin: 5px ;"> Deporte </label>
                            <select class="form-control" required name="codDeporte"  #codDeporte="ngModel" [(ngModel)]="mytorneo.cod_deporte" (ngModelChange)="selectDeporte()">
                                <option value="" seled hidden> Seleccione un deporte </option>
                                <option value="{{item.cod_deporte}}" *ngFor="let item of TorneoDeportes" >
                                    {{item.deporte}}
                                    <!--assets/imagen/empresas/1/noticias/1/padel.png-->
                                </option>
                                
                            </select>
                        </div>

                        <div class="input-info">
                            <label style="margin: 5px ;"> Categoria </label>
                            <select class="form-control" name="cod_categoria" required #cod_categoria="ngModel" [(ngModel)]="mytorneo.cod_categoria" (ngModelChange)="selectCategorias()" *ngIf="TorneoCategorias">
                                <option value="" hidden selected> Seleccione una Categoria </option>
                                <option value="{{item.cod_deporte_categoria}}" *ngFor="let item of TorneoCategorias" >
                                    {{item.categoria}}
                                    <!--assets/imagen/empresas/1/noticias/1/padel.png-->
                                </option>
                            </select>
                        </div>
                    
                        <div class="input-info">
                            <label style="margin: 5px ;"> Puntaje </label>
                            <select class="form-control" name="config_juego" required #config_juego="ngModel" [(ngModel)]="mytorneo.cod_config_juego" (ngModelChange)="selectTipoJuego()">
                                <option value="" hidden selected> Seleccione Tipo de Juego </option>
                                <option value="{{item.cod_torneo_config_juego}}" *ngFor="let item of TorneoTJ" >
                                    {{item.tipo_juego}}
                                    <!--assets/imagen/empresas/1/noticias/1/padel.png-->
                                </option>
                                
                            </select>
                        </div>
                    
                        <div class=" col-12 " style=" width: 80%; margin-left: 10%; margin-bottom: 5px; padding-left: 15px; border-radius: 5px; background-color: {{colorSite}};" 
                            *ngIf="torneoTJItemSelect&&torneoTJItemSelect!=null" >
                            <table>
                                <tr *ngFor="let item of torneoTJItemSelect">
                                    <td class="col-8">
                                        {{item.item}}
                                    </td>
                                    <td class="col-1">
                                        {{item.valor}}
                                    </td>
                                    <td class="col-1">
                                        <i class="fa fa-info-circle fa-lg" style="color: rgb(109, 109, 238);" title="{{item.descripcion}}"></i>
                                    </td> 
                                </tr>
                            </table>
                            
                        </div>
                    
                    </div>
                </div>

                <div class="row">
                    <div class="edit-cards col1 " style="margin-right: 2%; width: 70%;">
                        <input type="hidden"  name="imagen1" #imagen1="ngModel" [(ngModel)]="mytorneo.imagen1">
                        <input type="hidden"  name="imagen2" #imagen2="ngModel" [(ngModel)]="mytorneo.imagen2">
                        <input type="hidden"  name="imagen3" #imagen3="ngModel" [(ngModel)]="mytorneo.imagen3">
                
                        
                        <input type="file" style="width: 100%;" id="file" class="input-file" (change)="cargarImagen($event, 'imagen1')" > 
                        <input type="file" style="width: 100%;" id="file" class="input-file" (change)="cargarImagen($event, 'imagen2')" >
                        <input type="file" style="width: 100%;" id="file" class="input-file" (change)="cargarImagen($event, 'imagen3')" >
                    </div>

<!--                  <button type="submit" class="edit-cards " [disabled]="!itemForm.form.valid"
                        style=" width: min-content; flex-grow: 1; background-color: {{colorButton}}">
                            Guardar Torneo
                    </button>
-->  
                    <div class="edit-cards  col2 " style=" max-width: 30%; flex-grow: 1;">
                        <div class="">
                                                                                        <!-- || !(skinSelect&&skinSelect.titulo==true) -->
                            <button class="button button" type="submit"   [disabled]="!itemForm.form.valid  "
                             
                                style=" width: 100%; background-color: {{colorButton}}">
                                <!-- style="background-color: {{colorButton}}; font-family: {{buttonFont}}; font-size: {{buttonSize}}; color: {{buttonColor}};" -->
                                Guardar Torneo
                            </button><br>
                            
                            <button class="button button" type="submit"  
                                style="width: 100%;background-color: {{colorButton}}" onclick="history.go(0)">
                                <!-- style="background-color: {{colorButton}}; font-family: {{buttonFont}}; font-size: {{buttonSize}}; color: {{buttonColor}};" -->
                                Cancelar Cambios
                            </button>
                        </div>
                    </div>
    





                </div>
                
            </form>
        </div>
    </div>
    
</div>
 