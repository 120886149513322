import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { CommonModule } from '@angular/common';  
import { SortPipe } from './clases/sortPipe';
import { CustomDatePipe } from "./clases/custom.datepipe";

import { AppComponent } from './app.component';

// Zone Deporte
import { ZoneDeporteInicioComponent } from './component/zoneDeporteInicio/zoneDeporteInicio.component';

// App Puntos Padel
import { AppPuntosPadelComponent } from './component/appPuntosPadel/appPuntosPadel.component';

// skins
import { DefaultComponent } from './component/skins/default/default.component';
import { SkindosComponent } from './component/skins/skindos/skindos.component';
import { SkintresComponent } from './component/skins/skintres/skintres.component';

// Widgets
import { widgedEquipoPadelComponent } from './component/widgets/equipoPadel/widgedEquipoPadel.component';
import { widgedPartidoComponent } from './component/widgets/partido/widgedPartido.component';
import { widgedTablaPosGrupoPadelComponent } from './component/widgets/tablaPosGrupoPadel/widgedtablaPosGrupoPadel.component';
import { widgedPartidoChangeFechaComponent } from './component/widgets/partidoChangeFecha/widgedPartidoChangeFecha.component';

// load clients skins
import { loadskinssitesComponent } from './component/loads/loadskinssites.component';
import { TurnosComponent } from './component/turnero/turnos.component';
import { NoticiasComponent } from './component/noticias/noticias.component';
import { NoticiaComponent } from './component/noticia/noticia.component';
import { CanchasComponent } from './component/canchas/canchas.component';
import { addTorneosComponent } from './component/torneos/addTorneos.component';
import { LigasComponent } from './component/ligas/ligas.component';
import { ContactoComponent } from './component/contacto/contacto.component';
import { LoginComponent } from './component/login/login.component';
import { RegisterComponent } from './component/register/register.component';
import { PerfilComponent } from './component/perfil/perfil.component';
import { AgregarNoticiaComponent } from './component/noticias/agregarNoticia/agregarNoticia.component';
import { InscripcionTorneosComponent } from './component/torneos/inscripcion/inscripcionTorneos.component';
import { HabilitarEquipoComponent } from './component/torneos/habilitarEquipo/habilitarEquipo.component';
import { HabilitarZonasComponent } from './component/torneos/habilitarZonas/habilitarZonas.component';
import { VerTorneoGeneralComponent } from './component/torneos/verTorneoGeneral/verTorneoGeneral.component';

@NgModule({
  declarations: [
    AppComponent,
    SortPipe,
    CustomDatePipe,
    DefaultComponent,
    SkindosComponent,
    SkintresComponent,
    loadskinssitesComponent,
    TurnosComponent,
    NoticiasComponent,
    AgregarNoticiaComponent,
    NoticiaComponent,
    CanchasComponent,
    addTorneosComponent,
    LigasComponent,
    ContactoComponent,
    ZoneDeporteInicioComponent,
    LoginComponent,
    RegisterComponent,
    InscripcionTorneosComponent,
    AppPuntosPadelComponent,
    widgedEquipoPadelComponent,
    widgedPartidoComponent,
    widgedTablaPosGrupoPadelComponent,
    HabilitarEquipoComponent,
    widgedPartidoChangeFechaComponent,
    HabilitarZonasComponent,
    VerTorneoGeneralComponent,
    PerfilComponent,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    AppRoutingModule,
 
  ],
  providers: [AppComponent,
  CommonModule],
  bootstrap: [AppComponent]
})
export class AppModule { }
