import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { SiteService } from 'src/app/services/site.service';
import { TorneosService } from 'src/app/services/torneos.service';
import { Router, ActivatedRoute, Params } from '@angular/router';

import { exit } from 'process';

@Component({
  selector: 'widgedPartido',
  templateUrl: './widgedPartido.component.html',
  styleUrls: ['./widgedPartido.component.css'],
  providers: [SiteService, TorneosService]
}) 

export class widgedPartidoComponent implements OnInit, OnDestroy {
  @Input() codTorneo: number;
  @Input() codPartido: number;
  public movil: boolean;
  public datosPartido;
  public finalizado: boolean;
  public iniciado: boolean;
  public ganador: number;
  public contadorrecarga: number;
  destroy = false;
  
  constructor( private _siteServices: SiteService,
              private _torneosService: TorneosService,
              private _route: ActivatedRoute,
              private _router: Router
  )  { }

  ngOnInit(): void {
    this.contadorrecarga = 0;
    if (localStorage.getItem('navi') == 'N'){
      this.movil = false;
    } else {
      this.movil = true;
    }
    
    if (this.codTorneo){
      if (this.codPartido){
        // llamo al service
        this.recargarPartido();
      }
    }
  }
  ngOnDestroy(){
    this.destroy = true;
  }
  recargarPartido(){
    //this.datosPartido = [];
    let body = {'cod_torneo':this.codTorneo, 'cod_partido':this.codPartido};
    this._torneosService.torneoPuntosPartido(body,'').subscribe(
      response => {
        if(response.status != 'error'){
          this.datosPartido = response;
          // console.log(this.datosPartido);
          if (this.datosPartido[0].iniciado == 1){
            this.iniciado = true;
          }else{
            this.iniciado = false;
          }
         
          if (!this.iniciado){
            // si no inicio el partido
            
          }else{
            //si ya inicio el partido y existe un set creado
          
            let gana1 = 0; let gana2 = 0;
            this.finalizado = true;
            this.ganador = 0;
            for (let i = 0; i<this.datosPartido.length; i++){
              if (this.datosPartido[i].finalizado == false){
                this.finalizado = false;
                this.ganador = 0;

              }else{
                if (this.datosPartido[i].gamesEquipo1 > this.datosPartido[i].gamesEquipo2){gana1++}else{gana2++}
              }
            }
            if (this.finalizado == true) {
              if(gana1 > gana2){this.ganador = 1;}else{this.ganador = 2;}
            }else{
              this.verificar();
            }
          }
        }else{
          console.log(response);
        }
      },
      error => {
        console.log(error);
      } 
    )
    
  }
  verificar(){
    if (this.finalizado == false && this.destroy == false){
      //console.log('sigue aca');
      setTimeout(() => {
          this.recargarPartido()
        }, 2000
      );
    }
  }

} 
