<div class="alert alert-danger" *ngIf="status=='error'">
    {{message}}
    <h2> La sesion a expirado, vuelva a loguearse. </h2>
    <button class="button button" (click)="this._siteServices.logout(this.varempresa);" *ngIf="message=='no Autentificado'">
        Cerrar
    </button>
</div>

<div class="content" style="justify-content: space-between; background-color: {{colorSite}};">


    <h2 style="text-align: center; " *ngIf="codTorneo" >Habilitar Equipos del torneo {{dataTorneo.torneo}}</h2>

    <div style="background-color: {{colorSite}};" *ngIf="message!='no Autentificado'">
        <div class="content">
            <form style="width: 100%;" #itemForm="ngForm" (ngSubmit)="onSubmit(itemForm);">


                <div class="row ">
                    <div class="edit-cards " [ngClass]="(isMovil == 'S')? 'col2' : 'col1'  "  style="margin-right: 2%;">
                    

                        <div class="input-info" *ngIf="!noVer">
                            <b>Torneo: </b>  {{dataTorneo.torneo}}
                        </div>
                        <div class="input-info"  *ngIf="!noVer">
                            <b>Deporte: </b>  {{dataTorneo.deporte}}
                        </div>

                        <div class="input-info"  *ngIf="!noVer">
                            <b>Resumen: </b> {{dataTorneo.descripcion}}
                        </div>

                        <div class="input-info"  *ngIf="!noVer">
                            <b>Descripcion: </b> {{dataTorneo.noticia}}
                        </div>

                        <div class="input-info" >
                            <b>Fecha de inicio: </b> {{ _siteServices.fechaFormatoString(dataTorneo.fecha_inicio, 'largo') }}
                        </div>

                        <div class="input-info">
                            <b>Fecha Limite de inscripcion: </b> {{ _siteServices.fechaFormatoString(dataTorneo.fecha_limite_inscripcion, 'largo') }}
                        </div>

                        <div class="input-info">
                            <b>Cantidad de equipos: </b> {{ dataTorneo.plaza_equipos }}
                        </div>
                    
                        <div class="input-info" *ngIf="itemText && !noVer "  >
                            <b>Puntaje: </b> {{ itemText[0].texto }}
                        </div>
                    
                        <center>
                            <button class="button button" style=" width: 70%; border-radius: 10px; margin-right: 0%; background-color: {{colorButton}}; font-family: {{buttonFont}}; font-size: {{buttonSize}}; color: {{buttonColor}};"
                                (click)="noVer = !noVer" *ngIf="isMovil == 'S'"> {{ noVer ?  'Mostar +' : 'Mostrar -' }}
                            </button>
                        </center>

                    </div>
                
                
                    <div class="edit-cards " [ngClass]="(isMovil == 'S')? 'col2' : 'col1'  " >
                        <h3 style="text-align: center;">Habilitacion de Equipos</h3>

                        <div style="display: flex; flex-wrap: wrap;">
                            <div style="width: 33%; display: flex; flex-wrap: nowrap; margin-right: 5px;" *ngIf="itemText" >
                                <b>Anotados: </b>  {{itemText[0].anotados}}
                            </div>
                            <div style="width: 33%; display: flex; flex-wrap: nowrap" *ngIf="itemText">
                                <b>Confirmados: </b>  {{itemText[0].inscriptos}}
                            </div>
                            <div  *ngIf="itemText">
                                <b>En Espera: </b>  {{itemText[0].encola}}
                            </div>
                        </div>

                        <div style="width: 33%;" style="text-align: center;" *ngIf="itemText&&itemText[0].anotados+itemText[0].inscriptos < dataTorneo.plaza_equipos">
                            <b style="color: green; ">Lugar Disponible </b> 
                        </div>
                        
                        
                        


                            <!--  empezar a cargar la tabla -->
                        <div style="overflow-x: scroll;">
                            <table class="table table-hover " >
                                <thead>
                                    <tr>
                                        <th width="40%" (click)="sort('responsable')">Responsable</th>
                                        <th width="40%" (click)="sort('jugador 2')"  *ngIf="isMovil=='N'" >Jugador 2</th>
                                        <th width="150%" (click)="sort('activo')"><center>Activo</center></th>
                                        <th width="10%" (click)="sort('espera')"><center>Espera</center></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let inscripto of inscriptos; let i = index" [ngClass]="{'green-background': inscripto.inscripto == 1 ,  'yellow-background': !inscripto.jugador   }">
                                        <td>{{ inscripto.responsable }}</td>
                                        <td  *ngIf="isMovil=='N'" >{{ inscripto.jugador }}</td>
                                        <td>
                                            <center>
                                                <button class="button button" style="margin-right: 0%; background-color: {{colorButton}}; font-family: {{buttonFont}}; font-size: {{buttonSize}}; color: {{buttonColor}};"
                                                    (click)="habilito(i)" *ngIf="dataTorneo.inscripto==0"> {{ inscripto.inscripto === 0 ? 'Habilitar' : 'Deshabilitar' }}
                                                </button>
                                            </center>
                                        <td [ngClass]="{'orange-background': inscripto.cola === 1}">
                                            <center>{{ inscripto.cola === 0 ? 'No' : 'Si' }}</center>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                            <!-- Termina Tabla  -->
                
                        <div  style="justify-content: right; flex-wrap: nowrap; display: flex; " >
                            <!-- cerrar la inscripcion y armar zonas -->
                            <button class="button button " style="flex: 0 0 calc(66%); margin-right: 0%; background-color:darkgreen; font-family: {{buttonFont}}; font-size: {{buttonSize}}; color: {{buttonColor}};"
                                (click)="armarZonas()">Cerrar Inscripcion y armar zonas de grupos 
                            </button>
                            <button class="button button " style="flex: 0 0 calc(33%); margin-right: 0%; background-color:coral; font-family: {{buttonFont}}; font-size: {{buttonSize}}; color: {{buttonColor}};"
                                (click)="salir()">Salir 
                            </button>
                            

                        </div>
                    </div>
                </div>    
            </form>
        </div>
    </div>

</div>
