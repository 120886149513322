
 
<div  class="content" style="background-color: {{colorSite}}; padding-left: 15Px; "  >
    <h2 style="text-align: center;">Turnos</h2>

    <div class="row col-12" style="padding: 5px;" *ngIf="Deporte"> 
        
        <div class="col-6"  *ngIf="Deporte&&Deporte.length==1">
            <span style="font-weight: bold; "> Deporte: {{Deporte[0]}}</span>
        </div>
        <div class="col-6" *ngIf="Deporte&&Deporte.length>1">
            <span style="font-weight: bold; margin-right: 10px;  ">Deporte</span>
            <select class="form-control" name="selectDeporte" (change)="onSelectDeporte($event.target.value)" >
                <option value="null">Seleccione un deporte</option>
                <option *ngFor="let opcion of Deporte" value="{{opcion}}">
                    {{opcion}}
                </option>
            </select>
        </div>

        <div class="col-6" *ngIf="Deporte&&TipoCancha">
            <div  *ngIf="TipoCancha&&TipoCancha.length==1">
                <span style="font-weight: bold; ">Tipo de Cancha: {{TipoCancha[0]}}</span>
            </div>
            <div *ngIf="TipoCancha&&TipoCancha.length>1">
                <span style="font-weight: bold;  margin-right: 10px; ">Selecione Tipo de Cancha  </span>
                <select class="form-control" name="select Tipo de cancha" (change)="onSelectTipoCancha($event.target.value)" >
                    <option value="null">Seleccione un Tipo de Cancha</option>
                    <option *ngFor="let opcion of TipoCancha" value="{{opcion}}">
                        {{opcion}}
                    </option>
                </select>
            </div>
        </div>
    </div>
    <!--  cargar las canchas y turnos  -->


 

    <div style=" background-color: {{colorSite}}">
        <!--<div    *ngIf="_turnoGeneral">-->
        <div    *ngIf="mostrar==true">
            
            <div *ngFor="let turnero2 of _turnoGeneral.fechas ">
                <div class="news-card" *ngIf="turnero2" >
                    
                    <div *ngIf="turnero2"> 
                            
                        <div  class="col-12">{{turnero2.fechaTexto}}
                        </div>    
                            
                        <div class="col-12" *ngFor="let turnero3 of turnero2.canchas ">
                            <div class="col-12"  *ngIf="turnero3">     
                                

                            
                                        
                                <div>
                                    Cancha: {{turnero3.cancha}}
                                </div>
                                <div class="col-12 " style="padding: 0%;" > 
                                    
                                    <div class="row w-100 text-right  col-12" style="margin: 5px;">    
                                        <!--  aca empiezo a modificar para botones horizontales -->
                                        
                                        <div   class="col-6 w-100 divhorario"> 
                                            
                                            <div class="row divderecho" >   
                                            
                                                <div   *ngFor="let turnero4 of turnero3.turnos ">
                                                    <button class="button Libre" title="Horario Libre" (click)="pidirTurno(turnero4.hora, turnero2.fecha, turnero3.cancha, turnero3.cod_tipo_cancha)"  *ngIf="turnero4 && turnero4.estado=='Libre'&& turnero4.orden=='1' "  >
                                                        {{ turnero4.hora }}
                                                    </button>
                                                    <button class="button Ocupado" title="Horario Ocupado" disabled (click)="pidirTurno(turnero4.hora, turnero2.fecha, turnero3.cancha)"  *ngIf="turnero4 && turnero4.estado=='Ocupado'&& turnero4.orden=='1'&&(CodUser!=turnero4.cod_cliente )"  >
                                                        {{ turnero4.hora }}
                                                    </button>
                                                  
                                                    <button class="button OcupadoUser" title="Su Horario" disabled (click)="pidirTurno(turnero4.hora, turnero2.fecha, turnero3.cancha)"  *ngIf="turnero4 && turnero4.estado=='Ocupado'&& turnero4.orden=='1' && CodUser==turnero4.cod_cliente"  >
                                                        {{ turnero4.hora }} 
                                                    </button>



                                                    <button class="button Reservado" title="Horario Reservado" disabled (click)="pidirTurno(turnero4.hora, turnero2.fecha, turnero3.cancha)"  *ngIf="turnero4 && turnero4.estado=='Reservado'&& turnero4.orden=='1'"  >
                                                        {{ turnero4.hora }}
                                                    </button>
                                                    
                                                </div>  
                                            </div>      
                                        </div>
                                        
                                        <!--
                                        <div class="col-1" style="background-color: dimgray;"> 
                                            <div style="background-color: dimgrey; min-width: max-content; min-height: max-content;" > 
                                                
                                            </div>
                                        </div>
                                        -->

                                        <div class="col-6 divhorario" > 
                                            
                                            <div class="row w-100" >   
                                            
                                                <div  *ngFor="let turnero4 of turnero3.turnos ">
                                                    <button class="button Libre" title="Horario Libre" (click)="pidirTurno(turnero4.hora, turnero2.fecha, turnero3.cancha, turnero3.cod_tipo_cancha)"  *ngIf="turnero4 && turnero4.estado=='Libre'&& turnero4.orden=='3'"  >
                                                        {{ turnero4.hora }}
                                                    </button>
                                                    <button class="button Ocupado" title="Horario Ocupado" disabled (click)="pidirTurno(turnero4.hora, turnero2.fecha, turnero3.cancha)"  *ngIf="turnero4 && turnero4.estado=='Ocupado'&& turnero4.orden=='3'&&(CodUser!=turnero4.cod_cliente )"  >
                                                        {{ turnero4.hora }}
                                                    </button>
                                                    <button class="button OcupadoUser" title="Su Horario" disabled (click)="pidirTurno(turnero4.hora, turnero2.fecha, turnero3.cancha)"  *ngIf="turnero4 && turnero4.estado=='Ocupado'&& turnero4.orden=='3' && CodUser==turnero4.cod_cliente"  >
                                                        {{ turnero4.hora }} 
                                                    </button>
                                                    <button class="button Reservado" title="Horario Reservado" disabled (click)="pidirTurno(turnero4.hora, turnero2.fecha, turnero3.cancha)"  *ngIf="turnero4 && turnero4.estado=='Reservado'&& turnero4.orden=='3'"  >
                                                        {{ turnero4.hora }}
                                                    </button>
                                                    
                                                </div>  
                                            </div>      
                                        </div>
                                
                                    </div>
                                    
                                </div>
                            
                            
                            
                            </div>
                                
                        </div>
                        
                                
                                
                            





                        
                    </div>
                </div>
            
            </div>
            
        </div>

        
    </div>
</div>