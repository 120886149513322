<div class="alert alert-danger" *ngIf="status=='error'">
    {{message}}
    <h2> La sesion a expirado, vuelva a loguearse. </h2>
    <button class="button button" (click)="this._siteServices.logout(this.varempresa);" *ngIf="message=='no Autentificado'">
        Cerrar
    </button>
</div>
<div class="content" style="justify-content: space-between; background-color: {{colorSite}}; " *ngIf="skins">
    
    <h2 style="text-align: center; " *ngIf="!codNoticiaUpdate" >Crear Nueva Noticia</h2>
    <h2 style="text-align: center; " *ngIf="codNoticiaUpdate" >Modificar Noticia</h2>
    
    

    <div style=" background-color: {{colorSite}};" >
        
        <div class="content">
        <form  style="width: 100%;" #itemForm="ngForm" (ngSubmit)="onSubmit(itemForm);">
            <div class="input-info">
                <label style="margin: 5px ;">  Modelo </label>
                <select class="form-control" name="Skin"  #Skin="ngModel"  [(ngModel)]="noticia.cod_noticias_skin" (ngModelChange)="selectSkin()">
                    <option hidden value="0"  selected>Seleccione un Modelo para Continuar </option>
                    <option value="{{item.cod_noticias_skin}}" *ngFor="let item of skins" >
                        {{item.skin}}
                        <!--assets/imagen/empresas/1/noticias/1/padel.png-->
                    </option>
                    
                </select>
            </div>
            <div class="row ">
                <div class="edit-cards col1 " style="margin-right: 2%;">

                    <h4 style="text-align: center;">Titulo de la Noticia</h4>

                    <div class="input-info" >
                        <label style="margin: 5px ; ">Titulo </label>
                        <input type="text" style="width: 100%;" required title="Titulo de la Noticia" minlength="3" maxlength="45" placeholder="Titulo - Obligatorior "
                            name="titulo" #titulo="ngModel" [(ngModel)]="noticia.titulo" (ngModelChange)="actualizarVista()" *ngIf="skinSelect&&skinSelect.titulo==true">
                    
                    </div>
                    <!--  letra titulo  -->
                    <div class="input-info">
                        <label style="margin: 5px ;">  Letra </label>
                        <select class="form-control"  title="Tipo de Letra" required   name="Letratitulo" #Letratitulo="ngModel" 
                            [(ngModel)]="noticia.letra_titulo" (ngModelChange)="actualizarVista()" *ngIf="skinSelect&&skinSelect.titulo==true">
                            
                            <option hidden value=""  selected>Seleccionar tipo de Letra - Obligatorio </option>
                            <option value="'Borel', cursive" style="font-family: 'Borel', cursive;">'Borel', cursive</option>
                            <option value="'Bree Serif', serif" style="font-family: 'Bree Serif', serif;">'Bree Serif', serif</option>
                            <option value="'Bungee Shade', cursive" style="font-family: 'Bungee Shade', cursive;">'Bungee Shade', cursive</option>
                            <option value="'Cinzel', serif" style="font-family: 'Cinzel', serif;">'Cinzel', serif</option>
                            <option value="'Lilita One', cursive" style="font-family: 'Lilita One', cursive;">'Lilita One', cursive</option>
                            <option value="'Playfair Display', serif" style="font-family: 'Playfair Display', serif;">'Playfair Display', serif</option>
                            <option value="'PT Sans', sans-serif" style="font-family: 'PT Sans', sans-serif;">'PT Sans', sans-serif</option>
                        </select>
                    </div>
                    <!--  tamaño letra titulo  -->

                    <div class="input-info">
                        <label style="margin: 5px ; ">Tamaño </label>
                        <select class="form-control" title="Tamaño de la Letra" required 
                            name="tamanotitulo" #tamanotitulo="ngModel" [(ngModel)]="noticia.tamano_titulo" (ngModelChange)="actualizarVista()" *ngIf="skinSelect&&skinSelect.titulo==true">
                            <option hidden value=""  selected>Seleccionar Tamaño Letra - Obligatorio </option>
                            <option value="xx-small" style="font-family: {{titleFont}}; font-size: xx-small;">xx-small</option>
                            <option value="x-small" style="font-family: {{titleFont}}; font-size: x-small;">x-small</option>
                            <option value="small" style="font-family: {{titleFont}}; font-size: small;">small</option>
                            <option value="medium" style="font-family: {{titleFont}}; font-size: medium;">medium</option>
                            <option value="large" style="font-family: {{titleFont}}; font-size: large;">large</option>
                            <option value="x-large" style="font-family: {{titleFont}}; font-size: x-large;">x-large</option>
                            <option value="xx-large" style="font-family: {{titleFont}}; font-size: xx-large;">xx-large</option>
                        </select>
                    </div>
                    <!--  color de letra titulo  -->
                    <div class="input-info" >
                        <label style="margin: 5px ;">Color de Texto </label>
                        <input type="color" style="width: 20%;" title="Color de la Letra" name="colortitulo" #colortitulo="ngModel" [(ngModel)]="noticia.color_titulo" (ngModelChange)="actualizarVista()" *ngIf="skinSelect&&skinSelect.titulo==true">
                    </div>
                </div>

                <div class="edit-cards col1">

                    <h4 style="text-align: center;">Noticia</h4>
                    <div class="input-info" >
                        <label style="margin: 5px ; ">Resumen </label>
                        <input type="text" style="width: 100%;" required  maxlength="200" placeholder="Resumen Noticia - Obligatorio"
                        name="texto_corto" #texto_corto="ngModel" [(ngModel)]="noticia.texto_corto" (ngModelChange)="actualizarVista()" *ngIf="skinSelect&&skinSelect.texto_corto==true">
                    </div>
                    <div class="input-info" >
                        <label style="margin: 5px ; ">Noticia </label>
                        <textarea rows="3" cols="80"   style="width: 100%; min-height: 50px;" required  maxlength="2000" name="noticiatotal" #noticiatotal="ngModel" [(ngModel)]="noticia.noticia" (ngModelChange)="actualizarVista()" *ngIf="skinSelect&&skinSelect.noticia==true">
                        </textarea>
                    </div>
                    <!--  letra titulo  -->
                    <div class="input-info">
                        <label style="margin: 5px ;">  Letra </label>
                        
                        <select class="form-control" required  name="Letraotros" #Letraotros="ngModel" [(ngModel)]="noticia.letra_otros" (ngModelChange)="actualizarVista()" *ngIf="skinSelect&&(skinSelect.texto_corto==true||skinSelect.noticia==true)">
                            <option hidden value=""  selected>Seleccionar Tipo de Letra - Obligatorio </option>
                            <option value="'Borel', cursive" style="font-family: 'Borel', cursive;">'Borel', cursive</option>
                            <option value="'Bree Serif', serif" style="font-family: 'Bree Serif', serif;">'Bree Serif', serif</option>
                            <option value="'Bungee Shade', cursive" style="font-family: 'Bungee Shade', cursive;">'Bungee Shade', cursive</option>
                            <option value="'Cinzel', serif" style="font-family: 'Cinzel', serif;">'Cinzel', serif</option>
                            <option value="'Lilita One', cursive" style="font-family: 'Lilita One', cursive;">'Lilita One', cursive</option>
                            <option value="'Playfair Display', serif" style="font-family: 'Playfair Display', serif;">'Playfair Display', serif</option>
                            <option value="'PT Sans', sans-serif" style="font-family: 'PT Sans', sans-serif;">'PT Sans', sans-serif</option>
                        </select>   
                    </div>
                    <!--  tamaño letra titulo  -->
                    
                    
                    <div class="input-info" style="min-width: max-content;" > 
                        <label style="margin: 5px ;">  Tamaño </label>
                        <select class="form-control" required  style="margin-right: 10px; flex-grow: 1;" name="tamanootros" #tamanootros="ngModel" [(ngModel)]="noticia.tamano_otros" (ngModelChange)="actualizarVista()" *ngIf="skinSelect&&(skinSelect.texto_corto==true||skinSelect.noticia==true)">
                            <option hidden value=""  selected>Seleccionar Tamaño Letra - Obligatorio </option>
                            <option value="xx-small" style="font-family: {{titleFont}}; font-size: xx-small;">xx-small</option>
                            <option value="x-small" style="font-family: {{titleFont}}; font-size: x-small;">x-small</option>
                            <option value="small" style="font-family: {{titleFont}}; font-size: small;">small</option>
                            <option value="medium" style="font-family: {{titleFont}}; font-size: medium;">medium</option>
                            <option value="large" style="font-family: {{titleFont}}; font-size: large;">large</option>
                            <option value="x-large" style="font-family: {{titleFont}}; font-size: x-large;">x-large</option>
                            <option value="xx-large" style="font-family: {{titleFont}}; font-size: xx-large;">xx-large</option>
                        </select>
                    </div>
                    <!-- color de letra titulo  -->

                        <div class="input-info "  style="min-width: max-content;">
                            <label  style="margin: 5px; ">Color de Texto </label>
                    
                            <input style="width: 20%;" type="color" name="colorotros" #colorotros="ngModel" [(ngModel)]="noticia.color_otros" (ngModelChange)="actualizarVista()" *ngIf="skinSelect&&(skinSelect.texto_corto==true||skinSelect.noticia==true)">
                        </div>
                </div>
            </div>
            
            <!-- color de fondo -->
            
            <div class="row ">

                <div class="edit-cards col2 " style="margin-right: 2%;">
                    <h4 style="text-align: center;">Fondo</h4>
                    <div class="input-info">
                        <label style="margin: 5px ;">Color de Fondo </label>
                        <input style="max-width: 100%; flex-grow: 1;" type="color"  name="colorfondo" #colorfondo="ngModel" [(ngModel)]="noticia.color_fondo" (ngModelChange)="actualizarVista()" *ngIf="skinSelect">
                    </div>
                </div>
            
                <div class="edit-cards col1 " style="margin-right: 2%; width: 55%;">
                    <div class="input-info">

                        <input type="hidden"  name="imagen1" #imagen1="ngModel" [(ngModel)]="noticia.imagen1"><br>
                        <input type="hidden"  name="imagen2" #imagen2="ngModel" [(ngModel)]="noticia.imagen2"><br>
                        <input type="hidden"  name="imagen3" #imagen3="ngModel" [(ngModel)]="noticia.imagen3"><br>
                
                        
                        <input type="file" style="width: 100%;" id="file" class="input-file" (change)="cargarImagen($event, 'imagen1')" *ngIf="skinSelect&&skinSelect.imagen1==true">
                        <input type="file" id="file" class="input-file" (change)="cargarImagen($event, 'imagen2')" *ngIf="skinSelect&&skinSelect.imagen2==true">
                        <input type="file" id="file" class="input-file" (change)="cargarImagen($event, 'imagen3')" *ngIf="skinSelect&&skinSelect.imagen3==true">
                        <br>
                    </div>
                </div>

                <div class="edit-cards  col2 " style=" max-width: 20%; flex-grow: 1;">
                    <div class="">

                        <button class="button button" type="submit"   [disabled]="!itemForm.form.valid || !(skinSelect&&skinSelect.titulo==true) "
                            style=" width: 100%; background-color: {{colorButton}}">
                            <!-- style="background-color: {{colorButton}}; font-family: {{buttonFont}}; font-size: {{buttonSize}}; color: {{buttonColor}};" -->
                            Guardar Noticia
                        </button><br>
                        
                        <button class="button" type="submit"  
                            style="width: 100%;background-color: {{colorButton}}" onclick="history.go(0)">
                            <!-- style="background-color: {{colorButton}}; font-family: {{buttonFont}}; font-size: {{buttonSize}}; color: {{buttonColor}};" -->
                            Cancelar Cambios
                        </button>
                    </div>
                </div>
    
            </div>
            <!--  <div class="wrapper-button">
                <button class="w-100" [disabled]="loading" (click)="loadImages()">
            
                    {{(loading) ? 'Cargando...' : 'Subir'}}
                </button>
            </div>   -->
        </form>


    </div>
 
    <div class=" col12 " style="text-align: center; margin-left: 5px; background-color: {{colorSite}};" *ngIf="vistaPreliminar == true">
        <h4 style="text-align: center;">Vista preliminar de la Noticia</h4>
        <app-noticias  [verSolo]="'noticias'" [varempresa]="varempresa"  [edicion]="true" [noticiaEdit]="noticiaEdit" [imagenPrevia1]="imagenPrevia">  </app-noticias>
    
    </div>
    </div>
</div>
 