import * as internal from "events";
import { MergeMapSubscriber } from "rxjs/internal/operators/mergeMap";


export class ClassNoticia{

    constructor(
        public cod_noticia: number,
        public cod_empresa: string,
        public fecha: string,
        public titulo: string,
        public texto_corto: string,
        public noticia: string,
        public imagen1: string,
        public imagen2: string,
        public imagen3: string,
        public activa: number,
        public cod_noticias_skin: number,
        public letra_titulo: string,
        public tamano_titulo: string,
        public color_titulo: string,
        public letra_otros: string,
        public tamano_otros: string,
        public color_otros: string,
        public color_fondo: string


    ){

    }
}
