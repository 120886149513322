<!-- style="  font-family: {{buttonFont}}; font-size: {{buttonSize}}; color: {{buttonColor}};"-->
<div class="container"  style="min-width: 100% ; padding: 0%;">
    
    <div>
        <table *ngIf="datosPartido&&datosPartido[0]['iniciado']" style="display:grid; width: 100%; border-bottom: 1px; margin-bottom: 10px;">
            <tr>
                <td style="flex: auto; width: 100%;"> 
                    <p *ngIf="datosPartido[0]['fecha_inicio']" style="font-size: x-small; text-align: left; color:black;">
                        {{ datosPartido[0]['fecha_inicio'] | date:'dd/MM/yyyy HH:mm'}}
                    </p>
                    <p *ngIf="!datosPartido[0]['fecha_inicio']&&(datosPartido[0]['equipo1']!=null&&datosPartido[0]['equipo2']!=null)" 
                        style="font-size: x-small; text-align: left; color:black;">
                        Sin Confirmar
                    </p>
                </td>
                <td *ngIf="datosPartido[0]['iniciado']&&!datosPartido[0]['finalizado']" 
                    style="max-width: 60px; min-width: 60px;">  
                    <p style="font-size: x-small; text-align: right; color:green;"> 
                        En Vivo
                    </p>
                </td>
                <td *ngIf="datosPartido[0]['finalizado']" style="max-width: 60px; min-width: 60px;">  
                    <p style="font-size: x-small; text-align: right; color: blue;"> 
                        Finalizado
                    </p>
                </td>
            </tr>

            <tr *ngIf="datosPartido[0]['iniciado']" (click)="verMenuEdit()" style="background-color: rgb(225, 225, 225);"> 
                <td style="flex: auto; width: 100%;"  *ngIf="ganador==0&&movil"> {{datosPartido[0]['equipo1Largo']}}</td>
                <td style="flex: auto; width: 100%;"  *ngIf="ganador==0&&!movil"> {{datosPartido[0]['equipo1Largo']}}</td>
                <td style="flex: auto; width: 100%;"  *ngIf="ganador==1&&movil"> <b>{{datosPartido[0]['equipo1Largo']}}</b></td>
                <td style="flex: auto; width: 100%;"  *ngIf="ganador==1&&!movil"> <b>{{datosPartido[0]['equipo1Largo']}}</b></td>
                <td style="flex: auto; width: 100%; color: gray;"  *ngIf="ganador==2&&movil" > {{datosPartido[0]['equipo1Largo']}}</td>
                <td style="flex: auto; width: 100%; color: gray;"  *ngIf="ganador==2&&!movil" > {{datosPartido[0]['equipo1Largo']}}</td>
                <ng-container *ngFor="let set of datosPartido">
                    <td style="max-width: 33px; min-width: 33px;" *ngIf="ganador==0"><p> {{set['gamesEquipo1']}} <sup *ngIf="set['teabrekEquipo1']>0||set['teabrekEquipo2']>0">{{set['teabrekEquipo1']}}</sup></p> </td>
                    <td style="max-width: 33px; min-width: 33px;" *ngIf="ganador==1"><p><b>{{set['gamesEquipo1']}} <sup *ngIf="set['teabrekEquipo1']>0||set['teabrekEquipo2']>0">{{set['teabrekEquipo1']}}</sup></b> </p></td>
                    <td style="max-width: 33px; min-width: 33px; color: gray;" *ngIf="ganador==2" ><p>{{set['gamesEquipo1']}} <sup *ngIf="set['teabrekEquipo1']>0||set['teabrekEquipo2']>0">{{set['teabrekEquipo1']}}</sup></p> </td>
                    <td style="max-width: 33px; min-width: 33px;" *ngIf="set['pointEquipo1']&&set['pointEquipo2']&&ganador==0">
                        <p style="color: green;"> {{set['pointEquipo1']}} </p>
                    </td>
                </ng-container>
            </tr>
            <tr *ngIf="datosPartido[0]['iniciado']" (click)="verMenuEdit()" style="background-color: rgb(225, 225, 225);"> 

                <td style="flex: auto; width: 100%;" *ngIf="ganador==0&&movil"> {{datosPartido[0]['equipo2Largo']}}</td>
                <td style="flex: auto; width: 100%;" *ngIf="ganador==0&&!movil"> {{datosPartido[0]['equipo2Largo']}}</td>
                <td style="flex: auto; width: 100%;" *ngIf="ganador==2&&movil"> <b>{{datosPartido[0]['equipo2Largo']}}</b></td>
                <td style="flex: auto; width: 100%;" *ngIf="ganador==2&&!movil"> <b>{{datosPartido[0]['equipo2Largo']}}</b></td>
                <td style="flex: auto; width: 100%; color: gray;" *ngIf="ganador==1&&movil" > {{datosPartido[0]['equipo2Largo']}}</td>
                <td style="flex: auto; width: 100%; color: gray;" *ngIf="ganador==1&&!movil" > {{datosPartido[0]['equipo2Largo']}}</td>

                <ng-container *ngFor="let set of datosPartido">
                    <td style="max-width: 33px; min-width: 33px;" *ngIf="ganador==0">{{set['gamesEquipo2']}} <sup *ngIf="set['teabrekEquipo1']>0||set['teabrekEquipo2']>0">{{set['teabrekEquipo2']}}</sup> </td>
                    <td style="max-width: 33px; min-width: 33px;" *ngIf="ganador==2"><b>{{set['gamesEquipo2']}} <sup *ngIf="set['teabrekEquipo1']>0||set['teabrekEquipo2']>0">{{set['teabrekEquipo2']}}</sup></b> </td>
                    <td style="max-width: 33px; min-width: 33px; color: gray;" *ngIf="ganador==1" >{{set['gamesEquipo2']}} <sup *ngIf="set['teabrekEquipo1']>0||set['teabrekEquipo2']>0">{{set['teabrekEquipo2']}}</sup> </td>
                    
                    <td  style="max-width: 33px; min-width: 33px;;" *ngIf="set['pointEquipo1']&&set['pointEquipo2']&&ganador==0">
                        
                        <p style="color: green;"> {{set['pointEquipo2']}} </p>
                    </td>

                </ng-container>
            </tr> 
        </table>    

            
        <table *ngIf="datosPartido&&!datosPartido[0]['iniciado']&&(datosPartido[0]['equipo1']!=null&&datosPartido[0]['equipo2']!=null)" 
        style="display:grid; width: 100%; border-bottom: 1px; margin-bottom: 10px;">
            <tr>
                <td style="flex: auto; width: 100%;"> 
                    <p *ngIf="datosPartido[0]['fecha_inicio']" style="font-size: x-small; text-align: left; color:black;">
                        {{ datosPartido[0]['fecha_inicio'] | date:'dd/MM/yyyy HH:mm'}}
                    </p>
                    <p *ngIf="!datosPartido[0]['fecha_inicio']" 
                        style="font-size: x-small; text-align: left; color:black;">
                        Sin Confirmar
                    </p>
                </td>
                <td style="max-width: 60px; min-width: 60px;">  
                    <p style="font-size: x-small; text-align: right; color: gray;"> 
                        Pendiente
                    </p>
                </td> 
            </tr>
            <tr (click)="verMenuEdit()" style="background-color: rgb(225, 225, 225);">
                <td style="flex: auto; width: 100%;" *ngIf="(datosPartido[0]['equipo1']!=null&&datosPartido[0]['equipo2']!=null)">
                    {{datosPartido[0]['equipo1Largo']}}
                </td>
                
            </tr>
            <tr style="background-color: rgb(225, 225, 225);">
                <td  *ngIf="(datosPartido[0]['equipo1']!=null&&datosPartido[0]['equipo2']!=null)">
                    {{datosPartido[0]['equipo2Largo']}}
                </td>
            </tr>
        </table>
        
        <table *ngIf="datosPartido&&!datosPartido[0]['iniciado']&&(datosPartido[0]['equipo1']==null||datosPartido[0]['equipo2']==null)" 
        style="display:grid; width: 100%; border-bottom: 1px; margin-bottom: 10px;" >
            <tr>
                <td style="flex: auto; width: 100%;"> 
                    
                </td>     
                <td style="max-width: 60px; min-width: 60px;">  
                    <p style="font-size: x-small; text-align: right; color: blue;"> 
                        Fecha Libre
                    </p>
                </td> 
            </tr>
            <tr (click)="verMenuEdit()" style="background-color: rgb(225, 225, 225);">
                <td style="flex: auto; width: 100%;" >
                    {{datosPartido[0]['equipo1Largo']}}{{datosPartido[0]['equipo2Largo']}}
                </td>
            </tr>
        </table>
                        


    </div>

    <!-- Menu edit -->
    <div *ngIf="menuEdit && seter==1"  class="edit-cards " [ngClass]="(isMovil == 'S')? 'col2' : 'col1'  " >
        <div  style="justify-content: center; flex-wrap: nowrap; display: flex; " >
            
            <button disabled class="button-radius " style="background-color:rgb(149, 149, 144); font-family: {{buttonFont}}; font-size: {{buttonSize}}; color: {{buttonColor}};"
            (click)="editar()" title="Editar Fecha"  *ngIf="datosPartido[0]['iniciado']||datosPartido[0]['equipo1']==null||datosPartido[0]['equipo2']==null">
                <i class="fa fa-calendar" style="font-size:x-large;"></i>  
            </button>
            <button  class="button-radius " style="background-color:darkgreen; font-family: {{buttonFont}}; font-size: {{buttonSize}}; color: {{buttonColor}};"
            (click)="editar()" title="Editar Fecha"  *ngIf="!datosPartido[0]['iniciado']&&(datosPartido[0]['equipo1']!=null&&datosPartido[0]['equipo2']!=null)">
                <i class="fa fa-calendar" style="font-size:x-large;"></i>  
            </button>

            <button disabled  class="button-radius " style="background-color:rgb(149, 149, 144); font-family: {{buttonFont}}; font-size: {{buttonSize}}; color: {{buttonColor}};"
            (click)="cargarPuntos()" title="Cargar Puntos" *ngIf="datosPartido[0]['equipo1']==null||datosPartido[0]['equipo2']==null">
                <i class="	fa fa-pencil" style="font-size:x-large;"></i>
            </button>
            <button  class="button-radius " style="background-color:darkgreen; font-family: {{buttonFont}}; font-size: {{buttonSize}}; color: {{buttonColor}};"
            (click)="cargarPuntos()" title="Cargar Puntos" *ngIf="datosPartido[0]['equipo1']!=null&&datosPartido[0]['equipo2']!=null">
                <i class="	fa fa-pencil" style="font-size:x-large;"></i>  
            </button>

            <button class="button-radius " style="background-color:coral; font-family: {{buttonFont}}; font-size: {{buttonSize}}; color: {{buttonColor}};"
                (click)="verMenuEdit()" title="Cerrar Menu"> 
                <i class="fa fa-low-vision" style="font-size:x-large;"></i>  
            </button>
        </div> 
    </div>

    <!-- edit fecha -->
    <div *ngIf="datosPartido&&!datosPartido[0]['iniciado']&&edit">
        <form style="width: 100%;" #itemForm="ngForm" (ngSubmit)="onSubmit(itemForm);">
            <table class="table table-light">
                <tr>
                    <td>
                        
                        <tr>
                            <td>
                                <div class="input-info">
                                    <label style="margin: 5px ;"> Fecha </label> 
                                <input class="form-control" style="width: max-content;"  type="date" required name="fecha" value="{{strfecha}}"  (change)="tratarFecha($event.target.value)">
                                        
                                </div> 
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="input-info">
                                    <label style="margin: 5px ;"> Hora </label> 
                                    <input class="form-control" style="width: max-content;"  type="time"  required name="hora" value="{{strhora}}"  (change)="tratarHora($event.target.value)">
                                </div> 
                                <!-- <button class="button button" type="submit">save</button> -->
                                
                                <div  class="edit-cards " [ngClass]="(isMovil == 'S')? 'col2' : 'col1'  " >
                                    <div  style="justify-content: right; flex-wrap: nowrap; display: flex; " >
                                        <!-- TERMINAR GRUPOS Y ARMAR LLAVE -->
                                        <button  class="button button " style="flex: 0 0 calc(66%); margin-right: 0%; background-color:darkgreen; font-family: {{buttonFont}}; font-size: {{buttonSize}}; color: {{buttonColor}};"
                                        type="submit">Guardar
                                        </button>
                                        <!-- volver atras -->
                                        <button class="button button " style="flex: 0 0 calc(33%); margin-right: 0%; background-color:coral; font-family: {{buttonFont}}; font-size: {{buttonSize}}; color: {{buttonColor}};"
                                            (click)="salir()">Salir 
                                        </button>
                                        
            
                                    </div> 
                                </div>
                            </td>
                        </tr>
                        
                    </td>
                </tr>
            </table>
        </form>
    </div>

    <!-- edit puntos -->
    <div *ngIf="editPuntos"  class="edit-cards " [ngClass]="(isMovil == 'S')? 'col2' : 'col1'  " >
        
        <div *ngIf="datosPartido&&!datosPartido[0]['iniciado']"  style="justify-content: center; flex-wrap: nowrap; display: flex; " >
            <div>
                <b>Iniciar partido</b>
            </div>
            <button  class="button-radius " style="background-color:darkgreen; font-family: {{buttonFont}}; font-size: {{buttonSize}}; color: {{buttonColor}};"
            (click)="iniciarPartido()" title="Iniciar Partido"  *ngIf="!datosPartido[0]['iniciado']">
                <i class="fa fa-play" style="font-size:x-large;"></i>  
            </button>
            <button class="button-radius " style="background-color:coral; font-family: {{buttonFont}}; font-size: {{buttonSize}}; color: {{buttonColor}};"
                (click)="salir()" title="Salir sin iniciar el partido"> 
                <i class="fa fa-low-vision" style="font-size:x-large;"></i>  
            </button>
        </div>
    </div>
    <div *ngIf="editPuntos&&datosPartido&&datosPartido[0]['iniciado']">
            <table style="flex:auto; width: 100%;">
                <tr style="width: 100%;"> 
                    <ng-container *ngFor="let set of datosPartido">
                        <td style="flex: auto; width: 30%;">
                            
                                <button *ngIf="set['NroSet']==setEditando" class="button button " style="width: 100%; margin-right: 2%; background-color:rgb(38, 97, 198);  color:white; font-family: {{buttonFont}}; font-size: {{buttonSize}};"
                                    (click)="verSet(set['NroSet'])">Set: {{set['NroSet']}}
                                </button>
                                <button *ngIf="set['NroSet']!==setEditando" class="button button " style="width: 100%; margin-right: 2%; background-color:rgb(211, 211, 219); font-family: {{buttonFont}}; font-size: {{buttonSize}}; color: {{buttonColor}};"
                                    (click)="verSet(set['NroSet'])">Set: {{set['NroSet']}}
                                </button>
                                
                        </td> 
                    </ng-container> 
                    <td style="flex: auto; width: 10%;">
                            
                        <button  class="button button " style="width: 100%; margin-right: 2%; background-color:rgb(211, 211, 219); font-family: {{buttonFont}}; font-size: {{buttonSize}}; color: {{buttonColor}};"
                            (click)="addSet()">
                            <i class="fa fa-plus-circle"></i>
                        </button>
                        
                    </td>  
                </tr>
            </table>
            <table style="flex:auto; width: 100%;">
                <tr style="width: 100%;">
                    <td style="max-width: 100%; min-width: 100%; text-align: center;"> 
                        
                    </td>
                </tr> 
            </table>
            <table style="flex:auto; width: 100%;">
                <tr style="flex:auto ;width: 100%;">
                    <td style="width: 40%; text-align: center;"> 
                        <b>{{datosPartido[(setEditando-1)]['equipo1']}}</b>
                    </td>
                    <td style="flex:auto ; "> 
                        {{'  '}}
                    </td>
                    <td style="width: 40%; text-align: center;"> 
                        <b>{{datosPartido[(setEditando-1)]['equipo2']}}</b>
                    </td>
                </tr>        
                <tr>
                    <td style="text-align: center;"> 
                        <!-- equipo1 games and tae -->
                        <table style="flex:auto; width: 100%;">
                            <tr>
                                <td style="flex:auto ; text-align: center;">
                                    <!-- equipo1 games -->
                                    <table style="flex:auto; width: 100%;">
                                        <tr>
                                            <td style="width: 100%; text-align: center;">
                                                <!-- equipo1 games add-->
                                                <button class="button button " style="background-color:darkgreen; font-family: {{buttonFont}}; font-size: {{buttonSize}}; color: {{buttonColor}};"
                                                    (click)="addGame(1 ,'game','+')" title="sumar game">
                                                    <i class="fa fa-plus-circle" style="font-size:large;"></i>  
                                                </button>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style="width: 100%; text-align: center; ">
                                                <!-- equipo1 games info-->
                                                <button style="font-size: xx-large; text-align: center; border-radius: 50%; width: 50px; height: 50px ; ">
                                                    <b>{{datosPartido[(setEditando-1)]['gamesEquipo1']}}</b>
                                                </button>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style="width: 100%; text-align: center;">
                                                <!-- equipo1 games sup-->
                                                <button class="button button " style="background-color:darkgreen; font-family: {{buttonFont}}; font-size: {{buttonSize}}; color: {{buttonColor}};"
                                                    (click)="addGame(1 ,'game','-')" title="restar game">
                                                    <i class="fa fa-minus-circle" style="font-size:large;"></i>  
                                                </button>
                                            </td>
                                        </tr>
                                    </table>
                                </td>
                                <td style="width: 30%; text-align: center; background-color: rgb(135, 153, 153);">
                                    <!-- equipo1 tae -->
                                    <table style="flex:auto; width: 100%;">
                                        <tr>
                                            <td style="width: 100%; text-align: center; ">
                                                <!-- equipo1 games info-->
                                                <button style="font-size: large; text-align: center; border-radius: 50%; width: 30px; height: 30px ; ">
                                                    <b>{{datosPartido[(setEditando-1)]['teabrekEquipo1']}}</b>
                                                </button>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style="width: 100%; text-align: center;">
                                                <!-- equipo1 games add-->
                                                <button class="button button " style="background-color:darkgreen; font-family: {{buttonFont}}; font-size: {{buttonSize}}; color: {{buttonColor}};"
                                                    (click)="addGame(1 ,'tae','+')" title="sumar game" >
                                                    <i class="fa fa-plus-circle" style="font-size:medium;"></i>  
                                                </button>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style="width: 100%; text-align: center;">
                                                <!-- equipo1 games sup-->
                                                <button class="button button " style="background-color:darkgreen; font-family: {{buttonFont}}; font-size: {{buttonSize}}; color: {{buttonColor}};"
                                                    (click)="addGame(1 ,'tae','-')" title="restar game">
                                                    <i class="fa fa-minus-circle" style="font-size:large;"></i>  
                                                </button>
                                            </td>
                                        </tr>
                                    </table>
                                </td>
                                    
                            </tr>
                            
                        </table>
                    </td>
                    <td > 
                        
                    </td>
                    <td style="text-align: center;"> 
                        <!-- equipo2 games and tae -->
                        <table style="flex:auto; width: 100%;">
                            <tr>
                                <td style="flex:auto ; text-align: center; ">
                                    <!-- equipo2 games -->
                                    <table style="flex:auto; width: 100%;">
                                        <tr>
                                            <td style="width: 100%; text-align: center;">
                                                <!-- equipo1 games add-->
                                                <button class="button button " style="background-color:darkgreen; font-family: {{buttonFont}}; font-size: {{buttonSize}}; color: {{buttonColor}};"
                                                    (click)="addGame(2 ,'game','+')" title="sumar game">
                                                    <i class="fa fa-plus-circle" style="font-size:large;"></i>  
                                                </button>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style="width: 100%; text-align: center; ">
                                                <!-- equipo2 games info-->
                                                <button style="font-size: xx-large; text-align: center; border-radius: 50%; width: 50px; height: 50px ; ">
                                                    <b>{{datosPartido[(setEditando-1)]['gamesEquipo2']}}</b>
                                                </button>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style="width: 100%; text-align: center;">
                                                <!-- equipo2 games sup-->
                                                <button class="button button " style="background-color:darkgreen; font-family: {{buttonFont}}; font-size: {{buttonSize}}; color: {{buttonColor}};"
                                                    (click)="addGame(2 ,'game','-')" title="restar game">
                                                    <i class="fa fa-minus-circle" style="font-size:large;"></i>  
                                                </button>
                                            </td>
                                        </tr>
                                    </table>
                                </td>
                                <td style="width: 30%; text-align: center; background-color: rgb(135, 153, 153);">
                                    <!-- equipo2 tae -->
                                    <table style="flex:auto; width: 100%;">
                                        <tr>
                                            <td style="width: 100%; text-align: center; ">
                                                <!-- equipo2 games info-->
                                                <button style="font-size: large; text-align: center; border-radius: 50%; width: 30px; height: 30px ; ">
                                                    <b>{{datosPartido[(setEditando-1)]['teabrekEquipo2']}}</b>
                                                </button>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style="width: 100%; text-align: center;">
                                                <!-- equipo2 games add-->
                                                <button class="button button " style="background-color:darkgreen; font-family: {{buttonFont}}; font-size: {{buttonSize}}; color: {{buttonColor}};"
                                                    (click)="addGame(2 ,'tae','+')" title="sumar game">
                                                    <i class="fa fa-plus-circle" style="font-size:large;"></i>  
                                                </button>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style="width: 100%; text-align: center;">
                                                <!-- equipo2 games sup-->
                                                <button class="button button " style="background-color:darkgreen; font-family: {{buttonFont}}; font-size: {{buttonSize}}; color: {{buttonColor}};"
                                                    (click)="addGame(2 ,'tae','-')" title="restar game">
                                                    <i class="fa fa-minus-circle" style="font-size:large;"></i>  
                                                </button>
                                            </td>
                                        </tr>
                                    </table>
                                </td>
                            </tr>
                        </table>
                    </td>
                </tr>        
            </table>
            <!-- guardar  -->

            <div  class="edit-cards " [ngClass]="(isMovil == 'S')? 'col2' : 'col1'  " >
                <div  style="justify-content: right; flex-wrap: nowrap; display: flex; " >
                    <!-- TERMINAR GRUPOS Y ARMAR LLAVE -->
                    <button *ngIf="!guardarProcesando" class="button button " style="flex: 0 0 calc(66%); margin-right: 0%; background-color:darkgreen; font-family: {{buttonFont}}; font-size: {{buttonSize}}; color: {{buttonColor}};"
                        (click)="guardarPartido()">Guardar
                    </button>
                    <button *ngIf="guardarProcesando" disabled class="button button " style="flex: 0 0 calc(66%); margin-right: 0%; background-color:rgb(126, 127, 126); font-family: {{buttonFont}}; font-size: {{buttonSize}}; color: {{buttonColor}};"
                        >
                    </button>
                    <!-- volver atras -->
                    <button class="button button " style="flex: 0 0 calc(33%); margin-right: 0%; background-color:coral; font-family: {{buttonFont}}; font-size: {{buttonSize}}; color: {{buttonColor}};"
                        (click)="salir()">Volver 
                    </button>
                </div> 
            </div>
            <div *ngIf="message != ''" class="edit-cards " style="background-color:slategray;" [ngClass]="(isMovil == 'S')? 'col2' : 'col1'  " >
                <div  style="justify-content: left; flex-wrap: nowrap; display: flex; " >
                    <!-- informar resultado -->
                    <b>{{message}}</b>
                </div> 
            </div>

    </div>    
</div>
