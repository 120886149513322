import { Time, formatDate } from '@angular/common';
import { Component, OnInit, Input } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { SiteService }  from 'src/app/services/site.service';
import { TurnosService }  from 'src/app/services/turnos.service';
import { TurnosGeneral, TurnosCanchasFecha, TurnosCanchas, Turno } from 'src/app/clases/data';
import { empty } from 'rxjs';

@Component({
  selector: 'app-turnos',
  templateUrl: './turnos.component.html',
  styleUrls: ['./turnos.component.css'],
  providers: [SiteService, TurnosService]
})
export class TurnosComponent implements OnInit {
  @Input() varempresa: string;
  public canchas_resp;
  public colorSite: string;

  //filtrar - mostrar
  public Deporte = [];
  public TipoCancha = [];
  public Canchas = [];
  public horarios = [];
  public _turnoGeneral: TurnosGeneral;
  
  public mostrar: boolean = false;
  public CodUser: number = 0;
  
  //seleccionados
  public deporteSeleccionado: string;
  public tipoCanchaSeleccionado: string;


  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _siteServices: SiteService,
    private _turnosService: TurnosService,
  ) {}
  
  onCancha(cancha: number){
    //console.log(cancha);
  }

  optenerHora(time:string) {
    let arr = time.split(':');
    let resultado:Date;
    resultado = new Date();
    resultado.setHours(Number(arr[0]),Number(arr[1]),0);
  return resultado;
  }

  HoraTexto(time:string) {
    let arr = time.split(':');
    if (arr[0].length == 1){
      arr[0]='0'+[arr[0]];
    }
    if (arr[1].length == 1){
      arr[1]='0'+[arr[1]];
    }
    return arr[0]+':'+arr[1];
  }

  FechaTexto(fecha:Date) {
    let day: string; let month: string; let year : string; let dayn : string;  
    let dayl : string;
    dayn = String(fecha.getDay());
    day = String(fecha.getDate());
    month = String(fecha.getMonth()+1);
    year = String(fecha.getFullYear());
    
    if (dayn == "0") {
      dayl = "Dom.";
    } else if (dayn == "1") {
      dayl = "Lun.";
      } else if (dayn == "2") {
        dayl = "Mar.";
        } else if (dayn == "3") {
          dayl = "Mie.";
          } else if (dayn == "4") {
            dayl = "Jue.";
            } else if (dayn == "5") {
              dayl = "Vie.";
              } else if (dayn == "6") {
                dayl = "Sab.";
    }
    
    
    if (day.length == 1){
      day = '0'+day;
    }
    if (month.length == 1){
      month = '0'+month;
    }
    return dayl+' '+day+'/'+month+'/'+year;
    //return day+'/'+month+'/'+year;
  }

  pidirTurno(hora, fecha, cod_cancha, cod_tipo_cancha){
  
    let identity;
    let token;
    token = this._siteServices.getToken();
    identity = this._siteServices.getIdentity();
    let body={
      empresa: this.varempresa,
      codCancha: cod_cancha,
      fecha: formatDate(fecha, 'yyyyMMdd', 'en_US'),
      hora: hora,
      codUser: identity.cod_user,
      codTipoCancha: cod_tipo_cancha
    };
    //console.log(body);
    this._turnosService.altaTurnosClientes(body, token).subscribe(
      response => {
        if(response.status != 'error'){
          
          if(response.correcto != 'No'){
          
            //console.log(response);
            this.onSelectDeporte(this.deporteSeleccionado);
            this.onSelectTipoCancha(this.tipoCanchaSeleccionado);
            this.mostrar = true;
          }else{
            console.log('response.motivo');
          }

        }else{
          console.log(response.message);
        }
      },
      error => {
        console.log(error);
      } 
    )
  }


  onSelectTipoCancha(tipoCancha: string){
    this.mostrar = false;
    this.ProcSelectTipoCancha(tipoCancha);
    //this.mostrar = true;
    //console.log('fin')
  }

    
  ProcSelectTipoCancha(tipoCancha: string){
    this.mostrar = false;
    this.tipoCanchaSeleccionado = tipoCancha;
    // busco las canchas de este tipo de cancha
    this.Canchas = [];
    let contador = 0;
    for (let i=0; i<this.canchas_resp.length; i++ ){
      if ((this.canchas_resp[i].deporte == this.deporteSeleccionado) && (this.canchas_resp[i].tipo_cancha == this.tipoCanchaSeleccionado)){
        this.Canchas[contador]=this.canchas_resp[i];
        contador++;
      }
      
    }
    
    //una vez que tengo las canchas proceso los horarios
    let fecha = new Date();
    //console.log(formatDate(fecha, 'yyyyMMdd', 'en_US'));
    
    
    this._turnoGeneral = new TurnosGeneral([null]);
    for (let dias = 0; dias < 5; dias++){
      
      //calcular 5 dias
      // idea fecha cancha 1,2,3, ...
      // 9, 10.30, ...
      //console.log(formatDate(fecha, 'yyyyMMdd', 'en_US'));
      let _turnosCanchasFecha = new TurnosCanchasFecha(new Date(fecha),this.FechaTexto(new Date(fecha)),[null]);
      for (let i = 0; i<this.Canchas.length; i++){
        //recorro las canchas
        let inicio: Date; 
        let inicioIntervalo: Date; 
        let finintervalo: Date;
        let fin: Date; 
        let medida: Date;
        let hora: Date;
        inicio = this.optenerHora(this.Canchas[i].turno_inicial);
        inicioIntervalo=this.optenerHora(this.Canchas[i].turno_intermedio_inicial);
        finintervalo=this.optenerHora(this.Canchas[i].turno_intermedio_final);
        fin=this.optenerHora(this.Canchas[i].turno_final);
        medida=this.optenerHora(this.Canchas[i].turno_tiempo);
        hora= this.optenerHora(this.Canchas[i].turno_inicial);
        
        
        
        //calculo los tiempos..
        
        let _turnosCanchas = new TurnosCanchas(this.Canchas[i].cod_cancha,this.Canchas[i].empresa,[null],this.Canchas[i].cod_empresa,this.Canchas[i].cod_cancha, this.Canchas[i].cod_tipo_cancha);
         
        //let turnoorden = [];
        for (hora; hora < inicioIntervalo;){
          //no preguntar si estan ocupadas


          let Hora= this.HoraTexto(hora.getHours()+':'+hora.getMinutes());
          let _turnos = new Turno(Hora,'Libre', 1, null,1,null,null,null,null,null,'',null,null,null,null,null);
          _turnosCanchas.turnos.push(_turnos);
          
          
          hora.setHours(hora.getHours()+medida.getHours(),hora.getMinutes()+medida.getMinutes());
          

        };
        //agrego intermedio
        let _turnoIntermedio = new Turno(this.HoraTexto(this.Canchas[i].turno_intermedio_inicial),'Intermedio', 2, null,1,null,null,null,null,null,'',null,null,null,null,null);
        _turnosCanchas.turnos.push(_turnoIntermedio);
        
        
        hora= this.optenerHora(this.Canchas[i].turno_intermedio_final);
        for (hora; hora < fin;){
          // no preguntar si estan ocupadas


          let Hora= this.HoraTexto(hora.getHours()+':'+hora.getMinutes());
          let _turnos = new Turno(Hora,'Libre', 3, null,1,null,null,null,null,null,'',null,null,null,null,null);
          _turnosCanchas.turnos.push(_turnos);
          
          
          hora.setHours(hora.getHours()+medida.getHours(),hora.getMinutes()+medida.getMinutes());
          
        }
        

        var indice = _turnosCanchas.turnos.indexOf(null); // obtenemos el indice
        _turnosCanchas.turnos.splice(indice, 1); // 1 es la cantidad de elemento a eliminar
        
      
        _turnosCanchasFecha.canchas.push(_turnosCanchas);

      }
      this._turnoGeneral.fechas.push(_turnosCanchasFecha);
      
      fecha.setDate(fecha.getDate() + 1);
    }

    








    //recorrer objeto y chequear turnos


    for (let ifechas=1; ifechas<this._turnoGeneral.fechas.length; ifechas++){
      this.mostrar = false;
      //console.log(this._turnoGeneral.fechas[ifechas].fechaTexto);
      for (let icancha=1; icancha<this._turnoGeneral.fechas[ifechas].canchas.length; icancha++){
        //console.log(this._turnoGeneral.fechas[ifechas].canchas[icancha].cancha);
        for (let iturno=0; iturno<this._turnoGeneral.fechas[ifechas].canchas[icancha].turnos.length; iturno++){
        
          //console.log(this._turnoGeneral.fechas[ifechas].canchas[icancha].turnos[iturno].hora);
          //console.log(this._turnoGeneral.fechas[ifechas].canchas[icancha].turnos[iturno].estado);
          if (this._turnoGeneral.fechas[ifechas].canchas[icancha].turnos[iturno].estado == 'Libre'){
            //pregunto estado a db



            let body={
              Empresa: this._turnoGeneral.fechas[ifechas].canchas[icancha].cod_empresa,
              Cancha:this._turnoGeneral.fechas[ifechas].canchas[icancha].cod_cancha,
              Fecha: formatDate(this._turnoGeneral.fechas[ifechas].fecha, 'yyyyMMdd', 'en_US'),
              Hora: this._turnoGeneral.fechas[ifechas].canchas[icancha].turnos[iturno].hora
            };
            
            
            
            this._siteServices.turnos(body).subscribe(
              response => {
                if(response.status != 'error'){
                  if (response.length != 0){
                    for (let variohoras = 0; variohoras<response.length; variohoras++){
                      if (response[variohoras].activo == 1 && response[variohoras].fecha_cancelado == null){
                        //ocupado
                        this._turnoGeneral.fechas[ifechas].canchas[icancha].turnos[iturno].estado = 'Ocupado';
                        this._turnoGeneral.fechas[ifechas].canchas[icancha].turnos[iturno].cod_cliente = response[variohoras].cod_cliente;
                        // cargo data nueva 20231026
                        this._turnoGeneral.fechas[ifechas].canchas[icancha].turnos[iturno].activo = response[variohoras].activo;
                        this._turnoGeneral.fechas[ifechas].canchas[icancha].turnos[iturno].cod_usuario_alta = response[variohoras].cod_usuario_alta;
                        this._turnoGeneral.fechas[ifechas].canchas[icancha].turnos[iturno].fecha_alta = response[variohoras].fecha_alta;
                        this._turnoGeneral.fechas[ifechas].canchas[icancha].turnos[iturno].cod_lugar_alta = response[variohoras].cod_lugar_alta;
                        this._turnoGeneral.fechas[ifechas].canchas[icancha].turnos[iturno].fecha_cancelado = response[variohoras].fecha_cancelado;
                        this._turnoGeneral.fechas[ifechas].canchas[icancha].turnos[iturno].cod_usuario_cancelo = response[variohoras].cod_usuario_cancelo;
                        this._turnoGeneral.fechas[ifechas].canchas[icancha].turnos[iturno].motivo_cancelacion = response[variohoras].motivo_cancelacion;
                        this._turnoGeneral.fechas[ifechas].canchas[icancha].turnos[iturno].cod_motivo_cancelacion = response[variohoras].cod_motivo_cancelacion;
                        this._turnoGeneral.fechas[ifechas].canchas[icancha].turnos[iturno].fecha_confirmado = response[variohoras].fecha_confirmado;
                        this._turnoGeneral.fechas[ifechas].canchas[icancha].turnos[iturno].cod_usuario_confirmo = response[variohoras].cod_usuario_confirmo;
                        this._turnoGeneral.fechas[ifechas].canchas[icancha].turnos[iturno].cod_turno = response[variohoras].cod_turno;
                        this._turnoGeneral.fechas[ifechas].canchas[icancha].turnos[iturno].cod_turno_fijo = response[variohoras].cod_turno_fijo;
                      }
                    }
                    //console.log(response);
                    
                  }
                  
                }else{
                  console.log('response.message');
                }
              },
              error => {
                console.log(error);
              } 
            )



          }
        }
      }
     
    }









    //this.mostrar = true;
    //console.log(this._turnoGeneral);
    
  }
  
  onSelectDeporte(deporte: string){
    this.mostrar = false;
    this.deporteSeleccionado = deporte;
    // busco los tipos de canchas de este deporte
    this.TipoCancha = [];
    let vardeporte = '';
    for (let i=0; i<this.canchas_resp.length; i++ ){
      if (this.canchas_resp[i].deporte == this.deporteSeleccionado){
        if (vardeporte == ''){
          vardeporte = this.canchas_resp[i].tipo_cancha;
          this.TipoCancha[0] = vardeporte;
        }else{
          if (this.canchas_resp[i].tipo_cancha != vardeporte){
            vardeporte = this.canchas_resp[i].tipo_cancha;
            this.TipoCancha[this.TipoCancha.length] = vardeporte;
          }
        }
      }
    }
    if (this.TipoCancha.length == 1){
      this.onSelectTipoCancha(this.TipoCancha[0]);
      this.mostrar = true;
    } 
     
  }

  ngOnInit(): void { 
    let identity;
    identity = this._siteServices.getIdentity();

    if (!identity){
      this.CodUser = 0
    }else{
    this.CodUser = identity.cod_user;
    }

    this.mostrar = false;

    this.colorSite = this._siteServices.getColorSite();
    let body={Empresa: this.varempresa};
    this._siteServices.canchas(body).subscribe(
      response => {
        if(response.status != 'error'){
          
          this.canchas_resp = response;

//console.log(response);

          let vardeporte = '';
          for (let i=0; i<this.canchas_resp.length; i++ ){
            if (i==0){
              vardeporte = this.canchas_resp[i].deporte;
              this.Deporte[0] = vardeporte;
            }else{
              if (this.canchas_resp[i].deporte != vardeporte){
                vardeporte = this.canchas_resp[i].deporte;
                this.Deporte[this.Deporte.length] = vardeporte;
              }
            }
          }
          // para no preguntar si es unico deporte
          if (this.Deporte.length == 1){
            this.onSelectDeporte(this.Deporte[0]);
          }
        }else{
          console.log('response.message');
        }
      },
      error => {
        console.log(error);
      } 
    )
    


  }

} 
