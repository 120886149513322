<div class="alert alert-danger" *ngIf="status=='error'">
    {{message}}
    <h2> La sesion a expirado, vuelva a loguearse. </h2>
    <button class="button button" (click)="this._siteServices.logout(this.varempresa);" *ngIf="message=='no Autentificado'">
        Cerrar
    </button>
</div>

<div class="content" style="justify-content: space-between; background-color: {{colorSite}};">


<h2 style="text-align: center; " *ngIf="codTorneo" >Inscriptos {{dataTorneo.torneo}}</h2>

<div style="background-color: {{colorSite}};" *ngIf="message!='no Autentificado'">
    <div class="content">
        <form style="width: 100%;" #itemForm="ngForm" (ngSubmit)="onSubmit(itemForm);">
            <div class="row ">
                <div class="edit-cards col1 " style="margin-right: 2%;">
                
                    <div class="input-info">
                        <b>Torneo: </b>  {{dataTorneo.torneo}}
                    </div>
                    <div class="input-info">
                        <b>Deporte: </b>  {{dataTorneo.deporte}}
                    </div>

                    <div class="input-info">
                        <b>Resumen: </b> {{dataTorneo.descripcion}}
                    </div>

                    <div class="input-info">
                        <b>Descripcion: </b> {{dataTorneo.noticia}}
                    </div>

                    <div class="input-info">
                        <b>Fecha de inicio: </b> {{ _siteServices.fechaFormatoString(dataTorneo.fecha_inicio, 'largo') }}
                    </div>

                    <div class="input-info">
                        <b>Fecha Limite de inscripcion: </b> {{ _siteServices.fechaFormatoString(dataTorneo.fecha_limite_inscripcion, 'largo') }}
                    </div>

                    <div class="input-info">
                        <b>Cantidad de equipos: </b> {{ dataTorneo.plaza_equipos }}
                    </div>
                
                    <div class="input-info" *ngIf="itemText">
                        <b>Puntaje: </b> {{ itemText[0].texto }}
                    </div>
                </div>
                <div class="edit-cards col1">
                    <h3 style="text-align: center;">Inscripcion</h3>
                    <div  *ngIf="itemText">
                        <b>Anotados: </b>  {{itemText[0].anotados}}
                    </div>
                    <div  *ngIf="itemText">
                        <b>Confirmados: </b>  {{itemText[0].inscriptos}}
                    </div>
                    <div  *ngIf="itemText">
                        <b>En Cola de Espera: </b>  {{itemText[0].encola}}
                    </div>
                    <div  style="text-align: center;" *ngIf="itemText&&itemText[0].anotados+itemText[0].inscriptos < dataTorneo.plaza_equipos">
                        <b style="color: green; ">Lugar Disponible </b> 
                    </div>
                    


                    <div style="border-color: black; border-width: 1px; border-radius: 5px; border-style: solid; padding: 5%; padding-top: 5px;">
                    
                    <!-- Div de botones botones-->
                        <div  style="justify-content: space-between; flex-wrap: nowrap; display: flex; " *ngIf="identity&&!anotado&&ver===''">
                            <!-- elegir si me anoto o me uno a un grupo o salgo -->
                            <button class="button button" style="flex: 0 0 calc(33%);margin-right: 0%; background-color: {{colorButton}}; font-family: {{buttonFont}}; font-size: {{buttonSize}}; color: {{buttonColor}};"
                                (click)="ver='anotarse'" *ngIf="dataTorneo.inscripto==0">Inscribir mi equipo
                            </button>
                            <b style="color: green; " *ngIf="dataTorneo.inscripto==1">Ya estas Inscripto</b>
                            <button class="button button" style="flex: 0 0 calc(33%);margin-right: 0%; background-color: {{colorButton}}; font-family: {{buttonFont}}; font-size: {{buttonSize}}; color: {{buttonColor}};"
                                (click)="ver='unirme'" *ngIf="dataTorneo.inscripto==0">Unirme a mi equipo
                            </button>
                            <button class="button button " style="flex: 0 0 calc(33%); margin-right: 0%; background-color: {{colorButton}}; font-family: {{buttonFont}}; font-size: {{buttonSize}}; color: {{buttonColor}};"
                            (click)="salir()">Volver
                            </button>
                            
                        </div>
                        


                    <!-- Aca empieza el div para anotarse solo en grupo-->

                        <div  *ngIf="identity&&!anotado&&ver==='unirme'">
                            <!-- Unirme a mi equipo con un codigo -->
                            <form>
                                <h4 style="text-align: left;">Unirse a un Equipo</h4>

                                <div class="input-info">
                                <input class="form-control" id="_Codigo" type="text" style="width: 100%;" required title="Codigo" minlength="3" maxlength="20" placeholder="Código - Obligatorio"
                                        name="_Codigo" #_Codigo="ngModel" [(ngModel)]="unirseCodigo" >
                            </div>

                        
                            <button class="button button" style="margin-right: 0%; background-color: {{colorButton}}; font-family: {{buttonFont}}; font-size: {{buttonSize}}; color: {{buttonColor}};"
                                [disabled]="_Codigo.invalid" (click)="unirseAGrupo()">Unirme al torneo
                            </button>
                            <button class="button button" style="margin-right: 0%; background-color: {{colorButton}}; font-family: {{buttonFont}}; font-size: {{buttonSize}}; color: {{buttonColor}};"
                            (click)="salir(); ver=''">Volver
                            </button>
                            
                            </form>
                        </div>
                        
                        <div  *ngIf="identity&&!anotado&&ver==='anotarse'">
                            <div *ngIf="permiteIncribirse">
                            <!--crear form inscripcion -->
                                <form  style="width: 100%;" #itemForm="ngForm" (ngSubmit)="onSubmit(itemForm);">
                                    <div class="row ">
                                        <div style="margin-right: 2%;">
                                        <h4 style="text-align: left;">Inscripción Equipo</h4>
                                            <div  class="input-info">
                                                <label style="margin: 5px ; ">Equipo </label>
                                                <input class="form-control" type="text" style="width: 100%;" required title="Nombre del equipo" minlength="3" maxlength="45" placeholder="Nombre - Obligatorio "
                                                    name="equipo" #equipo="ngModel" [(ngModel)]="equipo1" >
                                            </div>
                                        </div>
                                    </div>

                                    <div  style=" max-width: 100%; flex-grow: 1;">
                                        <div class="">
                    
                                            <button class="button button"  [disabled]="!itemForm.form.valid"
                                                style=" width: max-content; background-color: {{colorButton}}" (click)="guardarGrupo()">
                                                <!-- style="background-color: {{colorButton}}; font-family: {{buttonFont}}; font-size: {{buttonSize}}; color: {{buttonColor}};" -->
                                                Anotarme
                                            </button>
                                            
                                            <button class="button" 
                                                style="width: max-content;background-color: {{colorButton}}" (click)="salir()">
                                                <!-- style="background-color: {{colorButton}}; font-family: {{buttonFont}}; font-size: {{buttonSize}}; color: {{buttonColor}};" -->
                                                Salir sin anotarme
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            <!--fin crear form inscripcion -->
                            </div>
                            <div *ngIf="!permiteIncribirse&&message!=''">
                                <h3 style="color: red;">Tu perfil no aplica con este torneo</h3>
                                <p style="color: red;">{{message}}</p>
                            </div>



                        </div>
                        <div  *ngIf="identity&&anotado&&ver==='anotarse'">
                            
                            {{anotado[0].mensaje}}
                            <p>Guarda este código para que tu compañero/a pueda unirse a tu equipo.</p>
                            <p>codigo: {{anotado[0].codigo}}</p>

                            
                            pronto recibiras mas informacion por e-mail. <br>

                            <button class="button button" style="margin-right: 0%; background-color: {{colorButton}}; font-family: {{buttonFont}}; font-size: {{buttonSize}}; color: {{buttonColor}};"
                            (click)="salir(); anotado = false;">Volver
                            
                            </button>
                            <!--(click)="ver=''; anotado = false;">Volver-->
                            
                        </div>
                        <div  *ngIf="identity&&unido&&ver==='resultado'">
                            <br>
                            <br>
                            <b> {{unido[0].mensaje}} </b><br>
                            

                            <button class="button button" style="margin-right: 0%; background-color: {{colorButton}}; font-family: {{buttonFont}}; font-size: {{buttonSize}}; color: {{buttonColor}};"
                            (click)="salir(); ver=''; anotado = false;">Volver
                            </button>

                        </div>

                        <div  *ngIf="!identity">
                            <b style="color: red;">Para acceder a la siguiente informacion de Inscripcion debe estar logueado en el sistema </b> 
                            
                        </div>

                    </div>
                    <!-- Aca termina div de  la incripcion-->

                </div>

            </div>
            
                
            
        </form>
    </div>
</div>

</div>
