<div class="alert alert-danger" *ngIf="status=='error'">
    {{message}}
    <h2 *ngIf="message=='no Autentificado'"> La sesion a expirado, vuelva a loguearse. </h2>
    <button class="button button" (click)="this._siteServices.logout(this.varempresa);" *ngIf="message=='no Autentificado'">
        Cerrar
    </button>
</div>

<div class="content" style="justify-content: space-between; background-color: {{colorSite}};">


    <h2 style="text-align: center; " *ngIf="codTorneo&&dataTorneo.isliga==0" >Habilitar Zonas del torneo: {{dataTorneo.torneo}}</h2>
    <h2 style="text-align: center; " *ngIf="codTorneo&&dataTorneo.isliga==1" >Habilitar Zonas de la Liga: {{dataTorneo.torneo}}</h2>

    <div style="background-color: {{colorSite}};" *ngIf="message!='no Autentificado'">
        <div class="content">
            <form style="width: 100%;" #itemForm="ngForm" (ngSubmit)="onSubmit(itemForm);">


                <div class="row ">
                    <div class="edit-cards " [ngClass]="(isMovil == 'S')? 'col2' : 'col1'  "  style="margin-right: 2%;">
                    

                        <div class="input-info" *ngIf="!noVer">
                            <b>Torneo: </b>  {{dataTorneo.torneo}}
                        </div>
                        <div class="input-info"  *ngIf="!noVer">
                            <b>Deporte: </b>  {{dataTorneo.deporte}}
                        </div>

                        <div class="input-info"  *ngIf="!noVer">
                            <b>Resumen: </b> {{dataTorneo.descripcion}}
                        </div>

                        <div class="input-info"  *ngIf="!noVer">
                            <b>Descripcion: </b> {{dataTorneo.noticia}}
                        </div>

                        <div class="input-info" >
                            <b>Fecha de inicio: </b> {{ _siteServices.fechaFormatoString(dataTorneo.fecha_inicio, 'largo') }}
                        </div>

                        <div class="input-info">
                            <b>Fecha Limite de inscripcion: </b> {{ _siteServices.fechaFormatoString(dataTorneo.fecha_limite_inscripcion, 'largo') }}
                        </div>

                        <div class="input-info">
                            <b>Cantidad de equipos: </b> {{ dataTorneo.plaza_equipos }}
                        </div>
                    
                        <div class="input-info" *ngIf="itemText && !noVer "  >
                            <b>Puntaje: </b> {{ itemText[0].texto }}
                        </div>
                    
                        <center>
                            <button class="button button" style=" width: 70%; border-radius: 10px; margin-right: 0%; background-color: {{colorButton}}; font-family: {{buttonFont}}; font-size: {{buttonSize}}; color: {{buttonColor}};"
                                (click)="noVer = !noVer" *ngIf="isMovil == 'S'"> {{ noVer ?  'Mostar +' : 'Mostrar -' }}
                            </button>
                        </center>

                    </div>
                    
                    <!--  empezar a cargar la tabla con ngfor-->
                    <ng-container *ngFor="let zona of general" >
                        

                        <div  class="edit-cards " [ngClass]="(isMovil == 'S')? 'col2' : 'col1'  " >
                            <h3 style="text-align: center;">Habilitacion de Zona: {{zona.zona}} </h3>
                            <div style="overflow-x: scroll;">
                                
                                <table class="table table-hover " >
                                    <thead>
                                        <tr >
                                            <th width="40%" >Responsable</th>
                                            <th width="40%"   *ngIf="isMovil=='N'" >Jugador 2</th>
                                            <th width="150%" ><center>nueva zona</center></th>
                                            <th width="10%" ><center>Cambiar</center></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let zonatabla of zona.tabla">
                                            <td>{{zonatabla.jug1}}</td>
                                            <td *ngIf="isMovil=='N'">{{zonatabla.jug2}}</td> 
                                            <td>
                                                <select (change)="cambiarZona(zonatabla.equipo, zonatabla.zona, $event.target.value)">
                                                    <option value="" seled hidden> Nueva Zona </option>
                                                    <option value="{{item.zona}}" *ngFor="let item of zonas" >
                                                        {{item.zona}}
                                                    </option>
                                                </select>
                                            </td>
                                            <td>
                                                <center>
                                                    <button class="button button" style="margin-right: 0%; background-color:coral; font-family: {{buttonFont}}; font-size: {{buttonSize}}; color: {{buttonColor}};"
                                                        (click)="cambiarZona(zonatabla.equipo, zonatabla.zona, 'A')" > Cambiar
                                                    </button>
                                                </center>
                                            </td>
                                            
                                        </tr>
                                    </tbody>
                                </table>
                                
                            </div>
                                <!-- Termina Tabla  -->
                        </div> <!-- end card -->
                    </ng-container>
                    <div class="row ">
                    <div  class="edit-cards " [ngClass]="(isMovil == 'S')? 'col2' : 'col1'  " >
                        
                        
                            <div  style="justify-content: right; flex-wrap: nowrap; display: flex; " >
                                <!-- cerrar la zona y armar partidos -->
                                <button class="button button " style="flex: 0 0 calc(66%); margin-right: 0%; background-color:darkgreen; font-family: {{buttonFont}}; font-size: {{buttonSize}}; color: {{buttonColor}};"
                                    (click)="guardarZonas()">Cerrar zonas y armar cruces
                                </button>
                                <!-- volver atras -->
                                <button class="button button " style="flex: 0 0 calc(33%); margin-right: 0%; background-color:coral; font-family: {{buttonFont}}; font-size: {{buttonSize}}; color: {{buttonColor}};"
                                    (click)="salir()">Salir 
                                </button>
                                

                            </div>
                        
                    </div>
                    </div>
                </div>    
            </form>
        </div>
    </div>

</div>
