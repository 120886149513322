import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { GLOBAL } from './global';

@Injectable()
export class MailService{
    public url:string;
    public token;
    public identity;
    
    constructor(
        public _http: HttpClient
    ){
        this.url = GLOBAL.url;
    }
    
    VerifyMail(body): Observable<any>{
        let json = JSON.stringify(body);
        let params = 'json='+json;
        let headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
                                       .set('Authorization', 'autory');
        return this._http.post(this.url+'mail/verify/code',params, {headers: headers});
    }

}

