import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SkindosComponent } from './component/skins/skindos/skindos.component';
import { SkintresComponent } from './component/skins/skintres/skintres.component';
import { DefaultComponent } from './component/skins/default/default.component';
import { loadskinssitesComponent } from './component/loads/loadskinssites.component';
// Zone Deporte
import { ZoneDeporteInicioComponent } from './component/zoneDeporteInicio/zoneDeporteInicio.component';
import { AppPuntosPadelComponent } from './component/appPuntosPadel/appPuntosPadel.component';

const routes: Routes = [
  {path:'', component: ZoneDeporteInicioComponent},
  
  //Load client site
  {path:'loadclientsite/:id', component: loadskinssitesComponent},

  // Apps
    //puntos Padel
    {path:'puntospadel/:id', component: AppPuntosPadelComponent},

  // skins clients sites
  {path:'uno/:id', component: DefaultComponent},
  {path:'uno/:id/:redi/:code', component: DefaultComponent},
  {path:'dos/:id', component: SkindosComponent},
  {path:'tres/:id', component: SkintresComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
