import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { GLOBAL } from './global';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { formatDate } from '@angular/common';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class SiteService{
    public url:string;

    constructor(
        public _http: HttpClient,
        public _router: Router

    ){
        this.url = GLOBAL.url;
    }
    
    logout(id){
        localStorage.removeItem('identity');
        localStorage.removeItem('token');
        localStorage.removeItem('seter');
        localStorage.removeItem('userimg');
        //redirecciono
        this._router.navigate(['loadclientsite/'+id]);
    }
    setColorSite(color:string){
        localStorage.setItem('colorSite', color);
    }
    getColorSite(){
        return localStorage.getItem('colorSite');
    }
    diaSpa(diaeng:string){
        let dia: string;
        switch (diaeng) {
            case 'Sunday':
                dia = 'Domingo';
                break;
            case 'Monday':
                dia = 'Lunes';
                break;
            case 'Tuesday':
                dia = 'Martes';
                break;
            case 'Wednesday':
                dia = 'Miercoles';
                break;
            case 'Thursday':
                dia = 'Jueves';
                break;
            case 'Friday':
                dia = 'Viernes';
                break;
            case 'Saturday':
                dia = 'Sabado';
                break;   
        }
        return dia;
    }
    mesSpa(data:number){
        let dia: string;
        switch (data) {
            case 1:
                dia = 'Enero';
                break;
            case 2:
                dia = 'Febrero';
                break;
            case 3:
                dia = 'Marzo';
                break;
            case 4:
                dia = 'Abril';
                break;
            case 5:
                dia = 'Mayo';
                break;
            case 6:
                dia = 'Junio';
                break;
            case 7:
                dia = 'Julio';
                break;   
            case 8:
                dia = 'Agosto';
                break;   
            case 9:
                dia = 'Setiembre';
                break;   
            case 10:
                dia = 'Octubre';
                break;   
            case 11:
                dia = 'Noviembre';
                break;   
            case 12:
                dia = 'Diciembre';
                break;   
        }
        return dia;
    }
    fechaFormatoString(fecha:Date, formato:string ){
        if (formato == 'largo'){
            let dia = formatDate(fecha,'EEEE,d,M,y','en-US');
            let roto = dia.split(',');
            
            return this.diaSpa(roto[0])+' '+roto[1]+' de '+this.mesSpa(Number(roto[2]))+' de '+roto[3];
        }
    }

    loadClientSites(data): Observable<any>{
        let json = JSON.stringify(data);
        let params = 'json='+json;
        let headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
        return this._http.post(this.url+'site/load/clientsite',params, {headers: headers});
    }
    loadDomineSites(data): Observable<any>{
        let json = JSON.stringify(data);
        let params = 'json='+json;
        let headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
        return this._http.post(this.url+'site/load/clientdomine',params, {headers: headers});
    }
    canchas(data): Observable<any>{
        let json = JSON.stringify(data);
        let params = 'json='+json;
        let headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
        return this._http.post(this.url+'getcanchas',params, {headers: headers});
    }
    turnos(data): Observable<any>{
        let json = JSON.stringify(data);
        let params = 'json='+json;
        let headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
        return this._http.post(this.url+'getturnosotorgados',params, {headers: headers});
    }
    noticias(data): Observable<any>{
        let json = JSON.stringify(data);
        let params = 'json='+json;
        let headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
        return this._http.post(this.url+'getnoticias',params, {headers: headers});
    }
    noticiasSkins(token: string): Observable<any>{
        let headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
                                        .set('Authorization', token);
        return this._http.post(this.url+'getnoticiasSkins','', {headers: headers});
    }
    getIdentity(){
        let identity = JSON.parse(localStorage.getItem('identity'));
        if(identity != 'undefined'){
            return identity;
        }else{
            return null;
        }
    }

    getToken(){
        let token = localStorage.getItem('token');
        if(token != 'undefined'){
            return token;
        }else{
            return null;
        }
    }
    updateskin(data, token: string): Observable<any>{
        let json = JSON.stringify(data);
        let params = 'json='+json;
        let headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
                                        .set('Authorization', token);
        return this._http.post(this.url+'site/update/skin',params, {headers: headers});
    }
    noticiasInsUpd(data, token: string): Observable<any>{
        let json = JSON.stringify(data);
        let params = 'json='+json;
        let headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
                                        .set('Authorization', token);
        return this._http.post(this.url+'setnoticias',params, {headers: headers});
    }
}