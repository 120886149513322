import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';


@Injectable()
export class LocalService{
    public url:string;

    constructor(
        public _http: HttpClient

    ){
        this.url = "https://zonedeporte.com/Local/";  

    }
    uploadFile(archivo) {
        return this._http.post(`${this.url}noticiaUp.php`, JSON.stringify(archivo));
    }
    uploadFileTorneo(archivo) {
        return this._http.post(`${this.url}torneoUp.php`, JSON.stringify(archivo));
    }
    
}