import * as internal from "events";
import { MergeMapSubscriber } from "rxjs/internal/operators/mergeMap";


export class login{

    constructor(
        public email: string,
        public clave: string,
        public factor: string,
        public ip: string,
        public getToken: string,
        public empresa: number
    ){

    }
}
export class user{

    constructor(
        public email: string,
        public clave: string,
        public nombre: string,
        public apellido: string,
        public fechaNacimiento: Date,
        public sexo: string, //M F
        public dni: string,
        public tipo: string //usuario

    ){

    }
}
