
<!--<div class="container" style="width: fit-content;">-->
<div class="container" style="min-width: 100% ; padding: 0%;" *ngIf="datosPartido">

    <table class="table table-light">
        <!--<caption>Partido xxx</caption>-->
        <!-- <thead> 
        <tr>
            <th>Producto</th>
            <th>Cantidad</th>
            <th>Precio</th>
        </tr>
        </thead>
        <tbody>-->
            
            <tr class="table-primary1" *ngIf="iniciado">
            <!--   *ngFor="let set of datosPartido" --> 
                <td>
                    <tr class="table-primary1">
                        <td *ngIf="ganador==0"> {{datosPartido[0]['equipo1']}}</td>
                        <td *ngIf="ganador==1"> <b>{{datosPartido[0]['equipo1']}}</b></td>
                        <td *ngIf="ganador==2" style="color: gray;" > {{datosPartido[0]['equipo1']}}</td>
                    </tr>
                    <tr class="table-primary1">
                        <td *ngIf="ganador==0"> {{datosPartido[0]['equipo2']}}</td>
                        <td *ngIf="ganador==2"> <b>{{datosPartido[0]['equipo2']}}</b></td>
                        <td *ngIf="ganador==1" style="color: gray;" > {{datosPartido[0]['equipo2']}}</td>
                    </tr>
                   
                </td>
                <ng-container *ngFor="let set of datosPartido">
                <td  >
                    <tr class="table-primary1">
                        <td *ngIf="ganador==0">{{set['gamesEquipo1']}} <sup *ngIf="set['teabrekEquipo1']>0||set['teabrekEquipo2']>0">{{set['teabrekEquipo1']}}</sup> </td>
                        <td *ngIf="ganador==1"><b>{{set['gamesEquipo1']}} <sup *ngIf="set['teabrekEquipo1']>0||set['teabrekEquipo2']>0">{{set['teabrekEquipo1']}}</sup></b> </td>
                        <td *ngIf="ganador==2" style="color: gray;">{{set['gamesEquipo1']}} <sup *ngIf="set['teabrekEquipo1']>0||set['teabrekEquipo2']>0">{{set['teabrekEquipo1']}}</sup> </td>
                    </tr>
                    <tr class="table-primary1">
                        <td *ngIf="ganador==0">{{set['gamesEquipo2']}} <sup *ngIf="set['teabrekEquipo1']>0||set['teabrekEquipo2']>0">{{set['teabrekEquipo2']}}</sup> </td>
                        <td *ngIf="ganador==2"><b>{{set['gamesEquipo2']}} <sup *ngIf="set['teabrekEquipo1']>0||set['teabrekEquipo2']>0">{{set['teabrekEquipo2']}}</sup></b> </td>
                        <td *ngIf="ganador==1" style="color: gray;">{{set['gamesEquipo2']}} <sup *ngIf="set['teabrekEquipo1']>0||set['teabrekEquipo2']>0">{{set['teabrekEquipo2']}}</sup> </td>
                    </tr>
                </td>
                <td  *ngIf="set['pointEquipo1']&&set['pointEquipo2']&&ganador==0">
                    <tr class="table-primary1" style="color: green;">
                        <td>{{set['pointEquipo1']}} </td>
                    </tr>
                    <tr class="table-primary1" style="color: green;">
                        <td>{{set['pointEquipo2']}} </td>
                    </tr>
                </td>
                </ng-container>
            </tr>



            <tr class="table-primary" style="width: 100%;" *ngIf="!iniciado&&(datosPartido[0]['equipo1']!=null&&datosPartido[0]['equipo2']!=null)">
                <td class="col1"  >
                    <!--<tr class="table-primary col1">
                        <td class="col1" style="background-color: brown;"> {{datosPartido[0]['equipo1']+'    Vs    '+datosPartido[0]['equipo2']}}</td>
                    </tr>
                    <tr class="table-primary " style="width: 100%;">
                        
                        <td *ngIf="datosPartido[0]['fecha_inicio']!==null"> <b>{{'Fecha de partido: '+datosPartido[0]['fecha_inicio']}}</b></td>
                        <td *ngIf="datosPartido[0]['fecha_inicio']===null"> <b>{{'Fecha de partido: Sin Confirmar'}}</b></td>
                    </tr>
                -->
                    <div class="col1 " [ngClass]="{'partido-container': !movil} " [ngStyle]=" !movil ?  {'text-align': 'center'} : {'text-align': 'left'} " >
                        <div  [ngClass]=" {'col-5': !movil , 'col-10': movil} " style="padding: 0%;">
                            <b> {{datosPartido[0]['equipo1']}}</b>
                        </div>
                        <div class="col-2" *ngIf="!movil">
                            <b> {{'    Vs    '}}</b>
                        </div> 
                        <div [ngClass]=" {'col-5': !movil , 'col-10': movil} " style="padding: 0%;">
                            <b> {{datosPartido[0]['equipo2']}}</b>
                        </div>
                    </div>
                    <div class="col1" [ngStyle]=" !movil ?  {'text-align': 'center'} : ({'text-align': 'left'} && {'padding': '10px'}) " *ngIf="datosPartido[0]['fecha_inicio']!==null">
                        {{'Fecha : '+datosPartido[0]['fecha_inicio']}}
                    </div>
                    <div class="col1" [ngStyle]=" !movil ?  {'text-align': 'center'} : ({'text-align': 'left'} && {'padding': '10px'})  " *ngIf="datosPartido[0]['fecha_inicio']===null">
                        {{'Fecha : Sin Confirmar'}}
                    </div>
                </td> 
            </tr>




            <tr class="table-primary" style="width: 100%; margin: 0%; padding: 0%;" *ngIf="!iniciado&&(datosPartido[0]['equipo1']==null||datosPartido[0]['equipo2']==null)">
                <td class="col1" >
                    <!--
                    <tr class="table-primary">
                        <td *ngIf="datosPartido[0]['equipo1']!==null"><b> {{datosPartido[0]['equipo1']}}</b></td>
                        <td *ngIf="datosPartido[0]['equipo2']!==null"><b> {{datosPartido[0]['equipo2']}}</b></td>
                    </tr>
                    <tr>
                        <td style="width: 100%;"> <b>{{'Fecha libre'}}</b></td>
                    </tr>
                    -->

                    <div class="col1" [ngStyle]=" !movil ?  {'text-align': 'center'} : {'text-align': 'left'} "
                        *ngIf="datosPartido[0]['equipo1']!==null">
                        <b> {{datosPartido[0]['equipo1']}}</b>
                    </div>
                    <div class="col1"  [ngStyle]=" !movil ?  {'text-align': 'center'} : {'text-align': 'left'} "
                        *ngIf="datosPartido[0]['equipo2']!==null">
                        <b> {{datosPartido[0]['equipo2']}}</b>
                    </div>
                    <div class="col1" [ngStyle]=" !movil ?  {'text-align': 'center'} : ({'text-align': 'left'} && {'padding': '10px'}) ">
                        {{'Fecha libre'}}
                    </div>

                </td>
            </tr>
        <!-- </tbody> -->
    </table>
</div>
