import { MailService } from './../../services/mail.service';
import {Component, OnInit, Input} from '@angular/core';
import {Router, ActivatedRoute, Params} from '@angular/router';
import { user } from '../../clases/user';
import { UserService } from '../../services/user.service';
import { formatDate } from '@angular/common';

@Component({
    selector: 'app-perfil',
    templateUrl: './perfil.component.html',
    providers:[UserService, MailService],
    styleUrls:['./perfil.css']
})
export class PerfilComponent implements OnInit{
    @Input() varempresa: number;
    @Input() userId: number;
    public title: string;
    public user: user;
    public status: string;
    public message:string;
    public categoriaPadel: string;
    public perfilCompleto: boolean = false;
    view2factor: boolean = false;
    view2factorMethod: string = 'mail';
    
    public app: string;

    fechaLimite = Date.now();

    constructor(
        private _route: ActivatedRoute,
        private _router: Router,
        private _userService: UserService,
        private _mailService: MailService,
        
    ){
        this.title = 'Perfil Usuario';
        this.user = new user('','','','',null,'','','usuario');
        
    }

    /*tratarDate(campo:string){
    
        if (campo == 'limite'){
          let fechaString = formatDate(this.fechaLimite,'yyyy/MM/dd HH:mm','en-US');
          this.user.fechaNacimiento = fechaString;
        }
    
        //console.log('entro y sali con valores '+campo+ ' '+this.mytorneo.fecha_limite_inscripcion);
      } */
    
    ngOnInit(){
        //console.log(this.userId)
        this._route.params.subscribe(
            params => {
                this.app = params['app'];
            }    
        );
        if(this.app){
           // console.log(this.app);
        }
        this.reload();
    }
    
    reload(){
        console.log('entro a reload');
        let body = {'empresa': this.varempresa, 'userId':this.userId};
        let token = this._userService.getToken(); 
        this._userService.perfil(body, token).subscribe(
            response => {
                //console.log(response);
                if(response.status != 'error'){
                    if (response[0].categoria != null){
                        this.perfilCompleto = true;
                        this.categoriaPadel = response[0].categoria;
                        console.log('categoria: '+this.categoriaPadel);
                    }else{

                    this.perfilCompleto = false;
                    }
                }else{
                    this.status = response.status;
                    this.message = response.message;
                }        
            },
            error => {
                console.log(<any> error);
            }
        )
    }

    onSubmit(registerForm){
        this.clearMessage();
        if(registerForm.valid)
        { 
            //console.log(this.categoriaPadel);
            let body = {'empresa': this.varempresa, 'userId':this.userId, 'catPadel': this.categoriaPadel};
            let token = this._userService.getToken(); 
            this._userService.setPerfil(body, token).subscribe(
                response => {
                    console.log(response);
                    if(response.status != 'error'){
                        this.reload();
                        
                    }else{
                        this.status = response.status;
                        this.message = response.message;
                    }        
                },
                error => {
                    console.log(<any> error);
                }
            )
        }
        
    }

    clearMessage() {
        this.status = '';
        this.message = '';
    }
    onForget(form) {
        this.clearMessage();
        form.reset();
    }

    
}