import { Component, OnInit, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { SiteService } from 'src/app/services/site.service';
import { Router, ActivatedRoute, Params } from '@angular/router';

@Component({
  selector: 'app-ligas',
  templateUrl: './appPuntosPadel.component.html',
  styleUrls: ['./appPuntosPadel.component.css'],
  providers: [SiteService]
})
export class AppPuntosPadelComponent implements OnInit {
public movil: boolean;
public data: any;
  constructor( private _siteServices: SiteService,
              private _route: ActivatedRoute,
              private _router: Router,
              @Inject(DOCUMENT) document: any
  )  { }

  ngOnInit(): void {
    if (localStorage.getItem('navi') == 'N'){
      this.movil = false;
    } else {
      this.movil = true;
    }
    this.data = {'equipo':'my equipo','user1':{'nombre':'martin','codigo':'1'},'user2':{'nombre':'miguel', 'codigo':'2'}};
    /*let body = {'domine': document.location.href}
    this._siteServices.loadDomineSites(body).subscribe(
      response => {
        if(response.status != 'error'){
          if (response[0].codigo != 0){
            console.log('http'+response[0].domineSearch+'loadclientsite/'+ response[0].codigo);
            window.location.href = 'http'+response[0].domineSearch+'loadclientsite/'+ response[0].codigo;
          }
        }else{
          console.log(response);
        }
      },
      error => {
        console.log(error);
      } 
    )*/
  }

}
