import { Component, OnInit, Input } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { SiteService }  from 'src/app/services/site.service';
import { UserService }  from 'src/app/services/user.service';
import { ClassNoticia } from "src/app/clases/noticia";
import { formatDate } from '@angular/common';
import { DomSanitizer } from '@angular/platform-browser';
import { LocalService } from 'src/app/services/local.service';
 

@Component({
  selector: 'app-add-noticias',
  templateUrl: './agregarNoticia.component.html',
  styleUrls: ['./agregarNoticia.component.css'],
  providers: [SiteService, UserService, LocalService]
})
export class AgregarNoticiaComponent implements OnInit {
  @Input() varempresa: string;
  @Input() codNoticiaUpdate: number;
  @Input() dataNoticiaUpdate: any;

  
  selectedFile: File=null;
  imagenPrevia: any;
  imagenUp1: any;
  imagenPrevia2: any;
  imagenUp2: any;
  imagenPrevia3: any;
  imagenUp3: any;
  
  loading: boolean;
  vartoken;

  //errors
  status = ''; message='';

  public colorSite: string;
  skins; skinSelect;
  public noticia = new ClassNoticia(0,'',formatDate(Date.now(),'yyyy/MM/dd HH:mm', 'en-US'),'','','','','','',1,0,'','','#000000','','','#000000','#ffffff');
  vistaPreliminar: boolean = false;
  noticiaEdit;
  
  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _siteServices: SiteService,
    private _userService: UserService,
    private sanitizer: DomSanitizer,
    private _localService: LocalService) { }
    

  ngOnInit(): void {
    //console.log(this.codNoticiaUpdate);
    this.colorSite = this._siteServices.getColorSite();
    
    this.vartoken = this._userService.getToken();
    //console.log(this.vartoken);
    this._siteServices.noticiasSkins(this.vartoken).subscribe(
      response => {
        if(response.status != 'error'){
          
          this.skins = response;
          //console.log(this.skins);
          
          this.noticia.cod_empresa = this.varempresa;
          this.noticia.noticia = '';
          this.noticia.texto_corto = '';
          this.noticia.titulo = '';
          this.noticia.imagen1 = '';
          this.noticia.imagen2 = '';
          this.noticia.imagen3 = '';
          
          if(this.codNoticiaUpdate!=null){
            this.noticia.cod_noticia = this.dataNoticiaUpdate.cod_noticia;
            this.noticia.cod_empresa = this.dataNoticiaUpdate.cod_empresa;
            this.noticia.fecha = this.dataNoticiaUpdate.fecha;
            this.noticia.titulo = this.dataNoticiaUpdate.titulo;
            this.noticia.texto_corto = this.dataNoticiaUpdate.texto_corto;
            this.noticia.noticia = this.dataNoticiaUpdate.noticia;
            this.noticia.imagen1 = this.dataNoticiaUpdate.imagen1;
            this.noticia.imagen2 = this.dataNoticiaUpdate.imagen2;
            this.noticia.imagen3 = this.dataNoticiaUpdate.imagen3;
            this.noticia.activa = this.dataNoticiaUpdate.activa;
            this.noticia.cod_noticias_skin = this.dataNoticiaUpdate.cod_skin;
            this.noticia.letra_titulo = this.dataNoticiaUpdate.letra_titulo;
            this.noticia.tamano_titulo = this.dataNoticiaUpdate.tamano_titulo;
            this.noticia.color_titulo = this.dataNoticiaUpdate.color_titulo;
            this.noticia.letra_otros = this.dataNoticiaUpdate.letra_otros;
            this.noticia.tamano_otros = this.dataNoticiaUpdate.tamano_otros;
            this.noticia.color_otros = this.dataNoticiaUpdate.color_otros;
            this.noticia.color_fondo = this.dataNoticiaUpdate.color_fondo;
          }
      
          //console.log(this.noticia);
      
      
      
      
          //this.selectSkin();
          this.selectSkin();






        }else{
          this.status = response.status;
          this.message = response.message;
        }
      },
      error => {
        console.log(error);
      } 
    )
    
    
  }


  _handleReaderLoaded1(readerEvent){
    var binaryString = readerEvent.target.result;
    this.imagenUp1 = btoa(binaryString);
  }
  _handleReaderLoaded2(readerEvent){
    var binaryString = readerEvent.target.result;
    this.imagenUp2 = btoa(binaryString);
  }
  _handleReaderLoaded3(readerEvent){
    var binaryString = readerEvent.target.result;
    this.imagenUp3 = btoa(binaryString);
  }


  cargarImagen(event, name:string){
    const imagen = event.target.files[0];
    if (imagen.type.includes('image')) {
      //otra opcion
      this.blobFile(imagen).then((res: any) => {
        if (name == 'imagen1'){
          var reader = new FileReader();
          reader.onload = this._handleReaderLoaded1.bind(this);
          reader.readAsBinaryString(imagen);
          this.noticia.imagen1=imagen.name;
          
          
          this.imagenPrevia = res.base;
          
        
      
      
        }else{
          if (name == 'imagen2'){
            var reader = new FileReader();
            reader.onload = this._handleReaderLoaded2.bind(this);
            reader.readAsBinaryString(imagen);
            this.imagenPrevia2 = res.base;
            this.noticia.imagen2=imagen.name;
          }else{
            if (name == 'imagen3'){
              var reader = new FileReader();
              reader.onload = this._handleReaderLoaded3.bind(this);
              reader.readAsBinaryString(imagen);
              this.imagenPrevia3 = res.base;
              this.noticia.imagen3=imagen.name;
            }
          }
        }
      })
      
    } else {
      console.log('No es imagen');
    };
  }
  





  actualizarVista(){
    this.vistaPreliminar = false;
    if (this.noticia.cod_noticia == 0){
      this.noticiaEdit=[{
        activa: this.noticia.activa,
        cod_empresa: this.noticia.cod_empresa,
        cod_noticia: this.noticia.cod_noticia+1,
        fecha: this.noticia.fecha,
        noticia: this.noticia.noticia,
        texto_corto: this.noticia.texto_corto,
        tipo_noticia: "noticia",
        titulo: this.noticia.titulo,
        cod_skin: this.noticia.cod_noticias_skin,
        letra_titulo: this.noticia.letra_titulo,
        tamano_titulo: this.noticia.tamano_titulo,
        color_titulo: this.noticia.color_titulo,
        letra_otros: this.noticia.letra_otros,
        tamano_otros: this.noticia.tamano_otros,
        color_otros: this.noticia.color_otros,
        color_fondo: this.noticia.color_fondo,
        imagen1: this.noticia.imagen1,
        imagen2: this.noticia.imagen2,
        imagen3: this.noticia.imagen3
  
      }];
    }else{
      this.noticiaEdit=[{
        activa: this.noticia.activa,
        cod_empresa: this.noticia.cod_empresa,
        cod_noticia: this.noticia.cod_noticia,
        fecha: this.noticia.fecha,
        noticia: this.noticia.noticia,
        texto_corto: this.noticia.texto_corto,
        tipo_noticia: "noticia",
        titulo: this.noticia.titulo,
        cod_skin: this.noticia.cod_noticias_skin,
        letra_titulo: this.noticia.letra_titulo,
        tamano_titulo: this.noticia.tamano_titulo,
        color_titulo: this.noticia.color_titulo,
        letra_otros: this.noticia.letra_otros,
        tamano_otros: this.noticia.tamano_otros,
        color_otros: this.noticia.color_otros,
        color_fondo: this.noticia.color_fondo,
        imagen1: this.noticia.imagen1,
        imagen2: this.noticia.imagen2,
        imagen3: this.noticia.imagen3
  
      }];
    }
    
    setTimeout(() => { this.vistaPreliminar = true; }, 1);
    //console.log(this.noticiaEdit);
  }

  selectSkin(){
    this.skinSelect = null;
    for(let i=0; i<this.skins.length; i++){
      if (this.skins[i].cod_noticias_skin == this.noticia.cod_noticias_skin){
        this.skinSelect = this.skins[i];
        break;
      }
    }
    this.actualizarVista();
  }

  onSubmit(form){
    //hacer control y ...
    let body = {
      activa: this.noticia.activa,
      cod_empresa: this.noticia.cod_empresa,
      cod_noticia: this.noticia.cod_noticia,
      fecha: this.noticia.fecha,
      noticia: this.noticia.noticia,
      texto_corto: this.noticia.texto_corto,
      tipo_noticia: "noticia",
      titulo: this.noticia.titulo,
      cod_noticias_skin: this.noticia.cod_noticias_skin,
      letra_titulo: this.noticia.letra_titulo,
      tamano_titulo: this.noticia.tamano_titulo,
      color_titulo: this.noticia.color_titulo,
      letra_otros: this.noticia.letra_otros,
      tamano_otros: this.noticia.tamano_otros,
      color_otros: this.noticia.color_otros,
      color_fondo: this.noticia.color_fondo,
      imagen1: this.noticia.imagen1,
      imagen2: this.noticia.imagen2,
      imagen3: this.noticia.imagen3
    };
    this._siteServices.noticiasInsUpd(body, this.vartoken).subscribe(
      response => {
        if(response.status != 'error'&&this.imagenPrevia!=undefined){

          //console.log(response[0].cod_noticia);
          this.noticia.cod_noticia = response[0].cod_noticia;
          let body1 = {
            nombreArchivo: this.noticia.imagen1,
            base64textString: this.imagenUp1,
            noticia: this.noticia.cod_noticia,
            empresa: this.noticia.cod_empresa
          }
          this._localService.uploadFile(body1).subscribe(
            response => {
              //console.log(response);

            },
            error => {
              console.log(error);
            } 
          )    
              


        }else{
          this.status = response.status;
          this.message = response.message;
        }
      },
      error => {
        console.log(error);
      } 
    )  
    //redirecciono
    this._router.navigate(['loadclientsite/'+this.varempresa]);
    
  }

  blobFile = async ($event: any) => new Promise((resolve, reject) => {
    try {
      const unsafeImg = window.URL.createObjectURL($event);
      const image = this.sanitizer.bypassSecurityTrustUrl(unsafeImg);
      const reader = new FileReader();
      reader.readAsDataURL($event);
      reader.onload = () => {
        resolve({
          //blob: $event,
          //image,
          base: reader.result
        });
      };
      reader.onerror = error => {
        resolve({
          //blob: $event,
          //image,
          base: null
        });
      };

    } catch (e) {
      return null;
    }
  })

}
 