//import { EMailService } from './../../services/EMail.service';
//import { isDefined } from '@angular/compiler/src/util';
//import { isNull } from '@angular/compiler/src/output/output_ast';
import {Component, OnInit, Input} from '@angular/core';
import {Router, ActivatedRoute, Params} from '@angular/router';
//import { isEmpty } from 'rxjs-compat/operator/isEmpty';
import { login } from '../../clases/user';
import { UserService } from '../../services/user.service';
import { AppComponent } from './../../app.component';
import { exit } from 'process';

  
@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    providers:[UserService],
    styleUrls:['./login.css']
})
export class LoginComponent implements OnInit{
    @Input() varempresa: number;

    public title: string;
    public user: login;
    public status: string;
    public message:string;
    public token;
    public identity;
    public showFogetPassword: boolean = false;
    public view2factor: boolean = false;
    public view2factorMethod: string;
    public view2factorphone: string;
    public ult4phone: string;
    public url: string;
    public app: string;
    constructor(
        private _route: ActivatedRoute,
        private _router: Router,
        private _userService: UserService
        //private _emailService: EMailService,
        
    ){
        this.title = 'Login Page';
        this.user = new login('','', 'null','0.0.0.0', null, this.varempresa);
        
    }
    
    ngOnInit(){
        this.logout();
        this._route.params.subscribe(
            params => {
                this.app = params['app'];
            }    
        );
        if(this.app){
            console.log(this.app);
        }

    }
    
    

    onSubmit(form){
        this.clearMessage();
        if(form.valid)
        { 
           // console.log(this.user);
            this._userService.signup(this.user).subscribe(
                response => {
                   // console.log(response);
                    if(response.status != 'error'){
                        //chequeo si trae mas de un usuario para elegir
                        if(response.status == 'otherUser'){
                            //trae lista de usuarios
                            this.identity = response.salida;
                            this.status = response.status
                            //console.log(this.identity);


                        }else{



                            if ( this.user.factor == 'null' ){
                                this.token = response;
                                
                                localStorage.setItem('token',this.token);
                            }
                            this._userService.signup(this.user, this.token).subscribe(
                                response => { 
                                    //console.log(response);
                                    if(response.status != 'error'){
                                        if((response.status == 'mail')||(response.status == 'sms')){
                                            this.view2factor = true;
                                            this.user.factor = '';
                                            this.view2factorMethod = response.status;
                                            this.view2factorphone = response.message;
                                            this.ult4phone = this.view2factorphone.slice(-4)
                                        }else{
                                            this.identity = response;
                                            if(this.app){
                                                this.identity.app = 'si';
                                            }
                                            localStorage.setItem('identity', JSON.stringify(this.identity));                                  
                                            //verifico
                                            
                                            // ingreso a empresa el usuario. si ya no existe y verifico si es adm
                                            let body={Usuario: this.identity.cod_user, Empresa: this.varempresa};
                                            this._userService.userEmpresa(body).subscribe(
                                                response => {     
                                                    if(response.status != 'error'){
                                                        //console.log(response);
                                                        if(response[0].tipo == 'admin'){
                                                            localStorage.setItem('seter', '1');
                                                        };
                                                        localStorage.setItem('userimg', response[0].imagen);
                                                        this._router.navigate(['loadclientsite/'+this.varempresa]);
                                                        

                                                         
                                                    }else{
                                                    }
                                                },
                                                error => {
                                                    console.log(error);
                                                }
                                            )
                                            
                                        }                                 
                                    }else{
                                        // console.log('error');
                                    }
                                },
                                error => {
                                    // console.log(response);
                                }
                            )
                            this.status = response.status;
                            this.message = response.message;                                
                            

                        }
                        
                        
                    }else{
                        this.status = response.status;
                        this.message = response.message;
                    }        
                },
                error => {
                    console.log(<any> error);
                }
            )
        }
        
    }

    logout(){
        this._route.params.subscribe( params => {
            let logout = +params['sure'];
            if(logout == 1){
                localStorage.removeItem('identity');
                localStorage.removeItem('token');
                localStorage.removeItem('seter');
                localStorage.removeItem('userimg');
                this.identity = null;
                this.token = null;
                //redirecciono
                this._router.navigate(['loadclientsite/'+this.varempresa]);
            }
        })
    }
    clearMessage() {
        this.status = '';
        this.message = '';
    }
    onForget(form) {
        /*this.clearMessage();
        if(form.valid){
            this._emailService.getValidate(this.user).subscribe(
                response => {
                    if(response.status != 'error'){ 
                        this.status = response.status;
                        this.message = response.message;
                        //vacio el form
                        this.user = new loginClas('','','null','0.0.0.0',null,0)
                        form.reset();
                        this.showFogetPassword = false;
                    } else {
                        this.status = response.status;
                        this.message = response.message;
                        
                    }      
                }
            );
        };
        */
    }

    
}