import { Component, OnInit, Inject, Input } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { SiteService } from 'src/app/services/site.service';
import { Router, ActivatedRoute, Params } from '@angular/router';

@Component({
  selector: 'widgedEquipoPadel',
  templateUrl: './widgedequipoPadel.component.html',
  styleUrls: ['./widgedequipoPadel.component.css'],
  providers: [SiteService]
}) 

export class widgedEquipoPadelComponent implements OnInit {
  @Input() data: [];
  public movil: boolean;
  public user1: boolean;
  public user2: boolean;
  constructor( private _siteServices: SiteService,
              private _route: ActivatedRoute,
              private _router: Router,
              @Inject(DOCUMENT) document: any
  )  { }

  ngOnInit(): void {
    if (localStorage.getItem('navi') == 'N'){
      this.movil = false;
    } else {
      this.movil = true;
    }
    
    if (this.data){
      console.log(this.data);
      if (this.data['user1']){
        this.user1 = this.data['user1'];
      }
      if (this.data['user2']){
        this.user2 = this.data['user2'];
      }
    }

    /*let body = {'domine': document.location.href}
    this._siteServices.loadDomineSites(body).subscribe(
      response => {
        if(response.status != 'error'){
          if (response[0].codigo != 0){
            console.log('http'+response[0].domineSearch+'loadclientsite/'+ response[0].codigo);
            window.location.href = 'http'+response[0].domineSearch+'loadclientsite/'+ response[0].codigo;
          }
        }else{
          console.log(response);
        }
      },
      error => {
        console.log(error);
      } 
    )*/

  }

}
