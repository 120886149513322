import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-canchas',
  templateUrl: './canchas.component.html',
  styleUrls: ['./canchas.component.css']
})
export class CanchasComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
