
<div class=" col-12 content column " style="width: 100%;  padding-left: 15Px; padding-top: 20px; 
                display: flex; flex-direction: column; align-items: center;" > 
    
    <div  class="  news-card "  >
        <div >
            <div > 
                <!-- BEGIN LOGIN -->
                <!-- <div class="content"> -->
                    <!-- BEGIN LOGIN FORM -->
                    <div style="display: flex; flex-direction: column;" >
                        <form #loginForm="ngForm" (ngSubmit)="onSubmit(loginForm);" class="login-form" *ngIf="!showFogetPassword&&!showFogetPassword">
                            <h1 class="form-title"  *ngIf="view2factor">Seguridad de dos factores</h1>
                            <h1 class="form-title" style="text-align: center;" *ngIf="!view2factor">Ingreso</h1>
                            <div class="alert alert-info display-hide" *ngIf="view2factor&&view2factorMethod=='mail'">
                                <button class="close" data-close="alert"></button>
                                <span>Se envio un codigo al e-mail {{user.email}} </span>
                            </div>
                            <div class="alert alert-info display-hide" *ngIf="view2factor&&view2factorMethod=='sms'">
                                <button class="close" data-close="alert"></button>
                                <!--    <span>A verification code was sent via SMS to the phone {{view2factorphone}} </span> -->
                                <span>Se envio un codigo via SMS al telefono ...{{this.ult4phone}} </span>
                            </div>
                            <div class="alert alert-danger display-hide" *ngIf="status=='error'">
                                <button class="close" data-close="alert"></button>
                                <span>{{message}}</span>
                            </div>
                            <div class="alert alert-danger display-hide" *ngIf="status=='mail'&&message=='codigo error'">
                                <button class="close" data-close="alert"></button>
                                <span>{{message}}</span>
                            </div>
                            <div class="alert alert-success display-hide" *ngIf="status=='success'">
                                <button class="close" data-close="alert"></button>
                                <span>{{message}}</span>
                            </div>
                            

                            <div class="form-group" *ngIf="!view2factor">
                                <!--ie8, ie9 does not support html5 placeholder, so we just show field title for that-->
                                <label class="control-label visible-ie8 visible-ie9"><i class="fa fa-user"></i> E-mail</label>
                                <div class="input-icon">
                                    <!-- <i class="fa fa-user"></i> -->
                                    <input type="email" name="email" #email="ngModel" [(ngModel)]="user.email" class="form-control placeholder-no-fix" 
                                        title="Ingrese su Email" type="text" placeholder="Email" required /> 
                                </div>
                                <span style="padding-left: 20px; margin-left: 20px;" *ngIf="!email.valid && email.touched">
                                    El E-Mail es obligatorio
                                </span>
                            </div>
                            <div class="form-group" *ngIf="!view2factor">
                                <label class="control-label visible-ie8 visible-ie9"><i class="fa fa-lock"></i> Clave</label>
                                <div class="input-icon">
                                    
                                    <input  type="password" name="password" #password="ngModel" [(ngModel)]="user.clave" class="form-control placeholder-no-fix" 
                                        title="Ingrese su Password" autocomplete="off" placeholder="Password" required/> 
                                </div>
                                <span style="padding-left: 20px; margin-left: 20px;" *ngIf="!password.valid && password.touched">
                                    El Password es obligatorio
                                </span>
                            </div>
                            

                            <div class="form-group" *ngIf="view2factor">
                                <!--ie8, ie9 does not support html5 placeholder, so we just show field title for that-->
                                <label class="control-label visible-ie8 visible-ie9">Codigo de verificacion</label>
                                <div class="input-icon">
                                    <i class="fa fa-user"></i>
                                    <input type="factor" name="factor" #factor="ngModel" [(ngModel)]="user.factor" class="form-control placeholder-no-fix" type="text" autocomplete="off" placeholder="Verification code" required /> 
                                </div>
                                <span *ngIf="!factor.valid && factor.touched">
                                    El Codigo es obligatorio
                                </span>
                            </div>
                            <div class="form-actions" style="text-align: center;">
                                <button type="submit"  class=" button btn-success"> Login </button>
                            </div>
            
                            <!-- <div class="login-options">
                                <h4>Or login with</h4>
                                <ul class="social-icons">
                                    <li>
                                        <a class="facebook" data-original-title="facebook" href="javascript:;"> </a>
                                    </li>
                                    <li>
                                        <a class="twitter" data-original-title="Twitter" href="javascript:;"> </a>
                                    </li>
                                    <li>
                                        <a class="googleplus" data-original-title="Goole Plus" href="javascript:;"> </a>
                                    </li>
                                    <li>
                                        <a class="linkedin" data-original-title="Linkedin" href="javascript:;"> </a>
                                    </li>
                                </ul>
                            </div> -->
                            <div class="forgot">
                                <a href="javascript:;" id="forget-password" (click)="showFogetPassword = true; clearMessage();">Olvide mi Clave?</a>
                            </div>
                            <!-- <div class="create-account">
                                <p> Don't have an account yet ?&nbsp;
                                    <a href="javascript:;" id="register-btn"> Create an account </a>
                                </p>
                            </div> -->
                        </form>
                    
            
                    <!-- END LOGIN FORM -->
                    <!-- BEGIN FORGOT PASSWORD FORM -->
                    <form #fogetForm="ngForm" (ngSubmit)="onForget(fogetForm);" class="forget-form" *ngIf="showFogetPassword">
                        <h3>¿Has olvidado tu contraseña?</h3>
                        <p> ¡No te preocupes! Ingrese su dirección de correo electrónico y le enviaremos un enlace para restablecer su contraseña (válido por 4 horas). </p>
                        <div class="alert alert-danger display-hide" *ngIf="status=='error'">
                            <button class="close" data-close="alert"></button>
                            <span>{{message}}</span>
                        </div>
                        <div class="form-group">
                            <div class="input-icon">
                                <i class="fa fa-envelope"></i>
                                <input class="form-control placeholder-no-fix" type="text" autocomplete="off" placeholder="Email" name="email" 
                                    title="Ingrese su E-mail" #email="ngModel" [(ngModel)]="user.email" required/> 
                            </div>
                            <span *ngIf="!email.valid && email.touched">
                                El E-mail es obligatorio
                            </span>
                        </div>
                        <div class="form-actions" style="text-align: center;">
                            <button type="submit" class="button btn-success"> Enviar </button>
                        </div>
                        <div class="forgot">
                            <a href="javascript:;" id="forget-password" (click)="showFogetPassword = false; clearMessage();">Volver</a>
                        </div>
                    </form>
                    <!-- END FORGOT PASSWORD FORM -->
                    
                </div>
            </div>    
            
        </div>
    </div>
</div>

