import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { SiteService }  from 'src/app/services/site.service';
import { debounceTime, finalize } from 'rxjs/operators';


@Component({
  selector: 'app-skindos',
  templateUrl: './skindos.component.html',
  styleUrls: ['./skindos.component.css'],
  providers: [SiteService]
})


export class SkindosComponent implements OnInit {
  // variable de carga
  public id: number; 
  public ButtonView: string = 'news';

  //variables de personificacion
  public empresa: string;
  public colorButton:string;
  public colorCabecera:string;
  public colorBotonera:string;
  public colorSite:string;
  public colorPie:string;
  public titleSize:string;
  public titleFont:string;
  public titleColor:string;
  public buttonSize:string;
  public buttonFont:string;
  public buttonColor:string;

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _siteServices: SiteService,
  ) { }
 

buttonClick(boton:string){
    // segun el boton carga variable ButtonView //
    this.ButtonView = boton;
  }

  ngOnInit(): void {
    this._route.params.subscribe(
      params => {
          this.id = +params['id'];
      }
    );
    let body={id: this.id};
    this._siteServices.loadClientSites(body).subscribe(
      response => {
        if(response.status != 'error'){
          this.empresa = response[0].nombre;
          this.colorButton = '#'+response[0].text;
          this.colorCabecera = '#'+response[1].text;
          this.colorBotonera = '#'+response[2].text;
          this.colorSite = '#'+response[3].text;
          this.colorPie = '#'+response[4].text;
          this.titleColor = '#'+response[5].text;
          this.titleFont = response[6].text;
          this.titleSize = response[7].text;
          this.buttonColor = '#'+response[8].text;
          this.buttonFont = response[9].text;
          this.buttonSize = response[10].text;
          
          this._siteServices.setColorSite(this.colorSite); 
          
        }else{
          console.log('response.message');
        }
      },
      error => {
        console.log('error');
      } 
    )
  
  }

}
