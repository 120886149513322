import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { SiteService }  from 'src/app/services/site.service';
import { debounceTime, finalize } from 'rxjs/operators';

@Component({ 
  selector: 'app-load',
  templateUrl: './loadskinssites.component.html',
  providers: [SiteService]
})
export class loadskinssitesComponent implements OnInit {

  //var
  public id:number;

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _siteServices: SiteService,
  ) {

  }

  ngOnInit(){
    this._route.params.subscribe(
      params => {
          this.id = +params['id'];
      }
    );
    //console.log(this.id);
    // llamo a la api.
    // consulto a que site apunto
    //window.location.href = this.datapost[0].dns+'/sapi'+'/descarga?data='+this.download.name;
    let body={id: this.id};
    this._siteServices.loadClientSites(body).subscribe(
      response => {
        if(response.status != 'error'){
          //console.log(response);
          this._router.navigate(['/'+response[0].ruta, this.id])
        }else{
          console.log('response.message');
        }
      },
      error => {
        console.log('error');
      } 
    )


  }

}
