<!--Cabecera  -->
<div class="header">
  <!--logo -->
      
  
  
  
</div>
    

  
   

<!-- contenido -->
<router-outlet>

</router-outlet>
