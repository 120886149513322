
<div class=" col-12 content column " style="width: 100%;  padding-left: 15Px; padding-top: 20px; 
                display: flex; flex-direction: column; align-items: center;" > 
    <div  class="  news-card "  >
        <div >
            <div >
                
                    <div style="display: flex; flex-direction: column;" >
                        <form #loginForm="ngForm" (ngSubmit)="onSubmit(loginForm);" class="login-form" >

                        
                            <h1 class="form-title" style="text-align: center;" >{{title}}</h1>    
                            
                            <div class="alert alert-danger display-hide" *ngIf="status=='error'">
                                <button class="close" data-close="alert"></button>
                                <span>{{message}}</span>
                            </div>
                            <div class="alert alert-success display-hide" *ngIf="status=='success'">
                                <button class="close" data-close="alert"></button>
                                <span>{{message}}</span>
                            </div>
                            <div  >
                                <div *ngIf="!perfilCompleto" class="input-info" >
                                <!--ie8, ie9 does not support html5 placeholder, so we just show field title for that-->
                                    <label style="margin: 5px ; " ><i class="fa fa-user"></i>categoria Padel</label>
                                    <select  class="form-control"  title="categoria" required   name="categoria" #categoria="ngModel" [(ngModel)]="categoriaPadel">
                                    
                                        <option hidden value=""  selected>Seleccione categoria Obligatorio </option>
                                        <option value="8" >8</option>
                                        <option value="7" >7</option>
                                        <option value="6" >6</option>
                                        <option value="5" >5</option>
                                        <option value="4" >4</option>
                                        <option value="3" >3</option>
                                        <option value="2" >2</option>
                                        <option value="1" >1</option>
                                    </select> 
                                </div>

                                <div *ngIf="perfilCompleto" class="input-info" >
                                    <!--ie8, ie9 does not support html5 placeholder, so we just show field title for that-->
                                    <label style="margin: 5px ; " ><i class="fa fa-user"></i>  Categoria Padel: {{categoriaPadel}}</label>
                                    
                                </div>
                                
                            </div>
                            

                            <div *ngIf="!perfilCompleto" class="form-actions" style="text-align: center;">
                                <button type="submit"  class=" button btn-success"> Agregar Categoria </button>
                            </div>

                           
                            
                        </form>
                    
                </div>
            </div>    
            
        </div>
    </div>
</div>



