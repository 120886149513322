import { Component, OnInit, Input } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { SiteService }  from 'src/app/services/site.service';
import { UserService }  from 'src/app/services/user.service';
import { TorneosService }  from 'src/app/services/torneos.service';
import { formatDate } from '@angular/common';
import { DomSanitizer } from '@angular/platform-browser';
import { LocalService } from 'src/app/services/local.service';
import { DataService } from 'src/app/services/data.service';
import { exit } from 'process';



@Component({
  selector: 'app-habilitar-equipo-torneo',
  templateUrl: './habilitarEquipo.component.html',
  styleUrls: ['./habilitarEquipo.component.css'],
  providers: [SiteService, UserService, LocalService, TorneosService]
})
export class HabilitarEquipoComponent implements OnInit {
  @Input() varempresa: string;
  @Input() codTorneo: number;
  @Input() dataTorneo: any;
  @Input() catDeportes: any;

  loading: boolean;
  vartoken;
  identity;
  colorSite: string;
  itemText;
  permiteIncribirse: boolean = false;
  equipo1:string = '';
  anotado; unido;
  ver: string = '';
  unirseCodigo:string;
  //errors
  status = ''; 
  message = '';
  inscriptos;
  columnToSort = '';
  isMovil='N'; 
  noVer: boolean = false;

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _siteServices: SiteService,
    private _userService: UserService,
    private sanitizer: DomSanitizer,
    private _localService: LocalService,
    private _torneosService: TorneosService,
    private dataService: DataService
  ){ 
    
  }
  
  fechaformato(fecha:Date):string {
    return formatDate(fecha,'yyyy/MM/dd HH:mm','en-US');
  }
  tratarDate(fecha:Date):string {
    return formatDate(fecha,'yyyy/MM/dd HH:mm','en-US');
  }

  armarZonas(){
    let body = {'cod_torneo': this.dataTorneo.cod_torneo};
    this._torneosService.torneoRepartirEquiposZonas(body, this.vartoken).subscribe(
      response => {
        console.log(response);
        if(response.status != 'error'){
          this.dataTorneo.iniciado = 1;
        }else{
          this.status = response.status;
          this.message = response.message;
        }
      },
      error => {
        console.log(error);
      } 
    )
  }
  sort(column: string) {
    if (this.columnToSort === column) {
      this.inscriptos.reverse();
    } else {
      this.columnToSort = column;
      this.inscriptos.sort((a, b) => {
        if (a[column] < b[column]) return -1;
        if (a[column] > b[column]) return 1;
        return 0;
      });
    }
  }

  salir(){
    // notociaView data envio
    //console.log('ento a salir inscri torneo');
    this.dataService.setNoticiasNav('');
  }
  ngOnInit(): void {
    this.status = ''; 
    this.message = '';
    this.isMovil = localStorage.getItem('navi');
    if(this.isMovil == 'S') {
      this.noVer = true
    }
    //console.log(this.catDeportes);
    this.identity = this._siteServices.getIdentity();
    //console.log(this.identity);
    this.colorSite = this._siteServices.getColorSite();
    this.vartoken = this._userService.getToken();
    let body = {'cod_config_juego': this.dataTorneo.cod_config_juego, 'cod_torneo': this.dataTorneo.cod_torneo};
    let pasaCheck: boolean = true;
    this._torneosService.getItemsJuegoText(body).subscribe(
      response => {
        if(response.status != 'error'){
          
          this.itemText = response;
          //console.log(this.itemText);

          let body = {'cod_torneo': this.dataTorneo.cod_torneo};
          this._torneosService.torneoGetAnotados(body, this.vartoken).subscribe(
            response => {
              //console.log(response);
              if(response.status != 'error'){
                this.inscriptos = response
              }else{
                this.status = response.status;
                this.message = response.message;
              }
            },
            error => {
              console.log(error);
            } 
          )
          
          
          
          //permite o no la inscripcion dependiendo la categoria, m,f etc

          this.permiteIncribirse = false;
          this.message = '';
          //console.log(this.dataTorneo);
          //console.log(this.catDeportes);

          /*
          if (this.dataTorneo.sexo != 'C'){
            if (this.dataTorneo.sexo != this.catDeportes[0].sexo ){
              pasaCheck = false;
              this.permiteIncribirse = false;
              if (this.dataTorneo.sexo == 'F')
              {'femenino'
                this.message = 'El torneo es femenino';
              }else{
                this.message = 'El torneo es masculino';
              };
            }
          }
          if (pasaCheck){
            if (this.dataTorneo.categoria_fija != null){
              // es categoria fija
              let userCatDeporte: number = 0;
              for (let i = 0; i<this.catDeportes.length; i++){
                if (this.catDeportes[i].deporte == this.dataTorneo.deporte){
                  userCatDeporte = this.catDeportes[i].categoria
                }
              }
              if (userCatDeporte == 0){
                pasaCheck = false;
                this.permiteIncribirse = false;
                this.message = 'No tiene una categoria de '+this.dataTorneo.deporte+', entre al su perfil y complete su informacion para continuar ';
              }else{
                if (userCatDeporte != this.dataTorneo.categoria_fija){
                  pasaCheck = false;
                  this.permiteIncribirse = false;
                  this.message = 'Su Categoria de '+this.dataTorneo.deporte+' no corresponde con la categoria del torneo.';
                }else{
                  pasaCheck = true;
                  this.permiteIncribirse = true;
                  this.message='';
                }
              }
              
            }else{
              //es categoria suma
              pasaCheck = true;
                  this.permiteIncribirse = true;
                  this.message='';
            }
          }
          */
        }else{
          this.status = response.status;
          this.message = response.message;
          
        }
      },
      error => {
        console.log(error);
      } 
    )
  }

  habilito(index1: number) {
    // Aquí puedes hacer lo que quieras con el índice
    let body = {
      cod_torneo: this.inscriptos[index1].cod_torneo , 
      cod_usuario: this.inscriptos[index1].cod_usuario_responsable
    }
    this._torneosService.torneoSetHablilitarAnotados(body, this.vartoken).subscribe(
      response => {
        if(response.status != 'error'){
          this.anotado = response;
          if (this.inscriptos[index1].inscripto == 0) {
            this.inscriptos[index1].inscripto = 1
          }else{
            this.inscriptos[index1].inscripto = 0
          }

          let body = {'cod_config_juego': this.dataTorneo.cod_config_juego, 'cod_torneo': this.dataTorneo.cod_torneo};
          let pasaCheck: boolean = true;
          this._torneosService.getItemsJuegoText(body).subscribe(
            response => {
              if(response.status != 'error'){
                
                this.itemText = response;
                //console.log(this.itemText);
      
                let body = {'cod_torneo': this.dataTorneo.cod_torneo};
                this._torneosService.torneoGetAnotados(body, this.vartoken).subscribe(
                  response => {
                    //console.log(response);
                    if(response.status != 'error'){
                      this.inscriptos = response
                    }else{
                      this.status = response.status;
                      this.message = response.message;
                    }
                  },
                  error => {
                    console.log(error);
                  } 
                )
                //para rearmar contadores y tablas
                this.permiteIncribirse = false;
                this.message = '';
                
              }else{
                this.status = response.status;
                this.message = response.message;
                
              }
            },
            error => {
              console.log(error);
            } 
          )
      




        }else{
          this.status = response.status;
          this.message = response.message;
        }
      },
      error => {
        console.log(error);
      } 
    )
  }
  onSubmit(form){

  }
}

