<!-- <p>default works!</p> -->
<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <link rel="preconnect" href="https://fonts.googleapis.com">
    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
    <link href="https://fonts.googleapis.com/css2?family=Borel&family=Bree+Serif&family=Bungee+Shade&family=Cinzel:wght@400;500;700&family=Lilita+One&family=PT+Sans:ital,wght@1,400;1,700&family=Playfair+Display:wght@400;700&display=swap" rel="stylesheet">
    <!--
            font-family: 'Borel', cursive;
            font-family: 'Bree Serif', serif;
            font-family: 'Bungee Shade', cursive;
            font-family: 'Cinzel', serif;
            font-family: 'Lilita One', cursive;
            font-family: 'Playfair Display', serif;
            font-family: 'PT Sans', sans-serif;
    -->
    
    <title>ZoneDeporte</title>



</head>
<body>
   
    <header style="background-color: {{colorCabecera}};">
        <div class="header-left">
            <img src="..\..\..\assets\imagen\LogotipoClub.jpg" alt="Foto del club" >
             <!-- 100px x 70 px-->
        </div>
        <div class="header-center" *ngIf="empresa">
            <h1 style="font-family: {{titleFont}}; font-size: {{titleSize}}; color: {{titleColor}};">{{empresa}}</h1>
        </div>
        <div class="header-right">
            <a href="https://www.facebook.com/miguel.fernandez.ar" target="_blank"> <img src="..\..\..\assets\imagen\f.jpg" alt="Facebook"></a>
            <a href="#"><img src="..\..\..\assets\imagen\i.jpg" alt="Instagram"></a>
        </div>

    </header>
   
    
   


    <main>
        <div class="button-container"  style="background-color: {{colorBotonera}}; padding-top: 120px;">
            <button class="button button" style="background-color: {{colorButton}}; font-family: {{buttonFont}}; font-size: {{buttonSize}}; color: {{buttonColor}};"
            (click)="buttonClick('news')">Inicio</button>
            <button class="button button" style="background-color: {{colorButton}}; font-family: {{buttonFont}}; font-size: {{buttonSize}}; color: {{buttonColor}};"
            (click)="buttonClick('turnos')">Turnos</button>
            <button class="button button" style="background-color: {{colorButton}}; font-family: {{buttonFont}}; font-size: {{buttonSize}}; color: {{buttonColor}};"
            (click)="buttonClick('canchas')">Canchas</button>
            <button class="button button" style="background-color: {{colorButton}}; font-family: {{buttonFont}}; font-size: {{buttonSize}}; color: {{buttonColor}};"
            (click)="buttonClick('torneos')">Torneos</button>
            <button class="button button" style="background-color: {{colorButton}}; font-family: {{buttonFont}}; font-size: {{buttonSize}}; color: {{buttonColor}};"
            (click)="buttonClick('ligas')">Ligas</button>
            <button class="button button" style="background-color: {{colorButton}}; font-family: {{buttonFont}}; font-size: {{buttonSize}}; color: {{buttonColor}};"
            (click)="buttonClick('login')">Contactos</button>
            <!--  <button class="button button" style="background-color: {{colorButton}}; font-family: {{buttonFont}}; font-size: {{buttonSize}}; color: {{buttonColor}};">?</button>
            ... Repite para los demás botones -->
        </div>
        <div class="content" style="background-color: {{colorBotonera}};">
            
            <div class="none"  style="background-color: {{colorSite}};" *ngIf="ButtonView=='login'">
                <!--  LOGIN  -->
                <app-login [varempresa]="id"></app-login>
            </div>

            <div class="none"  style="background-color: {{colorSite}};" *ngIf="ButtonView=='turnos'">
                <!--  TURNOS  -->
                <app-turnos [varempresa]="id"></app-turnos>

            </div>

            <div class="none"  style="background-color: {{colorSite}};" *ngIf="ButtonView=='news'">
                <!--  NOTICIAS  -->
                <app-noticias [varempresa]="id" [verSolo]="'todo'"> </app-noticias>
            </div>
      
            <div class="none"  style="background-color: {{colorSite}};" *ngIf="ButtonView=='torneos'">
                <!--  TORNEOS  -->
                <app-noticias [varempresa]="id" [verSolo]="'torneos'"></app-noticias>
            </div>
           <!--
             <div class="content-section section-2">
                Contenido del Botón 2
            </div>
            
             PARA PONER EL PLUGING FDE FACE 3 formas distintas de hacerlo
            <div class="content-section section-3">
                Contenido del Botón 3
                <div class="fb-page" 
                    data-tabs="timeline"
                    data-href="https://www.facebook.com//profile.php?id=100042625488376"
                    data-width="380" 
                    data-hide-cover="false"
                    data-show-facepile="false"> 
                </div>


                
                <div class="fb-page" 
                    data-tabs="timeline,events"
                    data-href="https://www.facebook.com/miguel.fernandez.ar"
                    data-width="380" 
                    data-hide-cover="false"></div>

                <div class="fb-page" data-href="https://www.facebook.com/profile.php?id=100042625488376" data-tabs="timeline" data-width="" data-height="" data-small-header="false" data-adapt-container-width="true" data-hide-cover="false" data-show-facepile="false"></div>

            </div>
            -->
            <!-- ... Repite para los demás botones -->

        
        </div>
    </main>

    <footer  style="background-color: {{colorPie}};">
        <p>&copy; 2023 ZoneDeporte. Dirección: Calle Principal, Ciudad. Email: info@miclubdeportivo.com</p>
    </footer>
</body>
</html>

