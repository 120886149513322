import * as internal from "events";
import { MergeMapSubscriber } from "rxjs/internal/operators/mergeMap";


export class ClassTorneo{

    constructor(
        public cod_torneo: number,
        public cod_empresa: string,
        public torneo: string,
        public descripcion: string,
        public fecha_creacion: string,
        public fecha_limite_inscripcion: string,
        public fecha_inicio: string,
        public plaza_equipos: number,
        public activo: number,
        public cod_torneo_config: number,
        public cod_categoria: number,
        public cod_deporte: number,
        public cod_config_juego: number,
        public imagen1: string,
        public imagen2: string,
        public imagen3: string,
        public is_liga: number,
        public iniciado: number,
        public partidos: number,
        public llaveInicial: number
    ){

    }
}