import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { SiteService }  from 'src/app/services/site.service';
import { debounceTime, finalize } from 'rxjs/operators';
import { UserService } from 'src/app/services/user.service';
import { isIdentifier } from '@angular/compiler';
import { Subscription } from 'rxjs';
import { DataService }  from 'src/app/services/data.service';
//import { log } from 'console';


@Component({
  selector: 'app-default',
  templateUrl: './default.component.html',
  styleUrls: ['./default.component.css'],
  providers: [UserService, SiteService]
})
export class DefaultComponent implements OnInit {
  // variable de carga

  public identity;
  public token;
  public seter;
  public userimg;
  isMovil: string;
  catDeportes = [];
  forms = [];

  public id: number;
  public ButtonView: string = 'news';
  

  //variables de personificacion
  skinVar;  
  public empresa: string;
  public colorButton:string;
  public colorCabecera:string;
  public colorBotonera:string;
  public colorSite:string;
  public colorPie:string;
  public titleSize:string;
  public titleFont:string;
  public titleColor:string;
  public buttonSize:string;
  public buttonFont:string;
  public buttonColor:string;
  editSite: boolean;
  editSiteSelector:string;

  data





  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _siteServices: SiteService,
    private _useService: UserService,
    private dataService: DataService
  ) { 
    this.identity = this._useService.getIdentity();
    this.token = this._useService.getToken();
  }
 
  buttonClick(boton:string){
    this.ButtonView = boton;
    this.dataService.setNoticiasNav('');
  }

  editButtonClick(boton:string){
    this.editSiteSelector = boton;
  }

  buttonAdminClick(boton:string){
    //botones del admin
    if(boton == 'colorSite'){
      if (this.editSite==true){this.editSite=false;}
      else {
        this.editSite=true;
        
      };
    }
    
  }

  ngOnInit(): void {
    this._route.params.subscribe(
      params => {
          this.id = +params['id'];
      }
    );
    this.isMovil = localStorage.getItem('navi');
    let body={id: this.id};
    this._siteServices.loadClientSites(body).subscribe(
      response => {
        if(response.status != 'error'){
          this.skinVar = response;
          this.empresa = response[0].empresa;
          this.colorButton = response[0].text;
          this.colorCabecera = response[1].text;
          this.colorBotonera = response[2].text;
          this.colorSite = response[3].text;
          this.colorPie = response[4].text;
          this.titleColor = response[5].text;
          this.titleFont = response[6].text;
          this.titleSize = response[7].text;
          this.buttonColor = response[8].text;
          this.buttonFont = response[9].text;
          this.buttonSize = response[10].text;
          
          this._siteServices.setColorSite(this.colorSite); 
          
          if (this.identity){
            let body1={ Usuario: this.identity.cod_user, Empresa: this.id};
            this._useService.userEmpresa(body1).subscribe(
              response => { 
                //console.log(response);    
                if(response.status != 'error'){
                  let edad = this.CalculateAge(response[0].fecha_nacimiento);
                  for (let i=0; i<response.length; i++){
                    this.catDeportes[i] = {deporte : response[i].deporte, categoria : response[i].categoria, sexo : response[i].sexo, edad : edad};
                  }
                  // formularios a mostrar
                  this.forms[0] = { viewNoticias : Number(response[0].viewNoticias), 
                                    viewTurnos : Number(response[0].viewTurnos), 
                                    viewCanchas : Number(response[0].viewCanchas), 
                                    viewTorneos : Number(response[0].viewTorneos),
                                    viewLigas : Number(response[0].viewLigas),
                                    viewContacto : Number(response[0].viewContacto),
                                    viewEditSite : Number(response[0].viewEditSite)
                                  };
                  //verifico si es el admin del site actual
                  //console.log(this.forms);
                  if (response[0].tipo == 'admin'){
                    localStorage.setItem('seter', '1');
                  }else{
                    localStorage.setItem('seter', '0');
                  }
                  this.seter = localStorage.getItem('seter');
                }else{
                  console.log(response);
                }
              },
              error => {
                  console.log(error);
              }
            )
          }else{
            let body1={ Usuario: 0, Empresa: this.id};
            this._useService.userEmpresa(body1).subscribe(
              response => { 
                //console.log(response);    
                if(response.status != 'error'){
                  // formularios a mostrar
                  this.forms[0] = { viewNoticias : Number(response[0].viewNoticias), 
                                    viewTurnos : Number(response[0].viewTurnos), 
                                    viewCanchas : Number(response[0].viewCanchas), 
                                    viewTorneos : Number(response[0].viewTorneos),
                                    viewLigas : Number(response[0].viewLigas),
                                    viewContacto : Number(response[0].viewContacto),
                                    viewEditSite : Number(response[0].viewEditSite)
                                  };
                  //verifico si es el admin del site actual
                  //console.log(this.forms);
                }else{
                  console.log(response);
                }
              },
              error => {
                  console.log(error);
              }
            )
          }; 

        }else{
          console.log('response.message');
        }
      },
      error => {
        console.log('error');
      } 
    )
    
    this.userimg = localStorage.getItem('userimg');
    // categorias
    
  }
  CalculateAge(fechaNacimiento:Date): number {
    if (fechaNacimiento) {
        let timeDiff = Math.abs(Date.now() - <any>fechaNacimiento);
        return Math.ceil((timeDiff / (1000 * 3600 * 24)) / 365);
    } else {
        return 0;
    }
}
  logout(){
            localStorage.removeItem('identity');
            localStorage.removeItem('token');
            localStorage.removeItem('seter');
            localStorage.removeItem('userimg');

            
            this.userimg=false;
            this.seter=0;
            this.identity = null;
            this.token = null;
            //redirecciono
            this._router.navigate(['loadclientsite/'+this.id]);
        
    
}

  onSubmit(form){
    
    if(form.valid){
      //console.log(form);
      // guardo los cambios
      let body={
        'cod_empresa': this.id,
        'colorButton': this.colorButton,
        'colorCabecera': this.colorCabecera,
        'colorBotonera': this.colorBotonera,
        'colorSite': this.colorSite,
        'colorPie': this.colorPie,
        'titleColor': this.titleColor,
        'titleFont': this.titleFont,
        'titleSize': this.titleSize,
        'buttonColor': this.buttonColor,
        'buttonFont': this.buttonFont,
        'buttonSize': this.buttonSize
      };
      this._siteServices.updateskin(body,this.token).subscribe(
        response => {
          if(response.status != 'error'){
            console.log('ok');
            this._router.navigate(['loadclientsite/'+this.id]);
          }else{
            console.log(response);
          }
        },
        error => {
          console.log('error');
        } 
      )    
    }
  }

}
