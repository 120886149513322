import { Component, OnInit, Input } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { SiteService }  from 'src/app/services/site.service';
import { NoticiasComponent } from 'src/app/component/noticias/noticias.component';

@Component({
  selector: 'app-noticia',
  templateUrl: './noticia.component.html',
  styleUrls: ['./noticia.component.css'],
  providers: [SiteService]
}) 
export class NoticiaComponent implements OnInit {
  @Input() varnoticia: any;
  @Input() varempresa:string;
  //public varempresa:string = '1';
  
  public colorSite: string;
  public noticias;
  public senalnoticia: boolean = false;
  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _siteServices: SiteService,) { }

  ngOnInit(): void {
    this.colorSite = this._siteServices.getColorSite();

    let body={Empresa: this.varempresa}; 
    
    
    
    /*
    console.log(this.varempresa)
    console.log(this.varnoticia)
    console.log("paso")
    
    this._siteServices.noticias(body).subscribe(
      response => {
        if(response.status != 'error'){
          
          this.noticias = response;

          console.log(this.noticias);

          
        }else{
          console.log('response.message');
        }
      },
      error => {
        console.log(error);
      } 
    )
    */ 
    
  }

  

}
