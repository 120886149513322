import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { SiteService } from 'src/app/services/site.service';
import { TorneosService } from 'src/app/services/torneos.service';
import { Router, ActivatedRoute, Params } from '@angular/router';

import { exit } from 'process';

@Component({
  selector: 'widgedTablaPosGrupoPadel',
  templateUrl: './widgedtablaPosGrupoPadel.component.html',
  styleUrls: ['./widgedtablaPosGrupoPadel.component.css'],
  providers: [SiteService, TorneosService]
}) 

export class widgedTablaPosGrupoPadelComponent implements OnInit {
  @Input() codTorneo: number;
  @Input() zona: number;
  public movil: boolean;
  public tabla;
  public pasan;
  public pasamejor;
  
  
  constructor( private _siteServices: SiteService,
              private _torneosService: TorneosService,
              private _route: ActivatedRoute,
              private _router: Router
  )  { }

  ngOnChanges(){
    if (localStorage.getItem('navi') == 'N'){
      this.movil = false;
    } else {
      this.movil = true;
    }
    
    if (this.codTorneo){
      if (this.zona){
        // llamo al service
        this.recargar();
      }
    }
  }

  
  ngOnInit(): void {
    if (localStorage.getItem('navi') == 'N'){
      this.movil = false;
    } else {
      this.movil = true;
    }
    
    if (this.codTorneo){
      if (this.zona){
        // llamo al service
        this.recargar();
      }
    }
  }
  recargar(){
    let body = {'cod_torneo':this.codTorneo, 'zona':this.zona};
    this._torneosService.torneoTablaPuntaje(body,'').subscribe(
      response => {
        if(response.status != 'error'){
          this.tabla = response;
          this.pasan =this.tabla[0].pasan;
          this.pasamejor =this.tabla[0].pasamejor;
          //console.log(this.tabla);
          
        }else{
          console.log(response.message);
        }
      },
      error => {
        console.log(error);
      } 
    )
    
  }
  verificar(){
    /*if (this.finalizado == false){
      setTimeout(() => {
          this.recargar()
        }, 2000
      );
    }*/
  }

}
