<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <link rel="preconnect" href="https://fonts.googleapis.com">
    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
    <link href="https://fonts.googleapis.com/css2?family=Borel&family=Bree+Serif&family=Bungee+Shade&family=Cinzel:wght@400;500;700&family=Lilita+One&family=PT+Sans:ital,wght@1,400;1,700&family=Playfair+Display:wght@400;700&display=swap" rel="stylesheet">
    <!--
            font-family: 'Borel', cursive;
            font-family: 'Bree Serif', serif;
            font-family: 'Bungee Shade', cursive;
            font-family: 'Cinzel', serif;
            font-family: 'Lilita One', cursive;
            font-family: 'Playfair Display', serif;
            font-family: 'PT Sans', sans-serif;
    -->
    
    <title>ZoneDeporte</title>
</head>
<body>
    <div style="text-align: center; vertical-align: middle; height: 100%; background-color: dimgray; width: 100%; min-width: 100%; min-height: 100vh;">
        
        <div style="min-width: 100%; min-height: 30vh;">
            
        </div>
        <div style="min-width: 50%; min-height: 30vh;">
            <img  src="../../../assets/imagen/zoneDeporte/logo/logoZDlargo.png" height="50%" width="50%" alt="ZoneDeporte" *ngIf="movil==false">
            <img  src="../../../assets/imagen/zoneDeporte/logo/logoZDlargo.png" height="80%" width="80%" alt="ZoneDeporte" *ngIf="movil==true">
        </div>
        <div style="min-width: 100%; min-height: 40vh;">
            <h1 style="font-family: 'Cinzel', serif; font-size:xx-large; color:rgb(44, 49, 53)">Nos Vemos Pronto...</h1>
        </div>

</div>
</body>