import { Component, OnInit, Input } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { SiteService }  from 'src/app/services/site.service';
import { UserService }  from 'src/app/services/user.service';
import { TorneosService }  from 'src/app/services/torneos.service';
import { formatDate } from '@angular/common';
import { DomSanitizer } from '@angular/platform-browser';
import { LocalService } from 'src/app/services/local.service';
import { exit } from 'process';
import { Subscription } from 'rxjs';
import { DataService }  from 'src/app/services/data.service';




@Component({
  selector: 'app-inscripcion-torneos',
  templateUrl: './inscripcionTorneos.component.html',
  styleUrls: ['./inscripcionTorneos.component.css'],
  providers: [SiteService, UserService, LocalService, TorneosService]
})
export class InscripcionTorneosComponent implements OnInit {
  @Input() varempresa: string;
  @Input() codTorneo: number;
  @Input() dataTorneo: any;
  @Input() catDeportes: any;

  loading: boolean;
  vartoken;
  identity;
  colorSite: string;
  itemText;
  permiteIncribirse: boolean = false;
  equipo1:string = '';
  anotado; unido;
  ver: string = '';
  unirseCodigo:string;
  //errors
  status = ''; 
  message = '';
  data
  // dataServices$: Subscription;
  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _siteServices: SiteService,
    private _userService: UserService,
    private sanitizer: DomSanitizer,
    private _localService: LocalService,
    private _torneosService: TorneosService,
    private dataService: DataService
  ){ 
    
  }
  salir(){
    // notociaView data envio
    //console.log('ento a salir inscri torneo');
    this.dataService.setNoticiasNav('');
  }

  fechaformato(fecha:Date):string {
    return formatDate(fecha,'yyyy/MM/dd HH:mm','en-US');
  }

  tratarDate(fecha:Date):string {
    return formatDate(fecha,'yyyy/MM/dd HH:mm','en-US');
  }

  ngOnInit(): void {
    this.status = ''; 
    this.message = '';
    //console.log(this.catDeportes);
    this.identity = this._siteServices.getIdentity();
    //console.log(this.identity);
    this.colorSite = this._siteServices.getColorSite();
    this.vartoken = this._userService.getToken();
    let body = {'cod_config_juego': this.dataTorneo.cod_config_juego, 'cod_torneo': this.dataTorneo.cod_torneo};
    let pasaCheck: boolean = true;
    this._torneosService.getItemsJuegoText(body).subscribe(
      response => {
        if(response.status != 'error'){
          
          this.itemText = response;
          //console.log(this.itemText);

          //permite o no la inscripcion dependiendo la categoria, m,f etc

          this.permiteIncribirse = false;
          this.message = '';
          //console.log(this.dataTorneo);
          //console.log(this.catDeportes);
          if (this.dataTorneo.sexo != 'C'){
            if (this.dataTorneo.sexo != this.catDeportes[0].sexo ){
              pasaCheck = false;
              this.permiteIncribirse = false;
              if (this.dataTorneo.sexo == 'F')
              {'femenino'
                this.message = 'El torneo es femenino';
              }else{
                this.message = 'El torneo es masculino';
              };
            }
          }
          if (pasaCheck){
            if (this.dataTorneo.categoria_fija != null){
              // es categoria fija
              let userCatDeporte: number = 0;
              for (let i = 0; i<this.catDeportes.length; i++){
                if (this.catDeportes[i].deporte == this.dataTorneo.deporte){
                  userCatDeporte = this.catDeportes[i].categoria
                }
              }
              if (userCatDeporte == 0){
                pasaCheck = false;
                this.permiteIncribirse = false;
                this.message = 'No tiene una categoria de '+this.dataTorneo.deporte+', entre al su perfil y complete su informacion para continuar ';
              }else{
                if (userCatDeporte != this.dataTorneo.categoria_fija){
                  pasaCheck = false;
                  this.permiteIncribirse = false;
                  this.message = 'Su Categoria de '+this.dataTorneo.deporte+' no corresponde con la categoria del torneo.';
                }else{
                  pasaCheck = true;
                  this.permiteIncribirse = true;
                  this.message='';
                }
              }
              
            }else{
              //es categoria suma
              pasaCheck = true;
                  this.permiteIncribirse = true;
                  this.message='';
            }
          }
          
        }else{
          this.status = response.status;
          this.message = response.message;
          
        }
      },
      error => {
        console.log(error);
      } 
    )
  }
  unirseAGrupo(){

    //unirse a un grupo ya inscripto
    if (this.unirseCodigo==''){
      this.message = 'debe ingresar un codigo';
    }else{
      let codigoArr = this.unirseCodigo.split('-');
      if (codigoArr.length == 4){
        //codigo con formato valido
        if (codigoArr[0] == this.dataTorneo.cod_torneo){
          //codigo pertenece al torneo seleccionado
          let body = {
            cod_torneo: codigoArr[0],
            cod_usuario: codigoArr[2],
            nombre_Equipo: 'nada',
            cod_usuario_jugador: this.identity.cod_user
          }
          this._torneosService.torneoAddEquipo(body, this.vartoken).subscribe(
            response => {
              if(response.status != 'error'){
                this.unido = response;
                this.anotado = false;
                this.ver = 'resultado';
                
                
              }else{
                this.status = response.status;
                this.message = response.message;
              }
            },
            error => {
              console.log(error);
            } 
          )  
          



        }else{
          this.message = 'El código ingresado no pertenece a este torneo';
        }
      }else{
        this.message = 'debe ingresar un codigo válido, verifique lo ingresado';
      }
    }
  }
  guardarGrupo(){
    let body = {
      cod_torneo: this.dataTorneo.cod_torneo,
      cod_usuario: this.identity.cod_user,
      nombre_Equipo: this.equipo1
    }
    this._torneosService.torneoAddEquipo(body, this.vartoken).subscribe(
      response => {
        if(response.status != 'error'){
          this.anotado = response;
          console.log(this.anotado);
        }else{
          this.status = response.status;
          this.message = response.message;
        }
      },
      error => {
        console.log(error);
      } 
    )  
  }


  onSubmit(form){

  }
}

