



export var GLOBAL = {
    //developer oracle
    //url: 'https://api.transfercba.com/zone/',
    url: 'https://apidos.zonedeporte.com/zone/',
    urlSite: 'https://zonedeporte.com/'
};


