import * as internal from "events";
import { MergeMapSubscriber } from "rxjs/internal/operators/mergeMap";


export class ColorsSite{

    constructor(
        public colorsite: string
    ){

    }
}

export class Turno{

    constructor(
        public hora: string,
        public estado: string,
        public orden: number,
        public cod_cliente: number,
        public activo: number, 
        public cod_usuario_alta: number, 
        public fecha_alta: Date, 
        public cod_lugar_alta: number, 
        public fecha_cancelado: Date, 
        public cod_usuario_cancelo: number, 
        public motivo_cancelacion: string, 
        public cod_motivo_cancelacion: number,
        public fecha_confirmado: Date, 
        public cod_usuario_confirmo: number,
        public cod_turno: number,
        public cod_turno_fijo: number
    ){

    }
}

export class TurnosCanchas{

    constructor(
        public cancha: number,
        public empresa: number,
        public turnos: [Turno],
        public cod_empresa: number,
        public cod_cancha: number,
        public cod_tipo_cancha: number 
    ){

    }
}

export class TurnosCanchasFecha{

    constructor(
        public fecha: Date,
        public fechaTexto: string,
        public canchas: [TurnosCanchas]
    ){

    }
}

export class TurnosGeneral{

    constructor(
        public fechas: [TurnosCanchasFecha]
    ){

    }
}