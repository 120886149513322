
<div class=" col-12 content column " style="width: 100%;  padding-left: 15Px; padding-top: 20px; 
                display: flex; flex-direction: column; align-items: center;" > 
    <div  class="  news-card "  >
        <div >
            <div >
                
                    <div *ngIf="!registrado;" style="display: flex; flex-direction: column;" >
                        <form #loginForm="ngForm" (ngSubmit)="onSubmit(loginForm);" class="login-form" >

                        
                            <h1 class="form-title" style="text-align: center;" >Registro</h1>    
                            <div class="alert alert-info display-hide" *ngIf="view2factor&&view2factorMethod=='mail'">
                                <button class="close" data-close="alert"></button>
                                <span>Se envio un codigo al e-mail {{user.email}} </span>
                            </div>
                            <div class="alert alert-info display-hide" *ngIf="view2factor&&view2factorMethod=='sms'">
                                <button class="close" data-close="alert"></button>
            <!--                    <span>A verification code was sent via SMS to the phone {{view2factorphone}} </span> -->
                                <span>Se envio un codigo via SMS al telefono ...{{this.ult4phone}} </span>
                            </div>
                            <div class="alert alert-danger display-hide" *ngIf="status=='error'">
                                <button class="close" data-close="alert"></button>
                                <span>{{message}}</span>
                            </div>
                            <div class="alert alert-danger display-hide" *ngIf="status=='mail'&&message=='codigo error'">
                                <button class="close" data-close="alert"></button>
                                <span>{{message}}</span>
                            </div>
                            <div class="alert alert-success display-hide" *ngIf="status=='success'">
                                <button class="close" data-close="alert"></button>
                                <span>{{message}}</span>
                            </div>
                            <div  >
                                <div class="input-info" >
                                <!--ie8, ie9 does not support html5 placeholder, so we just show field title for that-->
                                    <label style="margin: 5px ; " ><i class="fa fa-user"></i>E-Mail</label>
                                    <input type="email" name="email" #email="ngModel" [(ngModel)]="user.email" class="form-control placeholder-no-fix" type="text" placeholder="Email" required /> 
                                </div>
                                <span *ngIf="!email.valid && email.touched">
                                    El E-Mail es obligatorio
                                </span>
                            </div>
                            <div  >
                                <div class="input-info" >
                                <label style="margin: 5px ; " ><i class="fa fa-lock"></i>Clave</label>
                                <input  type="password" name="password" #password="ngModel" [(ngModel)]="user.clave" class="form-control placeholder-no-fix" autocomplete="off" placeholder="Password" required/> 
                                </div>

                                <span *ngIf="!password.valid && password.touched">
                                    La Clave es obligatoria
                                </span>
                            </div>
                            

                            <div class="input-info" >
                                <label style="margin: 5px ; ">Nombre </label>
                                <input type="text" style="width: 100%;" required title="Nombre" minlength="3" maxlength="45" placeholder="Nombre - Obligatorio "
                                    name="nombre" #nombre="ngModel" [(ngModel)]="user.nombre"  >
                            </div>
        
                            <div class="input-info" >
                                <label style="margin: 5px ; ">Apellido </label>
                                <input type="text" style="width: 100%;" required title="Apellido" minlength="3" maxlength="45" placeholder="Apellido - Obligatorio "
                                    name="apellido" #apellido="ngModel" [(ngModel)]="user.apellido"  >
                            </div>

                            <div class="input-info">
                                <label style="margin: 5px ;"> Fecha Nacimiento </label> 
                                <input class="form-control" style="width: max-content;"  type="date"  required name="fechaNacimiento" #fechaNacimiento="ngModel" [(ngModel)]="user.fechaNacimiento" >
                                <!-- (ngModelChange)="tratarDate('limite')"> -->
                            </div>
    
                            <div class="input-info">
                                <label style="margin: 5px ;">  Sexo </label>
                                <select class="form-control"  title="Sexo" required   name="sexo" #sexo="ngModel" 
                                    [(ngModel)]="user.sexo" >
                                    
                                    <option hidden value=""  selected>Seleccione Sexo - Obligatorio </option>
                                    <option value="'M'" >Masculino</option>
                                    <option value="'F'" >Femenino</option>
                                </select>
                            </div>    

                            <div class="input-info" >
                                <label style="margin: 5px ; ">Nro Documento </label>
                                <input type="number" style="width: max-content;" required title="Nro Documento" min="1000000" max="99999999" placeholder="Nro Documento - Obligatorio "
                                    name="dni" #dni="ngModel" [(ngModel)]="user.dni"  >
                            </div>

                            <div class="form-actions" style="text-align: center;">
                                <button type="submit"  class=" button btn-success"> Registrarse </button>
                            </div>

                            
                            
                        </form>
                    
                    </div>
                    <div *ngIf="registrado;"
                        class="alert alert-info display-hide" >
                                <button class="close" data-close="alert"></button>
                                <span>Se Registro correctamente, ya puede loguearse al sistema</span>
                    </div>
            </div>    
            
        </div>
    </div>
</div>



