import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { GLOBAL } from './global';

@Injectable()
export class UserService{

    public token;
    public identity;


    public url:string;

    constructor(
        public _http: HttpClient

    ){
        this.url = GLOBAL.url;

    }
    setColorSite(color:string){
        localStorage.setItem('colorSite', color);
    }
    getColorSite(){
        return localStorage.getItem('colorSite');
    }

    signup(user, getToken = null): Observable<any>{
        if( getToken != null){
            user.gettocken = getToken;
        }
        let json = JSON.stringify(user);
        let params = 'json='+json;
        let headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
        return this._http.post(this.url+'login',params, {headers: headers});
    }

    registerUser(user): Observable<any>{
        
        let json = JSON.stringify(user);
        let params = 'json='+json;
        let headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
        return this._http.post(this.url+'register/user',params, {headers: headers});
    }
    userEmpresa(body): Observable<any>{
        let json = JSON.stringify(body);
        let params = 'json='+json;
        let headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
        return this._http.post(this.url+'login/userempresa',params, {headers: headers});
    }

    getIdentity(){
        let identity = JSON.parse(localStorage.getItem('identity'));
        if(identity != 'undefined'){
            this.identity = identity;
        }else{
            this.identity = null;
        }
        return this.identity;
    }

    getToken(){
        let token = localStorage.getItem('token');
        if(token != 'undefined'){
            this.token = token;
        }else{
            this.token = null;
        }
        return this.token;
    }
    
    perfil(body, token): Observable<any>{
        let json = JSON.stringify(body);
        let params = 'json='+json;
        let headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
                                        .set('Authorization', token);
        return this._http.post(this.url+'user/Perfil',params, {headers: headers});
    }

    setPerfil(body, token): Observable<any>{
        let json = JSON.stringify(body);
        let params = 'json='+json;
        let headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
                                        .set('Authorization', token);
        return this._http.post(this.url+'user/setPerfil',params, {headers: headers});
    }




}