import { Component, OnInit, Input } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { SiteService }  from 'src/app/services/site.service';
import { UserService }  from 'src/app/services/user.service';
import { TorneosService }  from 'src/app/services/torneos.service';
import { formatDate } from '@angular/common';
import { DomSanitizer } from '@angular/platform-browser';
import { LocalService } from 'src/app/services/local.service';
import { DataService } from 'src/app/services/data.service';
import { exit } from 'process';



@Component({
  selector: 'app-habilitar-zonas-torneo',
  templateUrl: './habilitarZonas.component.html',
  styleUrls: ['./habilitarZonas.component.css'],
  providers: [SiteService, UserService, LocalService, TorneosService]
})
export class HabilitarZonasComponent implements OnInit {
  @Input() varempresa: string;
  @Input() codTorneo: number;
  @Input() dataTorneo: any;
  @Input() catDeportes: any;

  loading: boolean;
  vartoken;
  identity;
  colorSite: string;
  ver: string = '';
  zonas;
  tablaZona;
  general = [];
  inscriptos;
  
  //errors
  status = ''; 
  message = '';
  columnToSort = '';
  isMovil='N'; 
  noVer: boolean = false;

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _siteServices: SiteService,
    private _userService: UserService,
    private sanitizer: DomSanitizer,
    private _localService: LocalService,
    private _torneosService: TorneosService,
    private dataService: DataService
  ){ 
    
  }
  
  fechaformato(fecha:Date):string {
    return formatDate(fecha,'yyyy/MM/dd HH:mm','en-US');
  }
  tratarDate(fecha:Date):string {
    return formatDate(fecha,'yyyy/MM/dd HH:mm','en-US');
  }

  cargar(){
    this.general = [];
    let body = {'cod_torneo': this.dataTorneo.cod_torneo};
    //console.log(body);
    this._torneosService.torneoGetZonas(body, this.vartoken).subscribe(
      response => {
        //console.log(response);
        if(response.status != 'error'){
          this.zonas = response;
          let prepare; 
          for (let i=0; i<this.zonas.length; i++){
            //console.log(this.zonas[i].zona)
            prepare = {zona:this.zonas[i].zona, tabla:[]};
            //console.log(prepare);
            this.general.push(prepare);

            let body = {'cod_torneo': this.dataTorneo.cod_torneo, 'zona': this.zonas[i].zona};
            this._torneosService.torneoTablaPuntaje(body, this.vartoken).subscribe(
              response => {
                //console.log(response);
                if(response.status != 'error'){
                  this.tablaZona = response;
                  for (let x=0; x<this.tablaZona.length; x++){
                    prepare = { jug1: this.tablaZona[x].apellJug1+', '+this.tablaZona[x].nombJug1,
                                jug2: this.tablaZona[x].apellJug2+', '+this.tablaZona[x].nombJug2,
                                equipo: this.tablaZona[x].numero_equipo,
                                zona: this.tablaZona[x].zona,
                                zonaNueva: this.tablaZona[x].zona
                    };
                    this.general[i].tabla.push(prepare);
                  }
                }else{
                  this.status = response.status;
                  this.message = response.message;
                }
              },
              error => {
                console.log(error);
              } 
            )
            
          }
        }else{
          this.status = response.status;
          this.message = response.message;
        }
      },
      error => {
        console.log(error);
      } 
    )
    //console.log(this.general);
  }

  guardarZonas(){
    //armo los partidos de las zonas
    let body = {'cod_torneo': this.dataTorneo.cod_torneo};
    this._torneosService.torneoArmarPartidosZonas(body, this.vartoken).subscribe(
      response => {
        //console.log(response);
        if(response.status != 'error'){
          this.salir();

        }else{
          this.status = response.status;
          this.message = response.message;
        }
      },
      error => {
        console.log(error);
      } 
    )
  }

  cambiarZona(equipo:number, zonaActual: string, nuevaZona:string){
    //console.log(equipo);
    //console.log(zonaActual);
    //console.log(nuevaZona);
    
    let body = {'cod_torneo': this.dataTorneo.cod_torneo, 
                'equipo': equipo, 
                'zonaActual': zonaActual,
                'zonaNueva': nuevaZona};
    this._torneosService.torneoCambiarEquipoZona(body, this.vartoken).subscribe(
      response => {
        //console.log(response);
        if(response.status != 'error'){
          this.cargar();
        }else{
          this.status = response.status;
          this.message = response.message;
        }
      },
      error => {
        console.log(error);
      } 
    )
    
  }
  sort(column: string) {
    if (this.columnToSort === column) {
      this.inscriptos.reverse();
    } else {
      this.columnToSort = column;
      this.inscriptos.sort((a, b) => {
        if (a[column] < b[column]) return -1;
        if (a[column] > b[column]) return 1;
        return 0;
      });
    }
  }

  salir(){
    this.dataService.setNoticiasNav('');
  }

  ngOnInit(): void {
    this.status = ''; 
    this.message = '';
    this.isMovil = localStorage.getItem('navi');
    if(this.isMovil == 'S') {
      this.noVer = true
    }
    //console.log(this.catDeportes);
    this.identity = this._siteServices.getIdentity();
    //console.log(this.identity);
    this.colorSite = this._siteServices.getColorSite();
    this.vartoken = this._userService.getToken();
    this.cargar();
    
    
  }

  
}

