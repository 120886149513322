import { Component,  OnInit } from '@angular/core';
import { isNull } from 'util';
import { Router, ActivatedRoute, Params } from '@angular/router';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent implements OnInit {

  public navi: boolean = true;
  public domine: string = '';
  

  title = 'zonedeporte';




  navigator(){
    if( navigator.userAgent.match(/Android/i)
    || navigator.userAgent.match(/webOS/i)
    || navigator.userAgent.match(/iPhone/i)
    || navigator.userAgent.match(/iPad/i)
    || navigator.userAgent.match(/iPod/i)
    || navigator.userAgent.match(/BlackBerry/i)
    || navigator.userAgent.match(/Windows Phone/i)
    ){
      return true;
      //return false;
    }else {
      return false;
    }
  }
  ngOnInit(){
    this.navi = this.navigator();
    if (this.navi){
      localStorage.setItem('navi', 'S');
    } else {
      localStorage.setItem('navi', 'N');
    }

  }
}