
<div class="container " style="width: fit-content;">
    <div class="row align-items-start">
        
            <div class="col" style="background-color: white;">
                {{ data['equipo'] }}
            </div>
        
    </div>
    <div class="row align-items-start">    
        <div class="card-group">
            <div class="card">
                <img src="assets/imagen/perfil/cabecera/defaultperfil.png" class="card-img-top" style="border-radius: 50%" alt="perfil">
                <div class="card-body">
                    <h5 class="card-title">{{ user1['nombre'] }}</h5>
                </div>
            </div>
            <div class="card">
                <img src="assets/imagen/perfil/cabecera/defaultperfil.png" class="card-img-top" style="border-radius: 50%" alt="perfil">
                <div class="card-body">
                    <h5 class="card-title">{{ user2['nombre'] }}</h5>
                </div>
            </div>
        </div>
    </div>
</div>
