<!-- <p>default works!</p> -->
<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <link rel="preconnect" href="https://fonts.googleapis.com">
    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
    <link href="https://fonts.googleapis.com/css2?family=Borel&family=Bree+Serif&family=Bungee+Shade&family=Cinzel:wght@400;500;700&family=Lilita+One&family=PT+Sans:ital,wght@1,400;1,700&family=Playfair+Display:wght@400;700&display=swap" rel="stylesheet">
    
    <link href="https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css" rel="stylesheet">
    <!--
            font-family: 'Borel', cursive;
            font-family: 'Bree Serif', serif;
            font-family: 'Bungee Shade', cursive;
            font-family: 'Cinzel', serif;
            font-family: 'Lilita One', cursive;
            font-family: 'Playfair Display', serif;
            font-family: 'PT Sans', sans-serif;
    -->
    
    <title>ZoneDeporte</title>
</head>
<body>
    
  <header style="background-color: {{colorCabecera}};">
    <div class="header-left">
        <img src="..\..\..\assets\imagen\empresas\{{id}}\logo\logo.png" alt="Logo" >
          <!-- 1000px x 70 px-->
    </div>

    <div class="header-center" *ngIf="empresa">
      <h1 style="font-family: {{titleFont}}; font-size: {{titleSize}}; color: {{titleColor}};">{{empresa}}</h1>
    </div>
    <div class="header-right ">
          <a href="https://www.facebook.com" target="_blank"> <img style="border-radius: 15%;" src="..\..\..\assets\imagen\f.jpg" alt="Facebook"></a>
          <a href="#"><img style="border-radius: 15%;" src="..\..\..\assets\imagen\i.jpg" alt="Instagram"></a>
          
    </div>


    <div class="header-login  " >
      <img style="border-radius: 50%;" src="..\..\..\assets\imagen\perfil\cabecera\{{identity.cod_user}}\{{userimg}}"  alt="Perfil" *ngIf="userimg!='null'&&identity">
      <img style="border-radius: 50%;" src="..\..\..\assets\imagen\perfil\cabecera\defaultperfil.jpg"   alt="Perfil"  *ngIf="this.userimg=='null'">



      <!--<nav class="navbar navbar-expand-lg navbar-dark " >
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarsExample05" aria-expanded="true" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarsExample05">-->
      <nav class="navbar  navbar-dark " >
        <!-- no Identificado  -->
        <ul class="navbar-nav mr-auto pull-right" style="color: black;" *ngIf="!identity">
          <li class="nav-item">
            <a class="nav-link" style="cursor: pointer; margin-right: 10px; color: {{titleColor}};"   (click)="buttonClick('login')">Login</a>
          </li>
        </ul>
        <ul class="navbar-nav mr-auto" *ngIf="!identity">
          <li class="nav-item">
            <a class="nav-link" style="color: {{titleColor}};"    (click)="buttonClick('register')" >Registrarse</a>
          </li>
        </ul>
        <!-- Identificado  -->
        <!--<ul class="navbar-nav mr-auto pull-right" *ngIf="identity">  era el original agranda el encabezado mal
          
        style="background-color: {{colorCabecera}};"--> 
        <ul class="nav mr-auto pull-right" *ngIf="identity">
        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle active" style="color: {{titleColor}}; margin: 0%;" href="" id="dropdown05" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> 
              <strong > {{identity.nombre + ' '+ identity.apellido}} </strong><span class="fa fa-cog"> </span></a>
          <div class="dropdown-menu" style=" background-color: aquamarine;"  aria-labelledby="dropdown05">
              <a class="dropdown-item" style="margin: 0%;" (click)="buttonClick('perfil')"><span class="fa fa-user"></span> Su Perfil</a>
              <a class="dropdown-item disabled" style="margin: 0%;"[routerLink]="['/emailvalidate','changepaspro']"><span class="fa fa-refresh"></span> Reset Clave</a>
              <a class="dropdown-item disabled" style="margin: 0%;" [routerLink]="['/uservaliphone', + identity.sub]"><span class="fa fa-phone"></span> Verificacion Telefono</a>
              <!--
              <a class="dropdown-item" style="cursor: pointer;" (click)="lock()"><span class="fa fa-lock"></span> Lock screen</a>
              -->
              <a class="dropdown-item" style="margin: 0%;" (click)="logout()"> <i class="fa fa-arrow-right"></i> Salir </a>
            
          </div>
        </li>
        </ul>
      </nav>
    </div>
  </header>





  <!-- Esto es poner el menu de usuario horizontal en una sola linea abajo de encabezado -->
  <header class="header-login1 "  style="padding: 0%; background-color: {{colorCabecera}};">
    
    <div  style="width: 700px; ;">
      <nav class="navbar  navbar-dark pull-right " style="padding: 0%; margin: 0%;">
        <!-- no Identificado  -->
        <ul class="navbar-nav mr-auto pull-right" style=" color: {{titleColor}};" *ngIf="!identity">
          <li class="nav-item">
            <a class="nav-link" style="cursor: pointer; margin-right: 10px; color: {{titleColor}};"   (click)="buttonClick('login')">Login</a>
          </li>
        </ul>
        <ul class="navbar-nav mr-auto" *ngIf="!identity">
          <li class="nav-item">
            <a class="nav-link" style="color: {{titleColor}}; margin-right: 10px; " (click)="buttonClick('register')">Registrarse</a>
          </li>
        </ul>
        <!-- Identificado  -->
        <!--<ul class="navbar-nav mr-auto pull-right" *ngIf="identity">  era el original agranda el encabezado mal--> 
        <ul class="nav mr-auto pull-right" *ngIf="identity">
          <!-- <img src="..\..\..\assets\imagen\perfil\cabecera\defaultperfil.jpg"  alt="imagen perfil" *ngIf="identity"> -->

          <!--<img src="..\..\..\assets\imagen\perfil\cabecera\5\defaultperfil.png" ,  src="..\..\..\assets\imagen\perfil\cabecera\defaultperfil1.jpg" *ngIf="identity">
          <img id="logo" src="ruta-del-logo.jpg" alt="Logo" onerror="this.src='imagen-por-defecto.jpg'">-->

          <img style="border-radius: 50%;" src="..\..\..\assets\imagen\perfil\cabecera\{{identity.cod_user}}\{{userimg}}"  alt="Perfil" *ngIf="userimg!='null'">
          <img style="border-radius: 50%;" src="..\..\..\assets\imagen\perfil\cabecera\defaultperfil.jpg"   alt="Perfil" *ngIf="this.userimg=='null'">


          <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle active" style="color: {{titleColor}}; margin: 0%;" href="" id="dropdown05" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> 
              <strong > {{identity.nombre + ' '+ identity.apellido}} </strong><span class="fa fa-cog"> </span></a>
          <div class="dropdown-menu" style=" background-color: aquamarine;"  aria-labelledby="dropdown05">
              <a class="dropdown-item disabled" style="margin: 0%;" [routerLink]="['/userupdtprofile', + identity.sub]"><span class="fa fa-user"></span> Su Perfil</a>
              <a class="dropdown-item disabled" style="margin: 0%;"[routerLink]="['/emailvalidate','changepaspro']"><span class="fa fa-refresh"></span> Reset Clave</a>
              <a class="dropdown-item disabled" style="margin: 0%;" [routerLink]="['/uservaliphone', + identity.sub]"><span class="fa fa-phone"></span> Verificacion Telefono</a>
              <!--
              <a class="dropdown-item" style="cursor: pointer;" (click)="lock()"><span class="fa fa-lock"></span> Lock screen</a>
              -->
              <a class="dropdown-item" style="margin: 0%;" (click)="logout()"> <i class="fa fa-arrow-right"></i> Salir </a>
            
          </div>
        </li>
        </ul>
      </nav>
    </div>

    

  </header>



  <main *ngIf="forms">
    <div class="button-container"  style="background-color: {{colorBotonera}};">
      
      <!--  Menu Noticias user -->
      <button class="button button" style="margin-right: 0%; background-color: {{colorButton}}; font-family: {{buttonFont}}; font-size: {{buttonSize}}; color: {{buttonColor}};"
        (click)="buttonClick('news')" *ngIf="forms[0]&&forms[0].viewNoticias">Noticias
      </button>

      <!--  Menu Turnos -->
      <button class="button button" style="background-color: {{colorButton}}; font-family: {{buttonFont}}; font-size: {{buttonSize}}; color: {{buttonColor}};"
      (click)="buttonClick('turnos')" *ngIf="forms[0]&&forms[0].viewTurnos">Turnos</button>
      
      <!--  Menu Canchas -->
      <button class="button button" style="background-color: {{colorButton}}; font-family: {{buttonFont}}; font-size: {{buttonSize}}; color: {{buttonColor}};"
      (click)="buttonClick('canchas')" *ngIf="forms[0]&&forms[0].viewCanchas">Canchas</button>
      
        
      <!--  Menu Torneos user -->
      <button class="button button" style="margin-right: 0%; background-color: {{colorButton}}; font-family: {{buttonFont}}; font-size: {{buttonSize}}; color: {{buttonColor}};"
          (click)="buttonClick('torneos')" *ngIf="forms[0]&&forms[0].viewTorneos">Torneos
      </button>
      
      <!--  Menu Ligas -->
      <button class="button button" style="background-color: {{colorButton}}; font-family: {{buttonFont}}; font-size: {{buttonSize}}; color: {{buttonColor}};"
      (click)="buttonClick('ligas')" *ngIf="forms[0]&&forms[0].viewLigas">Ligas</button>
      
      
      <!-- <button class="button button" style="background-color: {{colorButton}}; font-family: {{buttonFont}}; font-size: {{buttonSize}}; color: {{buttonColor}};"
      (click)="buttonClick('login')">Contactos</button> -->

      <!--  Menu Edit Site admin -->
      <button class="button button" style="background-color: {{colorButton}}; font-family: {{buttonFont}}; font-size: {{buttonSize}}; color: {{buttonColor}};"
        (click)="buttonAdminClick('colorSite')" *ngIf="seter=='1'&&isMovil=='N'&&forms[0]&&forms[0].viewEditSite">Edit Site
      </button>

      <!--  <button class="button button" style="background-color: {{colorButton}}; font-family: {{buttonFont}}; font-size: {{buttonSize}}; color: {{buttonColor}};">?</button>
      ... Repite para los demás botones -->
    </div>
    

    <div class="content" [ngClass]="{'edit-content': editSite==true}"  style=" background-color: {{colorSite}};">
        
      <div class="none"  style="background-color: {{colorSite}};" *ngIf="ButtonView=='login'">
          <!--  LOGIN  -->
          <app-login [varempresa]="id"></app-login>
      </div>

      <div class="none"  style="background-color: {{colorSite}};" *ngIf="ButtonView=='register'">
        <!--  REGISTER  -->
        <app-register [varempresa]="id"></app-register>
      </div>

      <div class="none"  style="background-color: {{colorSite}};" *ngIf="ButtonView=='perfil'">
        <!--  PERFIL  -->
        <app-perfil [varempresa]="id" [userId]="identity.cod_user"></app-perfil>
      </div>

      <div class="none"  style="background-color: {{colorSite}};" *ngIf="ButtonView=='turnos'">
          <!--  TURNOS  -->
          <app-turnos [varempresa]="id"></app-turnos>
      </div>

      <div class="none"  style="background-color: {{colorSite}};" *ngIf="ButtonView=='news'">
          <!--  NOTICIAS  -->
          <app-noticias [varempresa]="id" [verSolo]="'todo'" [seter]="seter" [catDeportes]="catDeportes"> </app-noticias>
      </div>

      <div class="none"  style="background-color: {{colorSite}};" *ngIf="ButtonView=='torneos'">
          <!--  TORNEOS  -->
          <app-noticias [varempresa]="id" [verSolo]="'torneos'" [seter]="seter" [catDeportes]="catDeportes"></app-noticias>
      </div>

      <div class="none"  style="background-color: {{colorSite}};" *ngIf="ButtonView=='ligas'">
        <!--  LIGAS  -->
        <app-noticias [varempresa]="id" [verSolo]="'ligas'" [seter]="seter" [catDeportes]="catDeportes"></app-noticias>
    </div>

      <div class="none"  style="background-color: {{colorSite}};" *ngIf="ButtonView=='addNoticia'">
        <!--  AGREGAR NOTICIA  -->
        <app-add-noticias [varempresa]="id" [codNoticiaUpdate]='null'> </app-add-noticias>
      </div>

      <div class="none"  style="background-color: {{colorSite}};" *ngIf="ButtonView=='addTorneo'">
        <!--  AGREGAR TORNEO  -->
        <app-add-torneos [varempresa]="id" [codTorneoUpdate]='null'> </app-add-torneos>
      </div>

    </div>
    <!--  editor de site   -->

    <div class="edit-control-content"  style=" background-color: {{colorBotonera}};" *ngIf="editSite==true">
      <div class="content-section section-1" style="background-color: {{colorSite}};" >
        <h3> Configuracion del Sitio </h3>
        <div class="button-container"  style="background-color: {{colorBotonera}}; width: 100%;">
          <button class="button button" style="background-color: {{colorButton}}; font-family: {{buttonFont}}; font-size: {{buttonSize}}; color: {{buttonColor}};"
          (click)="editButtonClick('boton')">botones</button>
          <button class="button button" style="background-color: {{colorButton}}; font-family: {{buttonFont}}; font-size: {{buttonSize}}; color: {{buttonColor}};"
          (click)="editButtonClick('titulo')">titulo</button>
          <button class="button button" style="background-color: {{colorButton}}; font-family: {{buttonFont}}; font-size: {{buttonSize}}; color: {{buttonColor}};"
          (click)="editButtonClick('otros')">sitio</button>
          
        </div>

        <form style="width: 100%;" #itemForm="ngForm" (ngSubmit)="onSubmit(itemForm);">
          <button type="submit" class="button button" style="background-color: {{colorButton}}; font-family: {{buttonFont}}; font-size: {{buttonSize}}; color: {{buttonColor}};">
            aplicar los cambios
          </button>
          

          <ng-container *ngFor="let item of skinVar">
            
            
            <div class="edit-cards" 
            *ngIf="editSiteSelector=='otros'
            &&!(item.nombre=='color titulo'||item.nombre=='Letra titulo'||item.nombre=='tamaño titulo')
            &&!(item.nombre=='color del boton'||item.nombre=='color letra botones'||item.nombre=='Letra botones'||item.nombre=='tamaño letra botones')">
              <h2>{{item.nombre}}</h2>
              <p>{{item.descripcion}}</p>
              
              
              <!--  colores  -->
              
              <input type="color" value="{{item.text}}" name="colorcabecera" #colorcabecera="ngModel" [(ngModel)]="colorCabecera" *ngIf="item.nombre=='color cabecera'&&editSiteSelector=='otros'">
              <input type="color" value="{{item.text}}" name="colorbotonera" #colorbotonera="ngModel" [(ngModel)]="colorBotonera" *ngIf="item.nombre=='color botonera'&&editSiteSelector=='otros'">
              <input type="color" value="{{item.text}}" name="colorsite" #colorsite="ngModel" [(ngModel)]="colorSite" *ngIf="item.nombre=='color site'&&editSiteSelector=='otros'">
              <input type="color" value="{{item.text}}" name="colorpie" #colorpie="ngModel" [(ngModel)]="colorPie" *ngIf="item.nombre=='color pie'&&editSiteSelector=='otros'">
              
            </div>
            
            <div class="edit-cards" *ngIf="editSiteSelector=='titulo'&&(item.nombre=='color titulo'||item.nombre=='Letra titulo'||item.nombre=='tamaño titulo')">
              <h2> {{item.nombre}}</h2>
              <p>{{item.descripcion}}</p>
              <input type="color" value="{{item.text}}" name="colortitulo" #colortitulo="ngModel" [(ngModel)]="titleColor" *ngIf="item.nombre=='color titulo'">
              <!--  letras  -->
              <select name="Letratitulo" #Letratitulo="ngModel" [(ngModel)]="titleFont" *ngIf="item.nombre=='Letra titulo'">
                <option value="'Borel', cursive" style="font-family: 'Borel', cursive;">'Borel', cursive</option>
                <option value="'Bree Serif', serif" style="font-family: 'Bree Serif', serif;">'Bree Serif', serif</option>
                <option value="'Bungee Shade', cursive" style="font-family: 'Bungee Shade', cursive;">'Bungee Shade', cursive</option>
                <option value="'Cinzel', serif" style="font-family: 'Cinzel', serif;">'Cinzel', serif</option>
                <option value="'Lilita One', cursive" style="font-family: 'Lilita One', cursive;">'Lilita One', cursive</option>
                <option value="'Playfair Display', serif" style="font-family: 'Playfair Display', serif;">'Playfair Display', serif</option>
                <option value="'PT Sans', sans-serif" style="font-family: 'PT Sans', sans-serif;">'PT Sans', sans-serif</option>
              </select>
              <!--  tamaño letras  -->
              <select name="tamanotitulo" #tamanotitulo="ngModel" [(ngModel)]="titleSize" *ngIf="item.nombre=='tamaño titulo'">
                <option value="xx-small" style="font-family: {{titleFont}}; font-size: xx-small;">xx-small</option>
                <option value="x-small" style="font-family: {{titleFont}}; font-size: x-small;">x-small</option>
                <option value="small" style="font-family: {{titleFont}}; font-size: small;">small</option>
                <option value="medium" style="font-family: {{titleFont}}; font-size: medium;">medium</option>
                <option value="large" style="font-family: {{titleFont}}; font-size: large;">large</option>
                <option value="x-large" style="font-family: {{titleFont}}; font-size: x-large;">x-large</option>
                <option value="xx-large" style="font-family: {{titleFont}}; font-size: xx-large;">xx-large</option>
              </select>
            </div>
            
            <div class="edit-cards" *ngIf="editSiteSelector=='boton'&&(item.nombre=='color del boton'||item.nombre=='color letra botones'||item.nombre=='Letra botones'||item.nombre=='tamaño letra botones')">
              <h2>{{item.nombre}}</h2>
              <p>{{item.descripcion}}</p>
              <input type="color" value="{{item.text}}" name="colordelboton" #colordelboton="ngModel" [(ngModel)]="colorButton" *ngIf="item.nombre=='color del boton'">
              <input type="color" value="{{item.text}}" name="colorletrabotones" #colorletrabotones="ngModel" [(ngModel)]="buttonColor" *ngIf="item.nombre=='color letra botones'">
              <!--  letras  -->
              <select name="Letrabotones" #Letrabotones="ngModel" [(ngModel)]="buttonFont" *ngIf="item.nombre=='Letra botones'">
                <option value="'Borel', cursive" style="font-family: 'Borel', cursive;">'Borel', cursive</option>
                <option value="'Bree Serif', serif" style="font-family: 'Bree Serif', serif;">'Bree Serif', serif</option>
                <option value="'Bungee Shade', cursive" style="font-family: 'Bungee Shade', cursive;">'Bungee Shade', cursive</option>
                <option value="'Cinzel', serif" style="font-family: 'Cinzel', serif;">'Cinzel', serif</option>
                <option value="'Lilita One', cursive" style="font-family: 'Lilita One', cursive;">'Lilita One', cursive</option>
                <option value="'Playfair Display', serif" style="font-family: 'Playfair Display', serif;">'Playfair Display', serif</option>
                <option value="'PT Sans', sans-serif" style="font-family: 'PT Sans', sans-serif;">'PT Sans', sans-serif</option>
              </select>
              <!--  tamaño letras  -->
              <select name="tamanoletrabotones" #tamanoletrabotones="ngModel" [(ngModel)]="buttonSize" *ngIf="item.nombre=='tamaño letra botones'">
                <option value="xx-small" style="font-family: {{buttonFont}}; font-size: xx-small;">xx-small</option>
                <option value="x-small" style="font-family: {{buttonFont}}; font-size: x-small;">x-small</option>
                <option value="small" style="font-family: {{buttonFont}}; font-size: small;">small</option>
                <option value="medium" style="font-family: {{buttonFont}}; font-size: medium;">medium</option>
                <option value="large" style="font-family: {{buttonFont}}; font-size: large;">large</option>
                <option value="x-large" style="font-family: {{buttonFont}}; font-size: x-large;">x-large</option>
                <option value="xx-large" style="font-family: {{buttonFont}}; font-size: xx-large;">xx-large</option>
              </select>
            </div>  
            
            
          </ng-container>
        </form>
      </div>
    </div>

  </main>

  <footer  style="background-color: {{colorPie}};">
      <p>&copy; 2023 ZoneDeporte. Dirección: Calle Principal, Ciudad. Email: info@miclubdeportivo.com</p>
  </footer>
</body>
</html>


      <!--
        <div class="content-section section-2">
          Contenido del Botón 2
      </div>
      
        PARA PONER EL PLUGING FDE FACE 3 formas distintas de hacerlo
      <div class="content-section section-3">
          Contenido del Botón 3
          <div class="fb-page" 
              data-tabs="timeline"
              data-href="https://www.facebook.com//profile.php?id=100042625488376"
              data-width="380" 
              data-hide-cover="false"
              data-show-facepile="false"> 
          </div>


          
          <div class="fb-page" 
              data-tabs="timeline,events"
              data-href="https://www.facebook.com/miguel.fernandez.ar"
              data-width="380" 
              data-hide-cover="false"></div>

          <div class="fb-page" data-href="https://www.facebook.com/profile.php?id=100042625488376" data-tabs="timeline" data-width="" data-height="" data-small-header="false" data-adapt-container-width="true" data-hide-cover="false" data-show-facepile="false"></div>

      </div>
      -->
      <!-- ... Repite para los demás botones -->
