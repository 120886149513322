import { Component, OnInit, Input } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { SiteService }  from 'src/app/services/site.service';
import { UserService }  from 'src/app/services/user.service';
import { TorneosService }  from 'src/app/services/torneos.service';
import { ClassTorneo } from "src/app/clases/torneo";
import { formatDate } from '@angular/common';
import { DomSanitizer } from '@angular/platform-browser';
import { LocalService } from 'src/app/services/local.service';



@Component({
  selector: 'app-add-torneos',
  templateUrl: './addTorneos.component.html',
  styleUrls: ['./addTorneos.component.css'],
  providers: [SiteService, UserService, LocalService, TorneosService]
})
export class addTorneosComponent implements OnInit {
  @Input() varempresa: string;
  @Input() codTorneoUpdate: number;
  @Input() dataTorneoUpdate: any;

  selectedFile: File=null;
  imagenPrevia: any;
  imagenUp1: any;
  imagenPrevia2: any;
  imagenUp2: any;
  imagenPrevia3: any;
  imagenUp3: any;

  //fechas
  fechaLimite = Date.now();
  fechaInicio = Date.now();
  fechaDia = Date.now();
  

  public Equipos: number = 1 ;
  
  loading: boolean;
  vartoken;

  colorSite: string;
  TorneoConfiguracion; TorneoConfiguracionSelect; 
  TorneoDeportes; 
  TorneoCategorias; CategoriaSelect;
  TorneoTJItem; torneoTJItemSelect;
  TorneoTJ = [];
  mytorneo = new ClassTorneo(0,'','','',formatDate(Date.now(),'yyyy/MM/dd HH:mm','en-US'),formatDate(Date.now(),'yyyy/MM/dd HH:mm','en-US'),formatDate(Date.now(),'yyyy/MM/dd HH:mm','en-US'),1,0,0,0,0,0,'','','',0,0,0,0);
  vistaPreliminar: boolean = false;
  
  //errors
  status = ''; message = '';
  
  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _siteServices: SiteService,
    private _userService: UserService,
    private sanitizer: DomSanitizer,
    private _localService: LocalService,
    private _torneosService: TorneosService
  ){ }
  
  tratarDate(campo:string){
    
    if (campo == 'limite'){
      let fechaString = formatDate(this.fechaLimite,'yyyy/MM/dd HH:mm','en-US');
      this.mytorneo.fecha_limite_inscripcion = fechaString;
    }
    if (campo == 'inicio'){
      let fechaString = formatDate(this.fechaInicio,'yyyy/MM/dd HH:mm','en-US');
      this.mytorneo.fecha_inicio = fechaString;
    } 
    //console.log('entro y sali con valores '+campo+ ' '+this.mytorneo.fecha_limite_inscripcion);
  } 
  ngOnInit(): void {
    
    // console.log(this.imagenPrevia);
    this.colorSite = this._siteServices.getColorSite();
    this.vartoken = this._userService.getToken();
    //console.log(this.vartoken);
    this._torneosService.getTorneoConfiguraciones(this.vartoken).subscribe(
      response => {
        if(response.status != 'error'){
          
          this.TorneoConfiguracion = response;
          //console.log(this.TorneoConfiguracion);
          
          this.mytorneo.cod_empresa = this.varempresa;
          let body = {'cod_empresa': this.varempresa};
          this._torneosService.getDeportesEmpresa(body, this.vartoken).subscribe(
            response => {
              if(response.status != 'error'){
                
                this.TorneoDeportes = response;
                
                if(this.codTorneoUpdate!=null){
                  //console.log(this.dataTorneoUpdate);
                  this.mytorneo.cod_torneo = this.dataTorneoUpdate.cod_torneo;
                  this.mytorneo.cod_empresa = this.dataTorneoUpdate.cod_empresa;
                  this.mytorneo.torneo = this.dataTorneoUpdate.torneo;
                  this.mytorneo.descripcion = this.dataTorneoUpdate.descripcion;
                  this.mytorneo.fecha_creacion = this.dataTorneoUpdate.fecha_creacion;
                  this.mytorneo.fecha_limite_inscripcion = this.dataTorneoUpdate.fecha_limite_inscripcion;
                  this.fechaLimite = this.dataTorneoUpdate.fecha_limite_inscripcion;
                  this.mytorneo.fecha_inicio = this.dataTorneoUpdate.fecha_inicio;
                  this.fechaInicio = this.dataTorneoUpdate.fecha_inicio;
                  this.mytorneo.plaza_equipos = this.dataTorneoUpdate.plaza_equipos;
                  this.mytorneo.activo = this.dataTorneoUpdate.activa;
                  
                  this.mytorneo.imagen1 = this.dataTorneoUpdate.imagen1;
                  this.mytorneo.imagen2 = this.dataTorneoUpdate.imagen2;
                  this.mytorneo.imagen3 = this.dataTorneoUpdate.imagen3;

                  this.mytorneo.cod_torneo_config = this.dataTorneoUpdate.cod_torneo_config;
                  this.selectConfiguracion();
                  this.mytorneo.cod_deporte = this.dataTorneoUpdate.cod_deporte;
                  this.mytorneo.is_liga = this.dataTorneoUpdate.isliga;
                  this.mytorneo.iniciado = this.dataTorneoUpdate.iniciado;
                  this.selectDeporte()
                  //console.log(this.dataTorneoUpdate);
                }
              }else{
                this.status = response.status;
                this.message = response.message;
              }
            },
            error => {
              console.log(error);
            } 
          )
 
        }else{
          this.status = response.status;
          this.message = response.message;
          
        }
      },
      error => {
        console.log(error);
      } 
    )
  }

  _handleReaderLoaded1(readerEvent){
    var binaryString = readerEvent.target.result;
    this.imagenUp1 = btoa(binaryString);
  }
  _handleReaderLoaded2(readerEvent){
    var binaryString = readerEvent.target.result;
    this.imagenUp2 = btoa(binaryString);
  }
  _handleReaderLoaded3(readerEvent){
    var binaryString = readerEvent.target.result;
    this.imagenUp3 = btoa(binaryString);
  }
  cargarImagen(event, name:string){
    const imagen = event.target.files[0];
    if (imagen.type.includes('image')) {
      //otra opcion
      this.blobFile(imagen).then((res: any) => {
        if (name == 'imagen1'){
          var reader = new FileReader();
          reader.onload = this._handleReaderLoaded1.bind(this);
          reader.readAsBinaryString(imagen);
          this.mytorneo.imagen1=imagen.name;
          this.imagenPrevia = res.base;
        }else{
          if (name == 'imagen2'){
            var reader = new FileReader();
            reader.onload = this._handleReaderLoaded2.bind(this);
            reader.readAsBinaryString(imagen);
            this.imagenPrevia2 = res.base;
            this.mytorneo.imagen2=imagen.name;
          }else{
            if (name == 'imagen3'){
              var reader = new FileReader();
              reader.onload = this._handleReaderLoaded3.bind(this);
              reader.readAsBinaryString(imagen);
              this.imagenPrevia3 = res.base;
              this.mytorneo.imagen3=imagen.name;
            }
          }
        }
      })    
    } else {
      console.log('No es imagen');
    };
  }





  actualizarVista(){
    this.vistaPreliminar = false;
    /*if (this.noticia.cod_noticia == 0){
      this.noticiaEdit=[{
        activa: this.noticia.activa,
        cod_empresa: this.noticia.cod_empresa,
        cod_noticia: this.noticia.cod_noticia+1,
        fecha: this.noticia.fecha,
        noticia: this.noticia.noticia,
        texto_corto: this.noticia.texto_corto,
        tipo_noticia: "noticia",
        titulo: this.noticia.titulo,
        cod_skin: this.noticia.cod_noticias_skin,
        letra_titulo: this.noticia.letra_titulo,
        tamano_titulo: this.noticia.tamano_titulo,
        color_titulo: this.noticia.color_titulo,
        letra_otros: this.noticia.letra_otros,
        tamano_otros: this.noticia.tamano_otros,
        color_otros: this.noticia.color_otros,
        color_fondo: this.noticia.color_fondo,
        imagen1: this.noticia.imagen1,
        imagen2: this.noticia.imagen2,
        imagen3: this.noticia.imagen3
  
      }];
    }else{
      this.noticiaEdit=[{
        activa: this.noticia.activa,
        cod_empresa: this.noticia.cod_empresa,
        cod_noticia: this.noticia.cod_noticia,
        fecha: this.noticia.fecha,
        noticia: this.noticia.noticia,
        texto_corto: this.noticia.texto_corto,
        tipo_noticia: "noticia",
        titulo: this.noticia.titulo,
        cod_skin: this.noticia.cod_noticias_skin,
        letra_titulo: this.noticia.letra_titulo,
        tamano_titulo: this.noticia.tamano_titulo,
        color_titulo: this.noticia.color_titulo,
        letra_otros: this.noticia.letra_otros,
        tamano_otros: this.noticia.tamano_otros,
        color_otros: this.noticia.color_otros,
        color_fondo: this.noticia.color_fondo,
        imagen1: this.noticia.imagen1,
        imagen2: this.noticia.imagen2,
        imagen3: this.noticia.imagen3
  
      }];
    }
    
    setTimeout(() => { this.vistaPreliminar = true; }, 1);
    //console.log(this.noticiaEdit);*/
  }

  selectConfiguracion(){
    this.TorneoConfiguracionSelect = null;
    for(let i=0; i<this.TorneoConfiguracion.length; i++){
      if (this.TorneoConfiguracion[i].cod_torneo_config == this.mytorneo.cod_torneo_config){
        this.TorneoConfiguracionSelect = this.TorneoConfiguracion[i];
        break;
      }
    }
    this.Equipos = this.TorneoConfiguracionSelect.llave_inicial * 2;
    //console.log(this.TorneoConfiguracionSelect);
    //this.actualizarVista();
  }
  selectDeporte(){
    let body = {'cod_deporte':this.mytorneo.cod_deporte};
    this._torneosService.getCategoriasDeporte(body, this.vartoken).subscribe(
      response => {
        if(response.status != 'error'){
          
          this.TorneoCategorias = response;
          if (this.codTorneoUpdate!=null){
            this.mytorneo.cod_categoria = this.dataTorneoUpdate.cod_categoria;
            this.selectCategorias();
            
            
                  
          }
        }else{
          this.status = response.status;
          this.message = response.message;
        }
      },
      error => {
        console.log(error);
      } 
    )
    this.TorneoTJ = [];
    this._torneosService.getItemsJuegoDeporte(body, this.vartoken).subscribe(
      response => {
        if(response.status != 'error'){
          
          this.TorneoTJItem = response;
          let paso: number = 0;
          let suma: number = 0;
          for (let i = 0; i<this.TorneoTJItem.length; i++){
            if (this.TorneoTJItem[i].cod_torneo_config_juego!=paso){
              paso=this.TorneoTJItem[i].cod_torneo_config_juego; 
              let item = {'cod_torneo_config_juego' : this.TorneoTJItem[i].cod_torneo_config_juego,
                          'tipo_juego':this.TorneoTJItem[i].tipo_juego};
              this.TorneoTJ[suma]=item;
              suma++;
            }
          }
          if (this.codTorneoUpdate!=null){
            this.mytorneo.cod_config_juego = this.dataTorneoUpdate.cod_config_juego;
            this.selectTipoJuego();
                  
          }
          //console.log(this.TorneoTJ);
        }else{
          this.status = response.status;
          this.message = response.message;
        }
      },
      error => {
        console.log(error);
      } 
    )
  }  
  selectCategorias(){
    this.CategoriaSelect = null;
    for(let i=0; i<this.TorneoCategorias.length; i++){
      if (this.TorneoCategorias[i].cod_deporte_categoria == this.mytorneo.cod_categoria){
        this.CategoriaSelect = this.TorneoCategorias[i];
        break;
      }
    }
    //console.log(this.CategoriaSelect);
  }

  selectTipoJuego(){
    
    this.torneoTJItemSelect = [];
    for(let i=0; i<this.TorneoTJItem.length; i++){
      if (this.TorneoTJItem[i].cod_torneo_config_juego == this.mytorneo.cod_config_juego){
        let paso = {'item':this.TorneoTJItem[i].item,
                    'descripcion': this.TorneoTJItem[i].descripcion,
                    'valor': this.TorneoTJItem[i].ItemValor};
        //this.torneoTJItemSelect[i] = paso;
        this.torneoTJItemSelect.push(paso);
      }
    }
    
  }

  onSubmit(form){
    //hacer control y ...
    
    //console.log(this.mytorneo.activo);
    if (this.mytorneo.activo != 1){
      if (this.mytorneo.activo != 0){
        this.mytorneo.activo = 0;}
    }
    if ((this.mytorneo.imagen1 !='')&&(this.imagenPrevia==undefined)){
      this.mytorneo.imagen1='';
    }
    this._torneosService.torneoInsUpd(this.mytorneo, this.vartoken).subscribe(
      response => {
        if (response.status != 'error'){
          this.mytorneo.cod_torneo = response[0].cod_torneo;
          if ((this.mytorneo.imagen1 !='')&&(this.imagenPrevia!=undefined)){
          let body1 = {
            nombreArchivo: this.mytorneo.imagen1,
            base64textString: this.imagenUp1,
            torneo: this.mytorneo.cod_torneo,
            empresa: this.mytorneo.cod_empresa
          }
          this._localService.uploadFileTorneo(body1).subscribe(
            response => {
              //console.log(response);

            },
            error => {
              console.log(error);
            } 
          )    
          } 
        }else{
          this.status = response.status;
          this.message = response.message;
        }

      },
      error => {
        console.log(error);
      } 
    )  
    //redirecciono
    this._router.navigate(['loadclientsite/'+this.varempresa]);
    
  }

  blobFile = async ($event: any) => new Promise((resolve, reject) => {
    try {
      const unsafeImg = window.URL.createObjectURL($event);
      const image = this.sanitizer.bypassSecurityTrustUrl(unsafeImg);
      const reader = new FileReader();
      reader.readAsDataURL($event);
      reader.onload = () => {
        resolve({
          //blob: $event,
          //image,
          base: reader.result
        });
      };
      reader.onerror = error => {
        resolve({
          //blob: $event,
          //image,
          base: null
        });
      };

    } catch (e) {
      return null;
    }
  })
}

