<div class="alert alert-danger" *ngIf="status=='error'">
    {{message}}
    <h2 *ngIf="message=='no Autentificado'"> La sesion a expirado, vuelva a loguearse. </h2>
    <button class="button button" (click)="this._siteServices.logout(this.varempresa);" *ngIf="message=='no Autentificado'">
        Cerrar
    </button>
</div>


<div class="content-cero" style="justify-content: space-between; background-color: {{colorSite}};" *ngIf="dataTorneo">


    <h2 style="text-align: center; " *ngIf="codTorneo&&dataTorneo.isliga==0" >Torneo: {{dataTorneo.torneo}}</h2>
    <h2 style="text-align: center; " *ngIf="codTorneo&&dataTorneo.isliga==1" >Liga: {{dataTorneo.torneo}}</h2>

    <div style="background-color: {{colorSite}};" >  <!--*ngIf="message!='no Autentificado'"-->
        <div class="content">
             
            
            <!--  MENU DE TABLAS - Tabla zonas  -->
            <ul class="nav nav-pills nav-fill" >
                    <li class="nav-item" *ngIf="ver.submenu == 'tabla'&&ver.zona">
                        <a class="nav-link active" aria-current="page" (click)="ver.submenu='tabla'">Tablas</a>
                    </li>
                    <li class="nav-item" *ngIf="ver.submenu == 'partidos'&&ver.zona">
                        <a class="nav-link" style="background-color: gray;" (click)="ver.submenu='tabla'">Tablas</a>
                    </li>
                    <li class="nav-item" *ngIf="ver.submenu == 'partidos'">
                        <a class="nav-link active"  aria-current="page" (click)="ver.submenu='partidos'">Partidos</a>
                    </li>
                    <li class="nav-item" *ngIf="ver.submenu == 'tabla'">
                        <a class="nav-link" style="background-color: gray;" (click)="ver.submenu='partidos'">Partidos</a>
                    </li>
            </ul>
            <div class="row ">
                <!--  SELECT TABLA  -->
                
                <div  class="edit-cards " *ngIf="ver.submenu=='tabla'" [ngClass]="(isMovil == 'S')? 'col2' : 'col2'  " >
                    <div id="carouselExampleControls" class="carousel slide" data-ride="carousel" data-interval="5000" data-pause="hover">
                        <div class="carousel-inner"  >
                            <ng-container *ngFor="let zona of tablaZona.zona" >
                                <div class="carousel-item" [ngClass]="(zona.zona == tablaZona.zona[0].zona)? 'active' : ''  " >
                                    <widgedTablaPosGrupoPadel [codTorneo]="codTorneo" [zona]="zona.zona"></widgedTablaPosGrupoPadel>
                                </div>
                            </ng-container>
                        </div>
                        <a class="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
                            <span class="fa fa-chevron-left fa-lg" style="color:red;"></span>
                            <span class="sr-only">Previous</span>
                        </a>
                        <a class="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
                            <span class="fa fa-chevron-right fa-lg" style="color:red;"></span>
                            <span class="sr-only">Next</span>
                        </a>
                    </div>

                </div>  
                <!--  SELECT PARTIDOS TORNEOS  -->
                <div  class="edit-cards " *ngIf="ver.submenu=='partidos'&&dataTorneo.isliga==0" [ngClass]="(isMovil == 'S')? 'col2' : 'col2'  " >
                    <div style="overflow-x: scroll;">
                        <ng-container  *ngFor="let item of partidos">
                            <widgedPartidoChangeFecha *ngIf="!item.llave" [codTorneo]="item.cod_torneo" [codPartido]="item.cod_partido"></widgedPartidoChangeFecha>
                            <widgedPartidoChangeFecha *ngIf="item.llave&&item.llave==ver.llave" [codTorneo]="item.cod_torneo" [codPartido]="item.cod_partido"></widgedPartidoChangeFecha>
                        </ng-container>
                    </div>
                </div> 
                <div  class="edit-cards " *ngIf="ver.submenu=='partidos'&&dataTorneo.isliga==1" [ngClass]="(isMovil == 'S')? 'col2' : 'col2'  " >
                    
                        <ng-container *ngFor="let zona of tablaZona.zona" >
                            <!--   si es zona de grupos  -->
                            <div *ngIf="zona.zona==ver.zona">
                            
                                <table style="display:grid; width: 100%; background-color:rgb(7, 77, 105) ;" >
                                    <tr>
                                        <td style="max-width: 30px; min-width: 30px; text-align: right;" 
                                        (click)="ver.itemzona = ver.itemzona-1; ver.zona=tablaZona.zona[zona.idzona-1].zona; "
                                        *ngIf="zona.idzona > 0">
                                            <span class="fa fa-chevron-left fa-lg" style="color: white;"></span>
                                        </td>        
                                        <td style="max-width: 30px; min-width: 30px; text-align: right;" 
                                        *ngIf="zona.idzona == 0">
                                            <span class="fa fa-chevron-left fa-lg" style="color: gray;"></span>
                                        </td>

                                        <td style="flex: auto; width: 100%; text-align: center; color: white;"> 
                                            Zona {{zona.zona}}
                                        </td>
                                        
                                        <td style="max-width: 30px; min-width: 30px; text-align: right;" 
                                        (click)="ver.zona=tablaZona.zona[zona.idzona+1].zona; ver.itemzona = ver.itemzona+1"
                                        *ngIf="zona.idzona < tablaZona.zona.length-1">
                                            <span class="fa fa-chevron-right fa-lg" style="color: white;"></span>
                                        </td>        
                                        <td style="max-width: 30px; min-width: 30px; text-align: right;" 
                                        *ngIf="zona.idzona == tablaZona.zona.length-1"
                                        (click)="ver.zona=''; ver.llave=tablaZona.eliminacion[0].llave;">
                                            <span class="fa fa-chevron-right fa-lg" style="color: white;"></span>
                                        </td>
                                    </tr>
                                </table>

                                <div *ngIf="seter==1" id="carouselPartidos1" class="carousel slide" data-ride="carousel" data-interval="5000" data-pause="true" >
                                    <div class="carousel-inner"  >
                                        <table style="width: 100%;">
                                            <tr>
                                                <td style="width: 5px;">
                                                    <a href="#carouselPartidos1" role="button" data-slide="prev">
                                                        <span class="fa fa-chevron-left fa-lg" style="color: rgb(7, 77, 105);"></span>
                                                        
                                                    </a> 
                                                    
                                                </td>
                                                <td style="width: 100%;">
                            
                                                    <div *ngFor="let rondas of zona.rondas" class="carousel-item " [ngClass]="(rondas.ronda == 1)? 'active' : ''  ">
                                                        <b><p style="text-align: center; font-size: small;">Ronda: {{rondas.ronda}}</p></b>
                                                        <ng-container1 *ngFor="let item of partidos" >
                                                        
                                                            <widgedPartidoChangeFecha *ngIf="item.grupo==zona.zona&&item.ronda==rondas.ronda" [codTorneo]="item.cod_torneo" [codPartido]="item.cod_partido"></widgedPartidoChangeFecha>
                                        
                                                            
                                                        </ng-container1>
                                                    </div>
                                                </td>
                                                <td style="width: 5px;">
                                                    <a href="#carouselPartidos1" role="button" data-slide="next">
                                                        <span class="fa fa-chevron-right fa-lg" style="color: rgb(7, 77, 105);"></span>
                                                    </a>
                                                </td>
                                            </tr>
                                        </table>
                                        
                                        
                                    </div>
                                    
                                </div>
                                <!--
                                <div *ngIf="seter==1" style="display:grid; width: 100%; ">
                                    
                                    <table style="display:grid; width: 100%;">
                                        <tr>
                                            <td style="max-width: 100px; min-width: 100px; text-align: center; background-color: gray;" >
                                                <a class="button" href="#carouselPartidos1" role="button" data-slide="prev">
                                                    <span style="color: blue;" class="carousel-control-prev-icon"></span>
                                                    
                                                </a> 
                                            </td>
                                            <td style="flex: auto; width: 100%; text-align: center;">
                                                Rondas
                                            </td>
                                            <td style="max-width: 100px; min-width: 100px; text-align: center; background-color: gray;">
                                                <a class="button" href="#carouselPartidos1" role="button" data-slide="next">
                                                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                                    
                                                </a>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                                -->

                                <div *ngIf="!seter==1" id="carouselPartidos1" class="carousel slide" data-ride="carousel" data-interval="5000" data-pause="hover" >
                                    <div class="carousel-inner"  >
                                        <table style="width: 100%;">
                                            <tr>
                                                <td style="width: 5px;">
                                                    <span class="fa fa-chevron-left fa-lg" style="color: rgb(7, 77, 105);"></span>
                                                </td>
                                                <td style="width: 100%;">
                                                    <div *ngFor="let rondas of zona.rondas" class="carousel-item " [ngClass]="(rondas.ronda == 1)? 'active' : ''  ">
                                                        <b><p style="text-align: center; font-size: small;">Ronda: {{rondas.ronda}}</p></b>
                                                        <ng-container1 *ngFor="let item of partidos" >
                                                            <widgedPartidoChangeFecha *ngIf="item.grupo==zona.zona&&item.ronda==rondas.ronda" [codTorneo]="item.cod_torneo" [codPartido]="item.cod_partido"></widgedPartidoChangeFecha>
                                                        </ng-container1>
                                                    </div>
                                                </td>
                                                <td style="width: 5px;">
                                                    <span class="fa fa-chevron-right fa-lg" style="color: rgb(7, 77, 105);"></span>
                                                </td>
                                            </tr>
                                        </table>
                                        
                                    </div>
                                    <a class="carousel-control-prev" href="#carouselPartidos1" role="button" data-slide="prev">
                                        <!-- <span style="color: blue;" class="carousel-control-prev-icon" aria-hidden="true"></span>-->
                                        <span class="sr-only">Prev</span>
                                        </a>
                                    <a class="carousel-control-next" href="#carouselPartidos1" role="button" data-slide="next">
                                        <!-- <span class="carousel-control-next-icon" aria-hidden="true"></span>-->
                                        <span class="sr-only">Next</span>
                                    </a>
                                </div>
                            </div>
                        </ng-container>
                            <!--   si es eliminacion  -->
                        <ng-container *ngFor="let elim of tablaZona.eliminacion" >
                            <div *ngIf="elim.llave==ver.llave">
                                <table style="display:grid; width: 100%; background-color:rgb(7, 77, 105) ;">
                                    <tr>
                                        <td style="max-width: 30px; min-width: 30px; text-align: right;" 
                                        (click)="mostrar('ELIM', tablaZona.eliminacion[elim.idllave-1].llave,1)"
                                        *ngIf="elim.idllave > 0">
                                            <span class="fa fa-chevron-left fa-lg" style="color: white;"></span>
                                        </td>        
                                        <td style="max-width: 30px; min-width: 30px; text-align: right;" 
                                        *ngIf="elim.idllave == 0"
                                        (click)="ver.llave=''; ver.zona=tablaZona.zona[tablaZona.zona.length-1].zona; 
                                        ver.itemzona = tablaZona.zona[tablaZona.zona.length-1].rondas[tablaZona.zona[tablaZona.zona.length-1].rondas.length-1].ronda">
                                            <span class="fa fa-chevron-left fa-lg" style="color: white;"></span>
                                        </td>
                                        
                                        <td style="flex: auto; width: 100%; text-align: center; color: white;"> 
                                            {{elim.texto}}
                                        </td>

                                        <td style="max-width: 30px; min-width: 30px; text-align: right;" 
                                        (click)="ver.llave = tablaZona.eliminacion[elim.idllave+1].llave"
                                        *ngIf="elim.idllave < tablaZona.eliminacion.length-1">
                                            <span class="fa fa-chevron-right fa-lg" style="color: white;"></span>
                                        </td>        
                                        <td style="max-width: 30px; min-width: 30px; text-align: right;" 
                                        *ngIf="elim.idllave == tablaZona.eliminacion.length-1">
                                            <span class="fa fa-chevron-right fa-lg" style="color:gray;"></span>
                                        </td>
                                    </tr>
                                </table>
                                <div>    
                                    <ng-container1 *ngFor="let item of partidos" >
                                        <widgedPartidoChangeFecha *ngIf="item.llave==elim.llave" [codTorneo]="item.cod_torneo" [codPartido]="item.cod_partido"></widgedPartidoChangeFecha>
                                    </ng-container1>
                                </div>
                            </div>
                        </ng-container>
                </div> 
            </div> 
            <div class="row ">
                <div  class="edit-cards " [ngClass]="(isMovil == 'S')? 'col2' : 'col1'  " >
                        <div  style="justify-content: right; flex-wrap: nowrap; display: flex; " >
                            <!-- TERMINAR GRUPOS Y ARMAR LLAVE -->
                            <button *ngIf="seter==1&&!dataTorneo.partidos_llave" class="button button " style="flex: 0 0 calc(66%); margin-right: 0%; background-color:darkgreen; font-family: {{buttonFont}}; font-size: {{buttonSize}}; color: {{buttonColor}};"
                                (click)="armarLlaves()">Cerrar zonas y crear las llaves
                            </button>
                            <!-- volver atras -->
                            <button class="button button " style="flex: 0 0 calc(33%); margin-right: 0%; background-color:coral; font-family: {{buttonFont}}; font-size: {{buttonSize}}; color: {{buttonColor}};"
                                (click)="salir()">Salir 
                            </button>
                            

                        </div>
                    
                </div>
            </div>
        </div>
    </div>
</div>
