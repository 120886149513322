import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { GLOBAL } from './global';

@Injectable()
export class TurnosService{
    public url:string;

    constructor(
        public _http: HttpClient

    ){
        this.url = GLOBAL.url;

    }
    
    altaTurnosClientes(data, token: string): Observable<any>{
        let json = JSON.stringify(data);
        let params = 'json='+json;
        let headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
                                        .set('Authorization', token);
        return this._http.post(this.url+'empresa/addturno/cliente',params, {headers: headers});
    }
    confirmarTurnosClientes(data, token: string): Observable<any>{
        /*
        data{codUser:, codTurno:, codTurnoFijo:, fecha:}
        */
        let json = JSON.stringify(data);
        let params = 'json='+json;
        let headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
                                        .set('Authorization', token);
        return this._http.post(this.url+'empresa/confirmarturno/cliente',params, {headers: headers});
    }
}