
<div class="container" style="width: fit-content;" *ngIf="tabla">
    <table class="table table-light">
        
        <thead> 
            <tr>
                
                <th [colSpan]="11">{{'Zona "'+tabla[0]['zona']+'"'}}</th>
                
                
            </tr>
            <tr *ngIf="!movil">
                <th></th>
                <th [colSpan]="4" style="text-align: center;">Partido</th>
                
                <th [colSpan]="3" style="text-align: center;">Sets</th>
                <th [colSpan]="3" style="text-align: center;">Games</th>
            </tr>
            <tr>
                <th>equipo</th>
                <th *ngIf="!movil">Jug </th> <th *ngIf="movil">J </th>
                <th *ngIf="!movil">Gan</th>
                <th *ngIf="!movil">Per</th>
                <th >pts</th>
                
                <th *ngIf="!movil">Gan</th>
                <th *ngIf="!movil">Per</th>
                <th *ngIf="!movil">Dif S</th> <th *ngIf="movil">Set </th>
                
                <th *ngIf="!movil">Gan</th>
                <th *ngIf="!movil">Per</th>
                <th *ngIf="!movil">Dif G</th> <th *ngIf="movil">Gam</th>

            </tr>
        </thead>
        <tbody> 
            <tr *ngFor="let lista of tabla; index as i">
                <!--<td style="background-color: green;" *ngIf="i < pasan">   </td>
                <td style="background-color: rgb(238, 220, 18);" *ngIf="i==pasan && pasamejor > 0">   </td>
                <td  *ngIf="i > pasan ||( i==pasan && pasamejor == 0)">   </td>
                
                <td style="border-left: 3px solid red;" > {{lista['apellJug1']+'-'+lista['apellJug2']}} </td>-->
<!--
                <td [style.border-left]="(i < pasan) ? '3px solid green' : ((i==pasan && pasamejor > 0) ? '3px solid rgb(238, 220, 18)' : '3px solid white ') " *ngIf="!movil"> {{lista['apellJug1'] +'-'+lista['apellJug2']}} </td>
                <td [style.border-left]="(i < pasan) ? '3px solid green' : ((i==pasan && pasamejor > 0) ? '3px solid rgb(238, 220, 18)' : '3px solid white ') " *ngIf="!movil">  {{(lista['apellJug1'] | slice:0:3)+'-'+lista['apellJug2']}} </td>
-->
                <td [style.border-left]="(i < pasan) ? '3px solid green' : ((i==pasan && pasamejor > 0) ? '3px solid rgb(238, 220, 18)' : '3px solid white ') " >  
                                            {{ ( (lista['apellJug1'].length > 4 && movil) ? (lista['apellJug1'] | slice:0:4) : (lista['apellJug1'] | slice:0:10))  +'-'+  ( (lista['apellJug2'].length > 4 && movil) ? (lista['apellJug2'] | slice:0:4) : (lista['apellJug2']  | slice:0:10))      }} </td>
                                            
                <td style="text-align: right;"> {{lista['jugados']}} </td>
                <td style="text-align: right;" *ngIf="!movil"> {{lista['ganado']}} </td>
                <td style="text-align: right;" *ngIf="!movil"> {{lista['perdido']}} </td>
                
                <td style="text-align: right;"><strong> {{lista['puntos']}} </strong></td>

                <td style="text-align: right;" *ngIf="!movil"> {{lista['sets_ganado']}} </td>
                <td style="text-align: right;" *ngIf="!movil"> {{lista['sets_perdido']}} </td>
                <td style="text-align: right;"> {{lista['dif_sets']}} </td>

                <td style="text-align: right;" *ngIf="!movil"> {{lista['games_ganado']}} </td>
                <td style="text-align: right;" *ngIf="!movil"> {{lista['games_perdido']}} </td>
                <td style="text-align: right;"> {{lista['dif_games']}} </td>
            </tr>
            
        </tbody> 
    </table>
</div>
